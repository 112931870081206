import { useConvertImageInBase64 } from "hooks/useConvertImageInBase64";
import { useEffect, useState } from "react";

export const useImagesGroupForm = (watch, item) => {
  const { image, convertBase64, handleResetImage } = useConvertImageInBase64();

  const [loadingUploadGroupImage, setLoadingUploadGroupImage] = useState(false);

  const handleLoadingUploadGroupImage = (value) => {
    setLoadingUploadGroupImage(value);
  };

  useEffect(() => {
    const subscription = watch((value) => {
      const boxTouch = item.name;

      if (value[boxTouch]) {
        if (value[boxTouch]?.length > 0) {
          handleLoadingUploadGroupImage(true);

          convertBase64(value[boxTouch][0]);
          handleLoadingUploadGroupImage(false);
        } else if (value[boxTouch]?.length === 0) {
          handleResetImage();
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return {
    loadingUploadGroupImage,
    image,
  };
};
