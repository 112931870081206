import styled from "styled-components";
import { fadeIn } from "styled-components/animation.styled";
import {
  defaultColors,
  desktopSmallTexts,
  titlesStyled,
} from "styled-components/App.styled";
// import { fadeIn } from "./styled-components/animation.styled";
// import { defaultColors, desktopSmallTexts, titlesStyled } from "./styled-components/App.styled";

const TitleStyled = styled.div`
  width: 100%;
  color: ${defaultColors.white};
  font: ${titlesStyled};

  ${fadeIn({ time: "1.5s" })}
`;

const SmallTextStyled = styled.div`
  padding: ${({ from }) => (from ? "0" : "15px 0")};
  color: ${defaultColors.white};
  text-align: center;
  font: ${desktopSmallTexts};
`;

export { TitleStyled, SmallTextStyled };
