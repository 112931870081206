import { inputUbication } from "utilities/utilAccount/utilInputUbication";
import {
  FormGroupStyled,
  SpanErrorsStyled,
} from "views/account/pages/YourAccount/styled-components";
import { InputPlaceAutocompleteStyled } from "views/account/styled-components/InputPlaceAutocomplete.styled";
import { AutoComplete } from "./Autocomplete";

export const InputPlaceAutocomplete = ({
  register,
  errors,
  validationSchema,
  setValue,
  clearErrors,
  data
}) => {
  return (
    <>
      {inputUbication.map((field) => (
        <FormGroupStyled key={field.name} className="form-group">
          <InputPlaceAutocompleteStyled
            type={field.type}
            className="form-control"
            name={field.name}
            properties={field.properties}
            {...register(field.name, validationSchema[field.name])}
          />
          <AutoComplete
            placeholder={field.placeholder}
            setValue={setValue}
            clearErrors={clearErrors}
            data={data}
          />
          {errors[field.name] && (
            <SpanErrorsStyled className="col col-12">
              {errors[field.name]["message"]}
            </SpanErrorsStyled>
          )}
        </FormGroupStyled>
      ))}
    </>
  );
};
