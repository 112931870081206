import { URLs } from "services/servicesUrls.services";
import fetchAPI from "../../../services/fetchAPI.services";

export const getEvents = async ({ profileId }) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };

    const fetchingGenres = await fetchAPI({
      method: "GET",
      url: `${URLs.profiles}/${profileId}/Events?PageSize=100`,
      headers,
    });
    // console.log({fetchingGenres})
    return fetchingGenres;
  } catch (error) {
    console.log("error get interceptor", { error });
    return { success: false, code: "X" };
  }
};
