import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { messagesApp } from 'utilities/messagesApp.util';

export const ModalConfirmPayment = ({
	show,
	onHide,
	submitConfirm,
	paymentMethodSelected,
	accountTimeExtension,
}) => {
	return (
		<Modal
			show={show}
			onHide={onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{messagesApp.confirmActionText}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>{messagesApp.sureWantToMakePayment}</div>
				<div>
					<Card>
						<div className="px-2 my-3">{messagesApp.youSelected}:</div>
						<div className="px-2 d-flex flex-wrap ">
							<div className="col col-4 mt-2">
								{messagesApp.typeText}: {paymentMethodSelected?.creditCardType}
							</div>
							<div className="col col-4 mt-2">
								<div> {messagesApp.numberText}:</div>
								<div>
									XXXX XXXX XXXX
									{` ${paymentMethodSelected?.creditCardNumber}`}
								</div>
							</div>
							<div className="col col-4 mt-2">
								{messagesApp.expirationMonth}:
								{paymentMethodSelected?.creditCardExpirationMonth}
							</div>
							<div className="col col-4 mt-2">
								{messagesApp.expirationYear}:
								{paymentMethodSelected?.creditCardExpirationYear}
							</div>
							<div className="col col-4 my-2 d-flex flex-column">
								<div>{messagesApp.youAreAdd}:</div>
								<div>
									{accountTimeExtension}
									<strong>{messagesApp.daysText}</strong> {messagesApp.atRateOf}
									<strong>{` ${accountTimeExtension}`} $USD</strong>
								</div>
							</div>
						</div>
					</Card>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={submitConfirm}>
					{messagesApp.confirmPaymentText}
				</Button>
				<Button variant="danger" onClick={onHide}>
					{messagesApp.cancelText}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
