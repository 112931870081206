import styled from "styled-components";

const fromPadding = {
  multimedia: {
    "padding-top": 0,
  },
};

const DivFieldsStyled = styled.div`
  /* padding-top: 2rem; */
  padding-top: ${({ paddingFrom }) =>
    paddingFrom ? fromPadding[paddingFrom]["padding-top"] : "1rem"};
`;

export { DivFieldsStyled };
