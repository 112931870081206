import { useDispatch } from "react-redux";
import { feedbackSuccess } from "redux/actions/feedbackAction";
import { messagesApp } from "utilities/messagesApp.util";
import { utilStatusRequest } from "utilities/utilStatusRequest/utilStatusRequest";

export const useCatchErrors = () => {
  const dispatch = useDispatch();

  // info: is var with API response; textDetail: is code response in utilStatusRequest; where: is name function that made the call; withTry: Define if to wait throw error or not
  // catch exception or return msg error and continue flow
  const catchErrors = ({ info, textDetail, where = null, withTry = true }) => {
    // console.log({ info, textDetail, where, withTry });
    if (
      info &&
      (info.validationMessages?.length > 0 ||
        info.ValidationMessages?.length > 0)
    ) {
      // errors API response
      const arrayValidation =
        info.validationMessages?.length > 0
          ? [...info.validationMessages]
          : info.ValidationMessages?.length > 0
          ? [...info.ValidationMessages]
          : [{ message: messagesApp.unknownError }];

      arrayValidation.map((errorInfo) => console.error(errorInfo.message));

      dispatch(
        feedbackSuccess({
          variant: "danger",
          text: utilStatusRequest({ code: textDetail }),
        })
      );

      if (withTry) throw new Error(utilStatusRequest({ code: textDetail }));
      else return true;
    } else if (info) {
      // catch interceptors; exist info
      console.error("info exist - without error", where, " - ", info.error);
      dispatch(
        feedbackSuccess({
          variant: "danger",
          text: utilStatusRequest({ code: textDetail }),
        })
      );

      if (withTry) throw new Error(info.error);
      else return true;
    } else {
      // catch interceptors; dont exist info
      console.error("catch errors", where, " - ", info.error);
      dispatch(
        feedbackSuccess({
          variant: "danger",
          text: utilStatusRequest({ code: "X" }),
        })
      );

      if (withTry) throw new Error(utilStatusRequest({ code: "X" }));
      else return true;
    }
  };

  return { catchErrors };
};
