import { Alert } from "react-bootstrap";
import styled, { css } from "styled-components";
import { fadeIn } from "./animation.styled";

const fontFamily = "'Rubik', sans-serif";

const tabletStartWidth = 600;
const desktopStartWidth = 768;

const tabletSize = `@media (min-width: ${tabletStartWidth}px)`;
const desktopSize = `@media (min-width: ${desktopStartWidth}px)`;

const minHeight = "100vh - 112px";

const MainRowStyled = styled.div`
  min-height: ${({ windowSize: { height } }) =>
    height > 625 ? "100vh" : css`calc(${minHeight})`};

  padding-bottom: 10px;
  display: flex;
  align-items: start;
`;

const mobileTitlesStyled = `700 40px/40px ${fontFamily}`;

const mobileSsubTitlesStyled = `400 16px/16px ${fontFamily}`;

const mobileInputTexts = `400 12px/14px ${fontFamily}`;

const titlesStyled = `700 64px/76px ${fontFamily}`;

const subTitlesStyled = `400 20px/20px ${fontFamily}`;

const desktopInputTexts = `300 16px/19px ${fontFamily}`;

const desktopButtonTexts = `600 16px/19px ${fontFamily}`;

const desktopSmallTexts = `300 14px/20px ${fontFamily}`;

const defaultColors = {
  white: "#ffffff",
  transparentWhite: "rgba(255, 255, 255, 0)",
  warningYellow: "#D6B645",
  golden: "#CDA156",
  green: "#0f8131",
  skyBlue: "#8ECCCC",
  navyBlue: "#50717B",
  greyBlue: "rgba(58, 64, 66, 0.4)",
  greyDisabled: "#7E7E7E",
  darkBlue: "#26272E",
  red: "#CF3A3A",
  transparentRed: "rgba(207, 58, 58, 0.1)",
  black: "#000000",
  yellowBlack: "rgba(62, 67, 46, 0.4)",
  somethingTransparentWhite: "rgba(255, 255, 255, 0.1)",
};

const hoverEffectFieldsForm = css`
  color: ${defaultColors.white};
  background: ${defaultColors.darkBlue};
  outline: 1px solid ${defaultColors.white};
`;

const disabledButton = css`
  background-color: ${defaultColors.greyBlue};
  border-color: ${defaultColors.greyDisabled};
  border-radius: 12px;
  padding-left: 5px;
  padding-bottom: 10px;
  color: ${defaultColors.greyDisabled};
  cursor: not-allowed;
`;

const cardSectionFrom = {
  home: {
    height: "440px",
    margin: ".5rem 0",
  },
  accountInfo: {
    height: "661px",
    margin: "2rem 0",
  },
};

const WrapCardSectionStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: flex-start;
  width: 100%;
  margin: ${({ from }) => cardSectionFrom[from].margin};
  /* padding: 15px 0; */
  /* height: ${({ from }) => cardSectionFrom[from].height}; */
  /* overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 5px;
  }

  &::-webkit-scrollbar-button:increment {
    display: none;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fdfffe;
    border-radius: 10px;
    border: 3px solid #ffffff;
    transform: rotate(90deg);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: red;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  } */

  ${fadeIn({ time: "1s" })}
`;

const typeAlerts = {
  danger: {
    background: defaultColors.red,
    color: defaultColors.white,
    border: `2px solid ${defaultColors.red}`,
  },
  success: {
    background: defaultColors.skyBlue,
    color: defaultColors.darkBlue,
    border: `2px solid ${defaultColors.skyBlue}`,
  },
  warning: {
    background: defaultColors.golden,
    color: defaultColors.darkBlue,
    border: `2px solid ${defaultColors.golden}`,
  },
};

const AlertMessageStyled = styled(Alert)`
  background-color: ${({ type }) => typeAlerts[type].background} !important;
  color: ${({ type }) => typeAlerts[type].color} !important;
  border: ${({ type }) => typeAlerts[type].border} !important;
  font: ${desktopButtonTexts};

  ${fadeIn({ time: "1s" })};

  &:hover {
    background-color: ${defaultColors.greyBlue} !important;
    color: ${defaultColors.white} !important;
    outline: 2px solid ${defaultColors.greyBlue} !important;
  }
`;

export {
  fontFamily,
  MainRowStyled,
  titlesStyled,
  subTitlesStyled,
  desktopInputTexts,
  desktopButtonTexts,
  desktopSmallTexts,
  defaultColors,
  hoverEffectFieldsForm,
  tabletSize,
  desktopSize,
  mobileTitlesStyled,
  mobileSsubTitlesStyled,
  disabledButton,
  WrapCardSectionStyled,
  AlertMessageStyled,
  mobileInputTexts,
};
