import { ButtonsSubmitNewArtistFormStyled } from "../../../styled-components/ButtonsSubmitNewArtistForm.styled";

export const ButtonsSubmitNewArtistForm = ({
  type,
  text,
  from,
  background = "8ECCCC",
  color = null,
  click,
  disabled = false,
}) => {
  return (
    <ButtonsSubmitNewArtistFormStyled
      className="col col-5 my-3"
      type={type}
      from={from}
      background={background}
      color={color}
      onClick={click}
      disabled={disabled}
    >
      {text}
    </ButtonsSubmitNewArtistFormStyled>
  );
};
