import { addIcon } from "assets/images/images";
import { SpinnerLoadInputs } from "components/SpinnerLoad/SpinnerLoadInputs/SpinnerLoadInputs";
import { editValidationPictureField } from "utilities/utilAccount/editValidationFields.utils";
import { SpanErrorsStyled } from "views/Auth/styled-components/InputAuthForm.styled";
import { useImagesGroupForm } from "../../pages/NewArtists/hooks";
import {
  BoxAddImageFormStyled,
  InputImageStyled,
  WrapImageAndErrorMsgStyled,
} from "../../pages/NewArtists/styled-components";

export const ImagesGroupForm = ({
  item,
  index,
  register,
  errors,
  watch,
  gallery,
}) => {
  const { loadingUploadGroupImage, image } = useImagesGroupForm(watch, item);
  return (
    <WrapImageAndErrorMsgStyled
      className="wrap-image-and-error-msg-styled"
      index={index + 1}
      key={item.name}
    >
      <BoxAddImageFormStyled className="box-add-image-form-styled">
        {loadingUploadGroupImage ? (
          <SpinnerLoadInputs />
        ) : image ? (
          // <ProfilePictureImageStyled from="groups" src={image} alt="image" />
          <div
            style={{
              backgroundImage: "url(" + image + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top center",
              height: "208px",
              width: "208px",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          />
        ) : gallery && gallery[index]?.url ? (
          // <ProfilePictureImageStyled
          //   from="groups"
          //   src={gallery[index].url}
          //   alt="gallery image"
          // />
          <div
            style={{
              backgroundImage: "url(" + gallery[index].url + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top center",
              height: "208px",
              width: "208px",
              borderRadius: "8px",
            }}
          />
        ) : (
          addIcon({ width: "28px", height: "50px" })
        )}
        <InputImageStyled
          type="file"
          {...register(item.name, editValidationPictureField[item.name])}
        />
      </BoxAddImageFormStyled>
      {errors[item.name] && (
        <SpanErrorsStyled className="">
          {errors[item.name]["message"]}
        </SpanErrorsStyled>
      )}
    </WrapImageAndErrorMsgStyled>
  );
};
