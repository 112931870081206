import { messagesApp } from "utilities/messagesApp.util";
import { patterns } from "utilities/utilPatterns";

export const msgValidation = {
  required: messagesApp.fieldIsRequired,
  match: {
    password: messagesApp.passwordsMustMatch,
  },
  email: {
    pattern: messagesApp.enterValidEmail,
  },
  passwordField: {
    pattern: messagesApp.mustContainOneLetterAndNumber,
    numberOfCharacters: messagesApp.mustContainBetween,
  },
  picture: {
    invalidSize: messagesApp.imageCannotExceed,
    invalidType: messagesApp.imageFormatPermited,
  },
  phoneNumber: {
    invalidSize: messagesApp.enterValidNumber,
  },
  patternURL: {
    pattern: messagesApp.validateURLMessage,
  },
  patternNumber: {
    pattern: messagesApp.enterValidCapacity,
  },
};

const validationPictureField = {
  picture: {
    required: { value: true, message: msgValidation.required },
    validate: (picture) => {
      const typeImg = picture[0].type.split("/");

      if (picture[0].size > 2000000) return msgValidation.picture.invalidSize;

      if (typeImg[1] !== "png" && typeImg[1] !== "jpg" && typeImg[1] !== "jpeg")
        return msgValidation.picture.invalidType;
    },
  },
  galleryImage1: {
    // required: { value: true, message: msgValidation.required },
    validate: (galleryImage1) => {
      if (!galleryImage1 || galleryImage1.length === 0) return undefined;

      const typeImg = galleryImage1[0].type.split("/");

      if (galleryImage1[0].size > 2000000)
        return msgValidation.picture.invalidSize;

      if (typeImg[1] !== "png" && typeImg[1] !== "jpg" && typeImg[1] !== "jpeg")
        return msgValidation.picture.invalidType;
    },
  },
  galleryImage2: {
    // required: { value: true, message: msgValidation.required },
    validate: (galleryImage2) => {
      if (!galleryImage2 || galleryImage2.length === 0) return undefined;

      const typeImg = galleryImage2[0].type.split("/");

      if (galleryImage2[0].size > 2000000)
        return msgValidation.picture.invalidSize;

      if (typeImg[1] !== "png" && typeImg[1] !== "jpg" && typeImg[1] !== "jpeg")
        return msgValidation.picture.invalidType;
    },
  },
  galleryImage3: {
    // required: { value: true, message: msgValidation.required },
    validate: (galleryImage3) => {
      if (!galleryImage3 || galleryImage3.length === 0) return undefined;

      const typeImg = galleryImage3[0].type.split("/");

      if (galleryImage3[0].size > 2000000)
        return msgValidation.picture.invalidSize;

      if (typeImg[1] !== "png" && typeImg[1] !== "jpg" && typeImg[1] !== "jpeg")
        return msgValidation.picture.invalidType;
    },
  },
};

const validationSchemaAccount = {
  name: {
    required: { value: true, message: msgValidation.required },
  },
  email: {
    required: { value: true, message: msgValidation.required },
    pattern: {
      value: patterns.patternEmail,
      message: msgValidation.email.pattern,
    },
  },
  genre: {
    required: { value: true, message: msgValidation.required },
  },

  description: {
    required: { value: true, message: msgValidation.required },
  },
  webSite: {
    pattern: {
      value: patterns.patternURL,
      message: msgValidation.patternURL.pattern,
    },
  },
  phone: {
    required: { message: msgValidation.required },
    length: { message: msgValidation.phoneNumber.invalidSize },
  },
  ubication: {
    required: { value: true, message: msgValidation.required },
  },
  // locationName: {
  //   required: { value: true, message: msgValidation.required },
  // },
  capacity: {
    required: { value: true, message: msgValidation.required },
    pattern: {
      value: patterns.patternNumber,
      message: msgValidation.patternNumber.pattern,
    },
  },
  firstName: {
    required: { value: true, message: msgValidation.required },
  },
  lastName: {
    required: { value: true, message: msgValidation.required },
  },
  companyName: {
    required: { value: true, message: msgValidation.required },
  },
  eventUrl: {
    pattern: {
      value: patterns.patternURL,
      message: msgValidation.patternURL.pattern,
    },
  },
  eventDate: {},
};

export { validationSchemaAccount, validationPictureField };
