import { createContext, useContext } from "react";
import { debounce } from "debounce";
import { useState } from "react";
import { useEffect } from "react";
import { utilStatusRequest } from "utilities/utilStatusRequest/utilStatusRequest";
import { useNavigateInHome } from "../hooks/useNavigateInHome";
import { getInfoHome } from "../interceptors/getInfoHome.interceptor";
import { messagesApp } from "utilities/messagesApp.util";
import { getFilterHome } from "../interceptors/getFilterHome.interceptor";
import { useDispatch, useSelector } from "react-redux";
import { useGeoLocation } from "hooks/useGeoLocation";
import {
  geolocationFailure,
  geolocationSuccess,
} from "redux/actions/geoLocationAction";

const HomeContext = createContext();

const INITIALPAGE = 1;

const HomeProvider = ({ children }) => {
  const location = useGeoLocation();

  const dispatch = useDispatch();

  const { clickToggleBtn, handleToggleBtnClick } = useNavigateInHome();

  const [pageNumber, setPageNumber] = useState(1);
  const [information, setInformation] = useState([]);
  const [informationToRender, setInformationToRender] = useState([]);

  const [messageEmptyFilter, setMessageEmptyFilter] = useState(null);

  const [messageError, setMessageError] = useState(null);

  const [loadingData, setLoadingData] = useState(true);

  const handleSetPageNumber = (number) => {
    setPageNumber(number);
  };

  const isArtist = {
    artists: 1,
    locations: 2,
  };

  useEffect(() => {
    if (informationToRender.length > 0) {
      setLoadingData(false);
    }
  }, [informationToRender]);

  useEffect(() => {
    setLoadingData(true);

    const fetchData = async (coords) => {
      try {
        setInformation([]);
        setInformationToRender([]);
        setMessageEmptyFilter(null);

        const fetch = await getInfoHome({
          type: isArtist[clickToggleBtn],
          pageNumber,
          coords,
        });

        if (!fetch || !fetch.success) {
          fetch?.validationMessages?.map((messageError) =>
            console.error(
              utilStatusRequest({
                code: messageError.code,
                msgRequest: messageError.message,
              })
            )
          );

          throw new Error(
            utilStatusRequest({
              code: "X",
              msgRequest: messagesApp.problemsToSearchArtistOrLocations,
            })
          );
        }

        setMessageError(null);
        setInformation(fetch.data.data);
        setInformationToRender(fetch.data.data);
      } catch (error) {
        console.error("error", error.message);
        setMessageError(error.message);
      } finally {
        setLoadingData(false);
      }
    };

    if (location.success !== "") {
      fetchData(location.coords);
    }
  }, [clickToggleBtn, location]);

  useEffect(() => {
    if (location.success === "success")
      dispatch(geolocationSuccess(location.coords));
    else if (location.success === "error")
      dispatch(geolocationFailure(location.error));
  }, [location, dispatch]);

  const handleSearch = debounce(async (e) => {
    try {
      setLoadingData(true);
      if (!e.target.value) {
        setInformationToRender(information);
        setMessageEmptyFilter(null);
      } else {
        const filterToName = await getFilterHome(
          isArtist[clickToggleBtn],
          e.target.value
        );

        if (!filterToName || !filterToName.success)
          throw new Error(messagesApp.catchFilter);

        if (filterToName.data.data.length === 0)
          setMessageEmptyFilter(messagesApp.emptyFilter);
        else {
          setMessageEmptyFilter(null);
          setInformationToRender(filterToName.data.data);
        }
      }
    } catch (error) {
      console.error("error", error.message);
    } finally {
      setLoadingData(false);
    }
  }, 700);

  return (
    <HomeContext.Provider
      value={{
        clickToggleBtn,
        handleToggleBtnClick,
        informationToRender,
        loadingData,
        handleSearch,
        messageError,
        handleSetPageNumber,
        messageEmptyFilter,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

const useHomeContext = () => {
  const context = useContext(HomeContext);
  if (context === undefined)
    throw new Error("Home context must be used within a Home provider");

  return context;
};

export { useHomeContext, HomeProvider };
