import { SpanErrorsStyled } from "views/account/pages/YourAccount/styled-components";
import { TextAreaFieldStyled } from "views/account/styled-components";

export const TextAreaForm = ({
  register,
  errors,
  validationSchema,
  placeholder,
}) => {
  return (
    <>
      <TextAreaFieldStyled
        as="textarea"
        aria-label="With textarea"
        placeholder={placeholder}
        name="description"
        {...register("description", validationSchema["description"])}
      />
      {errors["description"] && (
        <SpanErrorsStyled>{errors["description"]["message"]}</SpanErrorsStyled>
      )}
    </>
  );
};
