import { TYPES } from "../types/types";

const initialAccountState = {
  loadingAccount: false,
  account: {},
  error: false,
  success_message: "",
  success_request: false,
};

const {
  ACCOUNT_REQUEST,
  ACCOUNT_SUCCESS,
  ACCOUNT_FAILURE,
  ACCOUNT_RESET_NOTIFICATION,
  ACCOUNT_RESET,
} = TYPES;

export const accountReducer = (state = initialAccountState, action) => {
  switch (action.type) {
    case ACCOUNT_REQUEST:
      return { ...state, loadingAccount: true };

    case ACCOUNT_SUCCESS:
      return {
        ...initialAccountState,
        account: action.payload.account,
        success_message: action.payload.success_message,
        success_request: true,
      };

    case ACCOUNT_FAILURE:
      return {
        ...initialAccountState,
        account: state.account,
        error: action.payload,
      };

    case ACCOUNT_RESET_NOTIFICATION:
      return {
        ...initialAccountState,
        account: state.account,
      };

    case ACCOUNT_RESET:
      return { ...initialAccountState };

    default:
      return state;
  }
};
