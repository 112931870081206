import { Outlet } from "react-router-dom";
import { ToggleBtnGroup } from "views/Home/components/ToggleBtnGroup";
import { useSpecialFilterContext } from "views/Home/contexts/SpecialFilterProvider";

export const SpecialFilter = () => {
  const { handleToggleBtnClick, clickToggleBtn } = useSpecialFilterContext();

  return (
    <div className="row m-0 w-100">
      <div className="col col-12">
        <ToggleBtnGroup
          handleToggleBtnClick={handleToggleBtnClick}
          clickToggleBtn={clickToggleBtn}
        />
      </div>
      <Outlet />
    </div>
  );
};
