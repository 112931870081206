import { createContext, useContext } from "react";
import { debounce } from "debounce";
import { useState } from "react";
import { useEffect } from "react";
import { useGetParams } from "hooks/useGetParams";
import { getProfileMultimedia } from "../interceptors/getProfileMultimedia.interceptor";
import { messagesApp } from "utilities/messagesApp.util";

const ProfileMultimediaContext = createContext();

const ProfileMultimediaProvider = ({ children }) => {
  const { parameterValue: isLocation } = useGetParams("idlocations");
  const { parameterValue: isArtist } = useGetParams("idartists");
  // console.log({ isLocation, isArtist });

  const [whatIs, setWhatIs] = useState("");
  const [data, setData] = useState({});
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingData(true);
        const id = isArtist ? isArtist : isLocation;
        const getInfo = await getProfileMultimedia(id);

        if (!getInfo.success)
          throw new Error(messagesApp.catchDetailsProfileMultimedia);

        setData(getInfo.data);
      } catch (error) {
        console.error("error", error.message);
      } finally {
        setLoadingData(false);
      }
    };

    if (isLocation) setWhatIs("location");
    else if (isArtist) setWhatIs("artist");
    fetchData();
  }, []);

  return (
    <ProfileMultimediaContext.Provider value={{ whatIs, data, loadingData }}>
      {children}
    </ProfileMultimediaContext.Provider>
  );
};

const useProfileMultimediaContext = () => {
  const context = useContext(ProfileMultimediaContext);
  if (context === undefined)
    throw new Error(
      "ProfileMultimedia context must be used within a ProfileMultimedia provider"
    );

  return context;
};

export { useProfileMultimediaContext, ProfileMultimediaProvider };
