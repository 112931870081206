const { default: Cookies } = require("universal-cookie");

const cookies = new Cookies();

export const useCookies = () => {
  var minutes = 10;
  //var now = new Date();
  //   const expires = now.setTime(now.getTime() + minutes * 60 * 1000);

  const createCookie = (nameCookie, data) => {
    cookies.set(nameCookie, data, {
      sameSite: "strict",
      path: "/",
      expires: new Date(new Date().getTime() + minutes * 60 * 1000),
      secure: true,
    });
  };

  const deleteCookie = (nameCookie) => {
    cookies.remove(nameCookie);
  };

  const getNameCookie = (nameCookie) => cookies.get(nameCookie);

  return { createCookie, deleteCookie, getNameCookie };
};
