import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import {
	useStripe,
	useElements,
	PaymentElement,
} from '@stripe/react-stripe-js';

import { Link, useSearchParams } from 'react-router-dom';
import { postPaymentMethodsSession } from 'views/account/interceptors/paymentsInterceptor/postPaymentMethodsSession.interceptor';
import { SpinnerLoad } from 'components/SpinnerLoad/SpinnerLoad';
import { messagesApp } from 'utilities/messagesApp.util';

const { REACT_APP_STRIPE_PK: STRIPEPK } = process.env;
// const { REACT_APP_STRIPE_PK_TEST: STRIPEPK } = process.env;

const SetupForm = ({ sessionId, accountId, type, profileId }) => {
	const stripe = useStripe();
	const elements = useElements();

	const [errorMessage, setErrorMessage] = useState(null);

	const [loadingAddedMethod, setLoadingAddedMethod] = useState(false);
	const [addedMethod, setAddedMethod] = useState(false);

	let URLorigin = window.location;
	const returnUrl = `${URLorigin.origin}/account/${accountId}/payments-section?si=${sessionId.sessionId}&pi=${profileId}&ai=${accountId}&type=${type}`;

	useEffect(() => {
		const prepareNewMethod = async () => {
			try {
				if (!stripe || !elements) {
					return;
				}

				const { error } = await stripe.confirmSetup({
					elements,
					confirmParams: {
						return_url: returnUrl,
					},
				});

				if (error) {
					setErrorMessage(error.message);
					setLoadingAddedMethod(false);
					setAddedMethod(false);
					throw new Error(error.message);
				}
			} catch (error) {
				console.error('error', error.message);
			}
		};

		if (addedMethod) prepareNewMethod();

		return () => {
			setErrorMessage(null);
			setLoadingAddedMethod(false);
			setAddedMethod(false);
		};

		//eslint-disable-next-line
	}, [addedMethod]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoadingAddedMethod(true);
		setAddedMethod(true);
	};

	return (
		<form onSubmit={handleSubmit} className="card card-body">
			<div>{messagesApp.addPaymentMethodToUse}</div>
			{loadingAddedMethod && <SpinnerLoad />}
			<PaymentElement />
			<button disabled={!stripe}>Aceptar</button>
			{errorMessage && <div>{errorMessage}</div>}
		</form>
	);
};
// end SetupForm

// add api key - this apikey is defined in envroiment file
const stripePromise = loadStripe(STRIPEPK);

function AddPaymentMethod() {
	const [serachUrl] = useSearchParams();
	//   // ai - account id
	const accountId = serachUrl.get('ai');
	//   // ui - user id
	const userId = serachUrl.get('ui');

	// type - is location or artist
	const type = serachUrl.get('type');

	// pi - profile id
	const profileId = serachUrl.get('pi');

	const [options, setOptions] = useState({
		clientSecret: '',
		appearance: {
			theme: 'stripe',

			variables: {
				colorPrimary: '#8ECCCC',
				colorBackground: '#30313d',
				colorText: '#ffffff',
				colorDanger: '#CF3A3A',
				fontFamily: "'Rubik', sans-serif",
				spacingUnit: '2px',
				borderRadius: '8px',
			},
		},
	});
	const [sessionId, setSessionId] = useState({});
	const [loadMethodPayment, setLoadMethodPayment] = useState(true);

	const [errorMessageUploadPaymentMethod, setErrorMessageUploadPaymentMethod] =
		useState('');

	useEffect(() => {
		const fetchPaymentMethodsSession = async () => {
			try {
				setErrorMessageUploadPaymentMethod('');
				setLoadMethodPayment(true);
				const fetchPaymentMethodsSession = await postPaymentMethodsSession(
					accountId,
					userId
				);

				if (!fetchPaymentMethodsSession || !fetchPaymentMethodsSession.success)
					throw new Error('tuvimos un problema');

				setOptions({
					...options,
					clientSecret: fetchPaymentMethodsSession.data.sessionSecret,
				});
				setSessionId({
					sessionId: fetchPaymentMethodsSession.data.sessionId,
				});
			} catch (error) {
				console.log('error fetchPaymentMethodsSession', error.message);
				setErrorMessageUploadPaymentMethod(error.message);
			} finally {
				setLoadMethodPayment(false);
			}
		};

		fetchPaymentMethodsSession();

		//eslint-disable-next-line
	}, []);

	if (loadMethodPayment)
		return (
			<div className="d-flex justify-content-center align-items-center">
				<SpinnerLoad />
			</div>
		);
	if (errorMessageUploadPaymentMethod)
		return (
			<div>
				<h2>{errorMessageUploadPaymentMethod}</h2>
				<Link
					to={`/account/${accountId}/payments-section?pi=${profileId}&ai=${accountId}&type=${type}`}
				>
					{messagesApp.backToPaymentSection}
				</Link>
			</div>
		);

	return (
		<div className="payments-section-page">
			<Link
				to={`/account/${accountId}/payments-section?pi=${profileId}&ai=${accountId}&type=${type}`}
				className="btn-back-payments btn btn-warning"
			>
				{messagesApp.backToPaymentSection}
			</Link>

			<Elements stripe={stripePromise} options={options}>
				<SetupForm
					sessionId={sessionId}
					accountId={accountId}
					type={type}
					profileId={profileId}
				/>
			</Elements>
		</div>
	);
}

export default AddPaymentMethod;
