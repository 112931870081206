import { peopleIcon, locationOnIcon } from 'assets/images/images';

const yourAccountFields = [
	{
		type: 'text',
		name: 'firstName',
		placeholder: '*Nombres',
	},
	{
		type: 'text',
		name: 'lastName',
		placeholder: '*Apellidos',
	},
	{
		type: 'text',
		name: 'companyName',
		placeholder: '*Compañía/Empresa',
	},
	{
		type: 'text',
		name: 'email',
		placeholder: '*Email de Contacto',
	},
];

const yourAccountDashedFields = [
	{
		name: 'new-artist',
		text: 'Añadir nuevo artista',
	},
	{
		name: 'new-location',
		text: 'Añadir nueva locación',
	},
];

const yourAccountProfileFields = [
	{
		name: 'artists',
		defaultName: 'Artistas',
		icon: peopleIcon,
		path: 'account-info?type=artists',
	},
	{
		name: 'location',
		defaultName: 'Locaciones',
		icon: locationOnIcon,
		path: 'account-info?type=locations',
	},
];

export { yourAccountFields, yourAccountProfileFields, yourAccountDashedFields };
