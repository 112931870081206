import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";
import { BEARERAUTH } from "utilities/authHeader.util";

export const putEvent = async (profileId, restUrl, objEvent, idEvent) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: BEARERAUTH(),
    };

    const fetchingData = await fetchAPI({
      method: "PUT",
      url: `${URLs.profiles}/${profileId}/${restUrl}/${idEvent}`,
      headers,
      body: JSON.stringify(objEvent),
    });

    return fetchingData;
  } catch (error) {
    console.log("error put event interceptor", { error });
    return { success: false, code: "X" };
  }
};
