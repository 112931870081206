import { SpinnerLoad } from 'components/SpinnerLoad/SpinnerLoad';
import { useCatchErrors } from 'hooks/useCatchErrors';
import { useState } from 'react';
import { useEffect } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getAccount } from 'redux/actions/accountAction';
import { feedbackSuccess } from 'redux/actions/feedbackAction';
import { messagesApp } from 'utilities/messagesApp.util';
import { getUserPaymentMethods } from 'views/account/interceptors/paymentsInterceptor/getUserPaymentMethods.interceptor';
import { postPaymentMethods } from 'views/account/interceptors/paymentsInterceptor/postPaymentMethods.interceptor';
import { postPayments } from 'views/account/interceptors/paymentsInterceptor/postPayments.interceptor';
import { ModalConfirmPayment } from './ModalConfirmPayment';

let minValue = 0;
const validationSchema = {
	paymentMethod: {
		required: { value: true, message: messagesApp.mustSelectPaymentMethod },
	},
	amountDays: {
		required: {
			value: true,
			message: messagesApp.mustSelectNumberDaysWantToAdd,
		},
		validate: {
			positiveNumber: (value) => {
				return parseInt(value) > minValue;
			},
		},
	},
};

export const PaymentSection = () => {
	const { user } = useSelector((state) => state.userReducer);
	const { catchErrors } = useCatchErrors();

	const dispatch = useDispatch();

	const [loadingPayment, setLoadingPayment] = useState(false);

	const [serachUrl] = useSearchParams();
	// si - session id
	const sessionId = serachUrl.get('si');

	// type - is location or artist
	const type = serachUrl.get('type');

	// pi - profile id
	const profileId = serachUrl.get('pi');

	// ui - user id
	const userId = user.id;

	// ai - account id
	const accountId = serachUrl.get('ai');

	const [modalConfirmPaymentShow, setModalConfirmPaymentShow] = useState(false);
	const [dataSubmit, setDataSubmit] = useState(null);

	const [paymentMethods, setPaymentMethods] = useState([]);
	const [addedNewPaymentMethod, setAddedNewPaymentMethod] = useState(false);

	const [messageWithoutPaymentMethod, setMessageWithoutPaymentMethod] =
		useState('');

	const [paymentMethodSelected, setPaymentMethodSelected] = useState({});

	const [accountTimeExtension, setAccountTimeExtension] = useState('30');

	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm({ defaultValues: { paymentMethod: '', amountDays: 30 } });

	// retrieve existing payment methods associated with account
	const fetchPaymentMethods = async () => {
		setMessageWithoutPaymentMethod('');
		try {
			setLoadingPayment(true);
			// get PaymentMethods
			const fetchMethods = await getUserPaymentMethods(accountId);

			if (!fetchMethods || !fetchMethods.success) {
				catchErrors({
					info: fetchMethods,
					textDetail: 'errorPaymentSection',
					where: 'fetchPaymentMethods - payment section',
				});
			}

			if (fetchMethods.data.length > 0) {
				setPaymentMethods(fetchMethods.data);
				setAddedNewPaymentMethod(true);
			} else setMessageWithoutPaymentMethod(messagesApp.dontHavePaymentMethod);
		} catch (error) {
			console.error('error', error.message);
		} finally {
			setLoadingPayment(false);
		}
	};

	// get new payment method added
	const fetchUserPaymentMethods = async () => {
		setMessageWithoutPaymentMethod('');
		try {
			setLoadingPayment(true);
			// get PaymentMethods
			const fetchMethods = await postPaymentMethods(accountId, sessionId);

			if (!fetchMethods || !fetchMethods.success) {
				catchErrors({
					info: fetchMethods,
					textDetail: 'errorUserPaymentMethods',
					where: 'fetchUserPaymentMethods - payment section',
				});
			}

			if (fetchMethods.data.length === 0)
				setMessageWithoutPaymentMethod('Aún no tienes un método de pago');
			else {
				// finished add new payment method
				setAddedNewPaymentMethod(true);
				setMessageWithoutPaymentMethod('');
				fetchPaymentMethods();
			}
		} catch (error) {
			console.error('error', error.message);
		} finally {
			setLoadingPayment(false);
		}
	};

	// search payment method
	useEffect(() => {
		if (!sessionId && !addedNewPaymentMethod) {
			fetchPaymentMethods();
		} else if (!addedNewPaymentMethod) fetchUserPaymentMethods(); // guarantee added payment method

		//eslint-disable-next-line
	}, []);

	const submitConfirm = async () => {
		try {
			setModalConfirmPaymentShow(false);
			setLoadingPayment(true);

			const dataToSend = {
				paymentMethodId: dataSubmit.paymentMethod,
				days: dataSubmit.amountDays,
				amount: dataSubmit.amountDays,
				accountId,
				profileId,
				currency: 'USD',
			};

			const sendPayment = await postPayments(dataToSend);

			if (!sendPayment || !sendPayment.success) {
				catchErrors({
					info: sendPayment,
					textDetail: 'errorPayment',
					where: 'onSubmit - payment section',
				});
			}

			dispatch(getAccount(userId, undefined, 'Successful operation'));

			dispatch(
				feedbackSuccess({
					variant: 'success',
					text:
						messagesApp.successfullPayment + '. ' + messagesApp.successfullPost,
				})
			);

			navigate(`/account/${accountId}/account-info?type=${type}`, {
				replace: true,
			});
		} catch (error) {
			console.error('error', error.mnessage);
			dispatch(
				feedbackSuccess({
					variant: 'danger',
					text: messagesApp.catchPaymentSection,
				})
			);
		} finally {
			setLoadingPayment(false);
			setDataSubmit(null);
		}
	};

	const onSubmit = async (data) => {
		setDataSubmit(data);
	};

	useEffect(() => {
		if (dataSubmit) setModalConfirmPaymentShow(true);
	}, [dataSubmit]);

	const watchFields = watch(['paymentMethod', 'amountDays']);

	useEffect(() => {
		const findPaymentMethodSelected = paymentMethods.find(
			(element) => element.id === parseInt(watchFields[0])
		);

		if (findPaymentMethodSelected)
			setPaymentMethodSelected(
				paymentMethods.find(
					(element) => element.id === parseInt(watchFields[0])
				)
			);

		setAccountTimeExtension(watchFields[1]);

		//eslint-disable-next-line
	}, [watchFields]);

	return (
		<div className="payment-container">
			<div className="Add-payment-method">
				<Link
					to={`/account/${accountId}/add-payment-method?ai=${accountId}&ui=${userId}&pi=${profileId}&type=${type}`}
					className="btn btn-warning"
				>
					{messagesApp.addPaymentMethodToUse}
				</Link>
			</div>

			{paymentMethods.length > 0 && (
				<Card className="p-5 payment-selection">
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>{messagesApp.paymentMethodText}</Form.Label>
							<Form.Select
								{...register('paymentMethod', validationSchema.paymentMethod)}
							>
								<option value="">{messagesApp.selectPaymentMethod}</option>
								{paymentMethods?.map(
									(payMethod, idx) =>
										payMethod.active && (
											<option key={idx} value={payMethod.id}>
												{payMethod.creditCardType}
											</option>
										)
								)}
							</Form.Select>
							{errors.paymentMethod && (
								<div>{errors.paymentMethod.message}</div>
							)}
						</Form.Group>

						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>{messagesApp.amountDaysValueOneDollar}</Form.Label>
							<Form.Control
								type="number"
								placeholder={messagesApp.addAmountDays}
								{...register('amountDays', validationSchema.amountDays)}
								min={1}
							/>
							{errors.amountDays && (
								<div>
									{errors.amountDays.message
										? errors.amountDays.message
										: messagesApp.enterNumberEqualToOrGreaterOne}
								</div>
							)}
						</Form.Group>

						{watchFields[0] && Object.keys(paymentMethodSelected).length > 0 && (
							<div className="card-info">
								<h3>{paymentMethodSelected.creditCardType}</h3>
								XXXX XXXX XXXX
								{` ${paymentMethodSelected.creditCardNumber}`}
								<br />
								Exp {paymentMethodSelected.creditCardExpirationMonth}/
								{paymentMethodSelected.creditCardExpirationYear}
								<div>{messagesApp.youAreAdd}:</div>
								<hr />
								{accountTimeExtension} <strong>{messagesApp.daysText}</strong>{' '}
								{messagesApp.atRateOf}
								<strong>${`${accountTimeExtension}`}</strong>
							</div>
						)}

						{loadingPayment && <SpinnerLoad />}

						<div className="btn-container">
							<Link className="btn-cancel" to={`/account/${accountId}`}>
								{messagesApp.cancelText}
							</Link>
							<button className="btn btn-success" type="submit">
								{messagesApp.postText}
							</button>
						</div>
					</Form>
				</Card>
			)}
			{messageWithoutPaymentMethod && <div>{messageWithoutPaymentMethod}</div>}

			<ModalConfirmPayment
				show={modalConfirmPaymentShow}
				onHide={() => setModalConfirmPaymentShow(false)}
				submitConfirm={submitConfirm}
				accountTimeExtension={accountTimeExtension}
				paymentMethodSelected={paymentMethodSelected}
			/>
		</div>
	);
};
