import { messagesApp } from "utilities/messagesApp.util";
import { InputAutocompleteStyled } from "views/account/styled-components";
import { useGoogleAutocomplete } from "views/Home/hooks/useGoogleAutocomplete";

export const AutoCompleteGoogle = () => {
  const { inputRef, handleChangeValue } = useGoogleAutocomplete();

  return (
    <InputAutocompleteStyled
      className="col col-12"
      ref={inputRef}
      onChange={handleChangeValue}
      placeholder={messagesApp.searchLocation}
      from="autocompleteGoogle"
    />
  );
};
