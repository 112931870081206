import { InputStyled } from 'components/InputForm/styled-components/Input.styled';
import { Modal } from 'react-bootstrap';
import { usePlatformLinksModal } from '../../pages/NewArtists/hooks';
import {
	ButtonModalLinksPlatformsStyled,
	BodyModalLinksPlatformsStyled,
	FooterModalLinksPlatformsStyled,
	HeaderModalLinksPlatformsStyled,
} from '../../pages/NewArtists/styled-components';

export const PlatformLinksModal = (props) => {
	const {
		handleClickInSaveButon,
		handleChangeURL,
		ref,
		disabledSaveButton,
		inputUrl,
		loadingUrl,
	} = usePlatformLinksModal(props);
	// console.log({disabledSaveButton})
	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<HeaderModalLinksPlatformsStyled closeButton>
				<Modal.Title id="contained-modal-title-center">
					{props.section}
				</Modal.Title>
			</HeaderModalLinksPlatformsStyled>
			<BodyModalLinksPlatformsStyled>
				<h4>Agrega tu link de {props.item?.source}</h4>
				<div className="d-flex">
					{loadingUrl && <div>CARGANDO</div>}
					<InputStyled
						type="text"
						className="form-control"
						placeholder="Ingresa la URL con http:// o https://"
						onChange={(e) => handleChangeURL(e, props.item.typeId)}
						value={inputUrl}
					/>
				</div>
			</BodyModalLinksPlatformsStyled>
			<FooterModalLinksPlatformsStyled>
				<ButtonModalLinksPlatformsStyled
					from="saved"
					onClick={handleClickInSaveButon}
					disabled={disabledSaveButton}
				>
					Guardar
				</ButtonModalLinksPlatformsStyled>
				<ButtonModalLinksPlatformsStyled onClick={props.onHide} ref={ref}>
					Close
				</ButtonModalLinksPlatformsStyled>
			</FooterModalLinksPlatformsStyled>
		</Modal>
	);
};
