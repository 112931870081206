import { useProfileImage } from '../../pages/NewArtists/hooks';
import {
	IconUploadImageStyled,
	ProfilePictureImageStyled,
} from '../../pages/NewArtists/styled-components';
import { cameraIcon } from 'assets/images/images';
import { SpinnerLoadInputs } from 'components/SpinnerLoad/SpinnerLoadInputs/SpinnerLoadInputs';

export const IconUploadImage = ({ errors, watch, linksProfilePicture }) => {
	const { image, loadingUploadProfileImage } = useProfileImage(
		watch,
		linksProfilePicture
	);

	const whatsDoRenderInImageProfile = (load, img, errorPicture, camImg) => {
		if (load) return <SpinnerLoadInputs />;
		else if (img && !errorPicture)
			return <ProfilePictureImageStyled src={image} alt="image" />;
		else return camImg;
	};

	return (
		<IconUploadImageStyled>
			{whatsDoRenderInImageProfile(
				loadingUploadProfileImage,
				image,
				errors.picture,
				cameraIcon
			)}
		</IconUploadImageStyled>
	);
};
