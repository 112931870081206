import { URLs } from "services/servicesUrls.services";
import fetchAPI from "../../../services/fetchAPI.services";

export const fetchingAccount = async (userId, bearer) => {
  try {
    const headers = {
      Authorization: bearer,
    };

    const fetchAccount = await fetchAPI({
      method: "GET",
      url: `${URLs.users}/${userId}/Accounts`,
      headers,
    });

    return fetchAccount;
  } catch (error) {
    console.log("error get interceptor", { error });
    return { success: false, code: "X" };
  }
};
