import { Card } from "components/Card/Card";

import { SpinnerLoad } from "components/SpinnerLoad/SpinnerLoad";
import { useEffect, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { WrapCardSectionStyled } from "styled-components/App.styled";
import { WrapInputSearchStyled } from "styled-components/InputSearch/InputSearch.styled";
import { messagesApp } from "utilities/messagesApp.util";
import { inputSearch } from "utilities/utilAccount/utilAccountInfo";

import {
  actionsSection,
  buttonSection,
  citiesInfo,
  genresInfo,
} from "utilities/utilHome/utilSpecialFilter/specialFilterArtistForm.util";
import { SpanErrorsStyled } from "views/account/pages/YourAccount/styled-components";
import { TextDesktopSubtitles } from "views/account/styled-components";
import { useSpecialFilterContext } from "views/Home/contexts/SpecialFilterProvider";
import {
  CardNameStyled,
  GenreNameStyled,
  WrapCardStyled,
} from "views/Home/pages/CardsSectionHome/styled-components/CardsSectionHome.styled";
import {
  ActionsSectionSetyled,
  SearchButtonSectionStyled,
} from "views/Home/styled-components/SpecialFilterArtistForm.styled";
import { AutoCompleteGoogle } from "../AutoCompleteGoogle/AutoCompleteGoogle";
import { CitiesFiltersection } from "../CitiesFilterSection/CitiesFiltersection";
import { GenresFilterSection } from "../GenresFilterSection/GenresFilterSection";
import { InputSearchInSpecialFilter } from "../InputSearchInSpecialFilter/InputSearchInSpecialFilter";

export const SpecialFilterArtistForm = () => {
  const {
    showType,
    handleType,
    handleClickSearchButton,
    resetGenres,
    resetCities,
    handleGenreFilter,
    genresToRenderSelected,
    loadingData,
    messageEmptyFilter,
    messageinformationToRender,
    informationToRender,
    setInformationToRender,
    clickToggleBtn,
    setRenderGenresOrCitiesData,
    genresOrCitiesData,
    citiesToRenderSelected,
    setMessageEmptyFilter,
  } = useSpecialFilterContext();

  const refSearchBtn = useRef();
  const navigate = useNavigate();

  const [disabledButtonSearch, setDisabledButtonSearch] = useState(true);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (genresToRenderSelected.length === 0 && citiesToRenderSelected === "") {
      setIsActive(false);
      setDisabledButtonSearch(true);
    } else {
      setDisabledButtonSearch(false);
      if (genresToRenderSelected.length > 0 && citiesToRenderSelected)
        setIsActive("all");
      else if (genresToRenderSelected.length > 0) setIsActive(genresInfo.name);
      else if (citiesToRenderSelected) setIsActive(citiesInfo.name);
    }
  }, [genresToRenderSelected, citiesToRenderSelected]);

  useEffect(() => {
    setInformationToRender([]);
    setMessageEmptyFilter(null);

    if (showType === "artists") handleType(genresInfo.name);

    return () => {
      resetGenres();
      resetCities();
      setInformationToRender([]);
    };
  }, []);

  const handleClickInCard = (idCard) => {
    navigate(`/profile/${clickToggleBtn}/${idCard}`);
  };

  const handleClickInAction = (item) => {
    if (showType === "Genres") refSearchBtn.current.value = "";
    setInformationToRender([]);
    setRenderGenresOrCitiesData(genresOrCitiesData);
    handleType(item.name);
  };

  return (
    <div className="special-filter-artist-form col  col-12">
      <div className="row actions-section m-0 d-flex justify-content-between NextButtonSectionStyled">
        {actionsSection.map((item, index) => (
          <ActionsSectionSetyled
            key={index}
            className="actions-section-setyled col col-5"
            isActive={isActive}
            nameBtn={item.name}
            onClick={() => handleClickInAction(item)}
          >
            {item.text}
          </ActionsSectionSetyled>
        ))}
      </div>

      <div>
        {showType === "Genres" ? (
          inputSearch.map((input, index) => (
            <InputSearchInSpecialFilter
              key={index}
              input={input}
              change={handleGenreFilter}
              ref={refSearchBtn}
            />
          ))
        ) : (
          <WrapInputSearchStyled from="home">
            <AutoCompleteGoogle />
          </WrapInputSearchStyled>
        )}
      </div>

      <div className="row items-section m-0 py-3">
        {loadingData && <SpinnerLoad />}
        {messageEmptyFilter && (
          <SpanErrorsStyled>{messageEmptyFilter}</SpanErrorsStyled>
        )}
        {messageinformationToRender && (
          <Alert variant="danger">{messageinformationToRender}</Alert>
        )}
        {informationToRender.length > 0 && (
          <WrapCardSectionStyled className="col col-12 p-0" from="home">
            <TextDesktopSubtitles className="col col-12 pb-3">
              {messagesApp.didYouSearch}
              {genresToRenderSelected.length > 0 &&
                ` ${genresToRenderSelected[0].description}`}
              {citiesToRenderSelected && ` en ${citiesToRenderSelected}`}
            </TextDesktopSubtitles>
            {informationToRender?.map((card, index) => (
              <WrapCardStyled
                key={index}
                onClick={() => handleClickInCard(card.id)}
              >
                <Card
                  image={card.picture}
                  altImg={
                    card.type === "1"
                      ? `${card.name} artist image`
                      : `${card.name} location image`
                  }
                />
                <CardNameStyled>{card.name}</CardNameStyled>
                {card.genres.length > 0 && (
                  <GenreNameStyled>
                    {card.genres[0].description}
                  </GenreNameStyled>
                )}
              </WrapCardStyled>
            ))}
          </WrapCardSectionStyled>
        )}
        {showType === "Genres" ? (
          <GenresFilterSection />
        ) : (
          <CitiesFiltersection />
        )}
      </div>
      <div className="row next-section m-0 pb-3">
        <div className="col col-12 p-0">
          {buttonSection.map((button, index) => (
            <SearchButtonSectionStyled
              key={index}
              onClick={handleClickSearchButton}
              disabled={disabledButtonSearch}
            >
              {button.text}

              {genresToRenderSelected.length > 0 &&
                ` ${genresToRenderSelected[0].description}`}

              {citiesToRenderSelected && ` en ${citiesToRenderSelected}`}
            </SearchButtonSectionStyled>
          ))}
        </div>
      </div>
    </div>
  );
};
