import fetchAPI from 'services/fetchAPI.services';
import { BEARERAUTH } from 'utilities/authHeader.util';

export const postPaymentMethods = async (accountId, sessionId) => {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: BEARERAUTH(),
		};

		const fetchPaymentMethods = await fetchAPI({
			url: `https://api.showbookie.com/v1/Account/${accountId}/PaymentMethods`,
			method: 'POST',
			headers,
			body: JSON.stringify({ sessionId }),
		});

		return fetchPaymentMethods;
	} catch (error) {
		console.error('error post interceptor postPaymentMethods', { error });
		return { success: false, code: 'X' };
	}
};
