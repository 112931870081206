import { useCatchErrors } from "hooks/useCatchErrors";
import { useCookies } from "hooks/useCookies";
import { useDispatch } from "react-redux";
import { getAccount, resetAccount } from "redux/actions/accountAction";
import {
  resetUser,
  userFailure,
  userRequest,
  userSuccess,
} from "redux/actions/userAction";
import { utilStatusRequest } from "utilities/utilStatusRequest/utilStatusRequest";

import { authenticateUser, userLogin } from "../interceptor";

const { REACT_APP_TOKEN: USERTOKEN } = process.env;

export const useAuthenticate = () => {
  const dispatch = useDispatch();
  const { createCookie, deleteCookie } = useCookies();
  const { catchErrors } = useCatchErrors();

  const getUserById = async ({ userId, code = null, token }) => {
    try {
      dispatch(userRequest());
      // console.log({ token });
      // console.log("getUserById", { userId, code, token });
      const fetchingUser = await authenticateUser(userId, token);

      if (!fetchingUser.success)
        throw new Error(
          utilStatusRequest({
            code: fetchingUser.code,
            msgRequest: fetchingUser.message,
          })
        );
      // console.log("authenticateUser", { fetchingUser });

      sessionStorage.setItem(USERTOKEN, token);
      sessionStorage.setItem("userP", fetchingUser.user.id);

      dispatch(getAccount(fetchingUser.user.id, token));

      dispatch(
        userSuccess({
          user: fetchingUser.user,
          success_message: utilStatusRequest({
            code: code ? code : "success",
          }),
        })
      );
    } catch (error) {
      dispatch(userFailure(error.message));
    }
  };

  const loginUser = async (data) => {
    try {
      dispatch(userRequest());

      const fetchingUser = await userLogin(data);

      if (!fetchingUser.success) {
        if (fetchingUser.code) {
          catchErrors({
            info: fetchingUser,
            textDetail: "errorAuthLogin",
            where: "error login user - use authenticate",
          });
        } else {
          catchErrors({
            info: fetchingUser,
            textDetail: "errorLogin",
            where: "error login user - use authenticate",
          });
        }
      }
      // console.log(fetchingUser.token);

      // UP -> user persist
      createCookie("UP", fetchingUser.user.id);
      // TA -> auth token
      createCookie("AT", fetchingUser.token);

      sessionStorage.setItem(USERTOKEN, fetchingUser.token);
      sessionStorage.setItem("userP", fetchingUser.user.id);

      dispatch(getAccount(fetchingUser.user.id, fetchingUser.token));
      // getAccount(fetchingUser.user.id, fetchingUser.token);

      dispatch(
        userSuccess({
          user: fetchingUser.user,
          success_message: utilStatusRequest({
            code: "successLoginuser",
          }),
        })
      );
    } catch (error) {
      console.error("error login user - use authenticate ", error.message);
      dispatch(userFailure(error.message));
    }
  };

  const logOut = () => {
    sessionStorage.removeItem(USERTOKEN);
    sessionStorage.removeItem("userP");
    deleteCookie("AT");
    deleteCookie("UP");
    dispatch(resetAccount());
    dispatch(resetUser());
  };

  return { getUserById, loginUser, logOut };
};
