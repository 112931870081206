import { TYPES } from "redux/types/types";
import { BEARERAUTH } from "utilities/authHeader.util";
import { utilStatusRequest } from "utilities/utilStatusRequest/utilStatusRequest";
import { fetchingAccount } from "views/account/interceptors/getAccount.interceptor";

export const accountRequest = () => ({
  type: TYPES.ACCOUNT_REQUEST,
});

export const accountSuccess = (data) => ({
  type: TYPES.ACCOUNT_SUCCESS,
  payload: data,
});

export const accountFailure = (error) => ({
  type: TYPES.ACCOUNT_FAILURE,
  payload: error,
});

export const resetAccountNotification = () => ({
  type: TYPES.ACCOUNT_RESET_NOTIFICATION,
});

export const resetAccount = () => ({
  type: TYPES.ACCOUNT_RESET,
});

export const getAccount = (userId, tokenUser, code) => async (dispatch) => {
  try {
    const bearer = BEARERAUTH() ? BEARERAUTH() : `Bearer ${tokenUser}`;

    const getingAccount = await fetchingAccount(userId, bearer);

    if (!getingAccount.success)
      throw new Error(
        utilStatusRequest({
          code: getingAccount.code,
          msgRequest: getingAccount.message,
        })
      );

    dispatch(
      accountSuccess({
        account: getingAccount.data,
        success_message: utilStatusRequest({
          code: code ? undefined : "success",
          msgRequest: code ? code : undefined,
        }),
      })
    );
  } catch (error) {
    dispatch(accountFailure(error.message));
  }
};
