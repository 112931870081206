import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";
import { BEARERAUTH } from "utilities/authHeader.util";

export const deleteEvent = async (idProfile, eventId) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: BEARERAUTH(),
    };

    const eventToDelete = await fetchAPI({
      method: "DELETE",
      url: `${URLs.profiles}/${idProfile}/Events/${eventId}`,
      headers,
    });

    return eventToDelete;
  } catch (error) {
    console.error("error delete - deleteEvent interceptor", { error });
    return { success: false, code: "X" };
  }
};
