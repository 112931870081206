import { cameraIcon } from 'assets/images/images';
import { SpinnerLoadInputs } from 'components/SpinnerLoad/SpinnerLoadInputs/SpinnerLoadInputs';
import { useProfileImage } from 'views/account/pages/NewArtists/hooks';
import {
	IconUploadImageStyled,
	ProfilePictureImageStyled,
} from 'views/account/pages/NewArtists/styled-components';
import { useEditArtistOrLocationContext } from '../../contexts/EditArtistOrLocationProvider';

export const EditIconUploadImage = ({ errors, watch, linksProfilePicture }) => {
	const { data, profile } = useEditArtistOrLocationContext();

	const { image, loadingUploadProfileImage } = useProfileImage(
		watch,
		linksProfilePicture
	);

	const whatsDoRenderInImageProfile = (load, img, errorPicture, camImg) => {
		if (load) return <SpinnerLoadInputs />;
		else if (!img)
			return (
				<ProfilePictureImageStyled
					src={data.picture}
					alt={`image ${profile} - ${data.name}`}
				/>
			);
		else if (img && !errorPicture)
			return <ProfilePictureImageStyled src={image} alt="image" />;
		else return camImg;
	};

	return (
		<IconUploadImageStyled>
			{whatsDoRenderInImageProfile(
				loadingUploadProfileImage,
				image,
				errors.picture,
				cameraIcon
			)}
		</IconUploadImageStyled>
	);
};
