import { useEffect, useRef } from "react";

export const useGooglePlaceAutocomplete = ({
  setValue,
  clearErrors,
  data = undefined,
}) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    types: ["locality", "country"],
  };

  const handleChangeValue = (event) => {
    if (!event.target.value) setValue("ubication", "");
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();

      let city = place.address_components.filter(
        (item) => item.types[0] === "locality"
      );

      let country = place.address_components.filter(
        (item) => item.types[0] === "country"
      );

      let state = place.address_components.filter(
        (item) => item.types[0] === "administrative_area_level_1"
      );

      const address = place.formatted_address;

      if (city.length === 0) city = [{ long_name: " " }];
      if (country.length === 0) country = [{ long_name: " " }];
      if (state.length === 0) state = [{ long_name: " " }];

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const ubication = `${city[0].long_name}/${country[0].long_name}/${state[0].long_name}/${address}/${latitude}/${longitude}`;
      setValue("ubication", ubication);
      clearErrors(["ubication"]);
    });
  }, [clearErrors, setValue]);

  useEffect(() => {
    if (data && data.address) {
      inputRef.current.value = data.address;

      const latitude = data.latitude;
      const longitude = data.longitude;
      const ubication = `${data.city}/${data.country}/${data.state}/${data.address}/${latitude}/${longitude}`;
      setValue("ubication", ubication);
      clearErrors(["ubication"]);
    }
  }, [data]);

  return { inputRef, handleChangeValue };
};
