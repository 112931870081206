import { useState } from "react";
import { useEffect } from "react";

export const useScrollToTop = () => {
  const [moveToTop, setMoveToTop] = useState(false);

  useEffect(() => {
    if (moveToTop) window.scrollTo(0, 0);

    return () => {
      setMoveToTop(false);
    };
  }, [moveToTop]);

  const handleMoveToTop = (value) => setMoveToTop(value);

  return { handleMoveToTop };
};
