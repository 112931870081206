import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";

export const getSpecialFilterSearch = async ({ whatIs, dataUrl }) => {
  try {
    console.log({ dataUrl });
    const url = `${URLs.profiles}?Type=${whatIs}${
      dataUrl.isGenre && `&${dataUrl.isGenre}=${dataUrl.genre}`
    }${dataUrl.isCity && `&${dataUrl.isCity}=${dataUrl.city}`}${
      dataUrl.latitude && `&${dataUrl.latitude}=${dataUrl.coordinates.lat}`
    }${
      dataUrl.longitude && `&${dataUrl.longitude}=${dataUrl.coordinates.lng}`
    }`;

    console.log({ url });
    const fetchData = await fetchAPI({
      method: "GET",
      url,
    });

    console.log({ fetchData });
    return fetchData;
  } catch (error) {
    console.error("error get Special Filter Search interceptor", { error });
    return { success: false, code: "X" };
  }
};
