import { debounce } from "debounce";
import { useEffect, useRef } from "react";
import { useSpecialFilterContext } from "../contexts/SpecialFilterProvider";

export const useGoogleAutocomplete = () => {
  const {
    setCitiesToRenderSelected,
    citiesToRenderSelected,
    setLocationCitiesSelected,
  } = useSpecialFilterContext();

  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    types: ["locality", "country"],
  };

  const handleChangeValue = debounce((event) => {
    if (!event.target.value) setCitiesToRenderSelected("");
  }, 700);

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    // console.log({ citiesToRenderSelected });
    if (citiesToRenderSelected) {
      // const dataPlace = citiesToRenderSelected.split("/");
      // const cityExist = dataPlace[0];
      // console.log({ inputRef, cityExist });

      if (citiesToRenderSelected !== "")
        inputRef.current.value = citiesToRenderSelected;
    } else {
      inputRef.current.value = "";
    }

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();

      let city = place.address_components.filter(
        (item) => item.types[0] === "locality"
      );

      let country = place.address_components.filter(
        (item) => item.types[0] === "country"
      );

      let state = place.address_components.filter(
        (item) => item.types[0] === "administrative_area_level_1"
      );

      const address = place.formatted_address;

      if (city.length === 0) city = [{ long_name: " " }];
      if (country.length === 0) country = [{ long_name: " " }];
      if (state.length === 0) state = [{ long_name: " " }];

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const ubication = `${city[0].long_name}/${country[0].long_name}/${state[0].long_name}/${address}/${latitude}/${longitude}`;

      console.log({ ubication });
      setCitiesToRenderSelected(city[0].long_name);
      setLocationCitiesSelected({
        lat: latitude ? latitude : "",
        lng: longitude ? longitude : "",
      });
    });
  }, []);

  return { inputRef, handleChangeValue };
};
