import fetchAPI from 'services/fetchAPI.services';
import { URLs } from 'services/servicesUrls.services';

export const getInfoHome = async ({ type, pageNumber = '', coords }) => {
	try {
		// const url = `${URLs.profiles}?Type=${type}&PageNumber=${pageNumber}`;

		const lat = coords && coords.lat ? coords.lat : '';
		const lng = coords && coords.lng ? coords.lng : '';

		// https://api.showbookie.com/v1/Profiles?Latitude=-38.9417&Longitude=-67.95&Type=1

		const url =
			lat && lng
				? `${URLs.profiles}?Type=${type}${lat && `&Latitude=${lat}`}${
						lng && `&Longitude=${lng}`
				  }`
				: `${URLs.profiles}?Type=${type}`;

		// console.log({url})

		const fetchData = await fetchAPI({
			method: 'GET',
			url: `${url}&pageSize=500`,
		});

		// console.log("getInfoHome", { fetchData });
		return fetchData;
	} catch (error) {
		console.log('error getInfoHome interceptor', { error });
		return { success: false, code: 'X' };
	}
};
