export const Card = ({ image }) => {
  const coverBackground = {
    backgroundImage: "url(" + image + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    height: "180px",
    width: "180px",
    borderRadiusTop: "12px",
    overflow: "hidden",
  };

  return (
    <>
      <div style={coverBackground} />
    </>
  );
};
