import "./SpinnerLoad.styles.css";

export const SpinnerLoad = () => {
  return (
    <div className="lds-spinner ">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
