import { InputStyled } from "components/InputForm/styled-components/Input.styled";
import { eventsGroups } from "utilities/utilAccount/utilNewArtist";
import { DateInput } from "views/account/pages/NewArtists/components/FormNewArtist/DateInput/DateInput";
import { FormGroupStyled } from "views/account/pages/NewArtists/styled-components";

export const CreateEventsInputs = ({
  register,
  dateEvent,
  handleDateEvent,
}) => {
  return (
    <>
      {eventsGroups.inputsEventsGroups.map((field, index) => (
        <FormGroupStyled key={index} className="form-group my-2">
          <InputStyled
            type={field.type}
            className="form-control"
            name={`${index + 1}-${field.name}`}
            placeholder={field.placeholder}
            {...register(field.name)}
          />
        </FormGroupStyled>
      ))}

      <DateInput dateEditEvent={dateEvent} handleDateEvent={handleDateEvent} />
    </>
  );
};
