import styled, { css } from "styled-components";
import { Button, Modal } from "react-bootstrap";
import {
  defaultColors,
  desktopButtonTexts,
  desktopInputTexts,
  disabledButton,
} from "styled-components/App.styled";
import { fadeIn } from "styled-components/animation.styled";

const stylesElementsModal = css`
  background: ${defaultColors.greyBlue};
  border: 1px solid ${defaultColors.navyBlue};
  color: ${defaultColors.white};
`;

const HeaderModalLinksPlatformsStyled = styled(Modal.Header)`
  ${stylesElementsModal}

  button {
    background-color: ${defaultColors.navyBlue} !important;
  }
`;

const BodyModalLinksPlatformsStyled = styled(Modal.Body)`
  ${stylesElementsModal}
`;

const SpanExampleUrlStyled = styled.span`
  background: ${defaultColors.greyBlue};
  border: 2px solid ${defaultColors.navyBlue};
  color: ${defaultColors.white};
  padding: 29px 16px 0 16px;
  margin-right: 5px;
  border-top: none;
  border-left: none;

  font: ${desktopInputTexts};

  ${fadeIn({ time: "1.5s" })}
`;

const FooterModalLinksPlatformsStyled = styled(Modal.Footer)`
  ${stylesElementsModal}
`;

const ButtonModalLinksPlatformsStyled = styled(Button)`
  width: 150px;
  background-color: ${({ from }) =>
    from ? defaultColors.skyBlue : defaultColors.red};

  color: ${({ from }) => (from ? defaultColors.greyBlue : defaultColors.white)};

  border: 1px solid
    ${({ from }) => (from ? defaultColors.skyBlue : defaultColors.red)};

  font: ${desktopButtonTexts};

  &:hover {
    background-color: ${defaultColors.greyBlue};

    color: ${({ from }) => (from ? defaultColors.skyBlue : defaultColors.red)};

    border: 1px solid
      ${({ from }) => (from ? defaultColors.skyBlue : defaultColors.red)};
  }

  &:disabled {
    ${disabledButton};
  }
`;

export {
  HeaderModalLinksPlatformsStyled,
  BodyModalLinksPlatformsStyled,
  SpanExampleUrlStyled,
  FooterModalLinksPlatformsStyled,
  ButtonModalLinksPlatformsStyled,
};
