import styled from "styled-components";
import { fadeIn } from "styled-components/animation.styled";
import {
  defaultColors,
  desktopButtonTexts,
} from "styled-components/App.styled";

const ButtonAddNewEventStyled = styled.div`
  width: 200px;
  height: 45px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 12px;
  background-color: ${defaultColors.greyDisabled};
  color: ${defaultColors.white};
  cursor: pointer;

  font: ${desktopButtonTexts};
  ${fadeIn({ time: "1.5s" })};

  &:hover {
    background-color: ${defaultColors.greyBlue} !important;
    color: ${defaultColors.white} !important;
    outline: 2px solid ${defaultColors.greyDisabled} !important;
  }
`;

export { ButtonAddNewEventStyled };
