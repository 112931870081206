import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";

export const getGenresOrCity = async (type) => {
  try {
    // const url = `${URLs.profiles}?Type=${type}&PageNumber=${pageNumber}`;

    const url = type === "Genres" ? `${URLs.genres}` : null;

    const fetchData = await fetchAPI({
      method: "GET",
      // url: `${url}&pageSize=9`,
      url: `${url}`,
    });

    return fetchData;
  } catch (error) {
    console.error("error get Genres Or City interceptor", { error });
    return { success: false, code: "X" };
  }
};
