import { useEffect } from "react";
import { useForm } from "react-hook-form";

export const useFormAccount = ({ fields, defaultSchema }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm({ defaultValues: defaultSchema });

  useEffect(() => {
    // reset();
    //eslint-disable-next-line
  }, [fields]);

  return {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
  };
};
