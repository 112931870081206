import { useConvertImageInBase64 } from "hooks/useConvertImageInBase64";
import { useEffect, useState } from "react";
import { validationPictureField } from "utilities/utilAccount/utilValidationFields";
import { utilStatusRequest } from "utilities/utilStatusRequest/utilStatusRequest";
import { setImageInProfileSection } from "views/account/interceptors";

let prevPicture;

export const useProfileImage = (watch, linksProfilePicture) => {
  const { image, convertBase64, handleResetImage } = useConvertImageInBase64();

  const [loadingUploadProfileImage, setLoadingUploadProfileImage] =
    useState(false);

  const handleSetLoadingUploadProfileImage = (value) => {
    setLoadingUploadProfileImage(value);
  };

  useEffect(() => {
    const saveDataFile = async (file) => {
      try {
        let formdata = new FormData();
        formdata.append("image", file, file.name);

        const uploadPictureProfile = await setImageInProfileSection(formdata);

        if (!uploadPictureProfile.success || !uploadPictureProfile)
          throw new Error(
            utilStatusRequest({
              code: "X",
              msgRequest: uploadPictureProfile.title,
            })
          );

        localStorage.setItem(
          "linkProfilePicture",
          uploadPictureProfile.data.fileUrl
        );

        linksProfilePicture(uploadPictureProfile.data.fileUrl);
      } catch (error) {
        // console.log("error file", { error });
        console.error(error.message);
      } finally {
        // setLoadingUploadProfileImage(false);
      }
    };

    const subscription = watch(async (value) => {
      if (value?.picture) {
        if (value?.picture?.length > 0) {
          if (value.picture[0]["name"] === prevPicture) return;
          handleSetLoadingUploadProfileImage(true);

          convertBase64(value.picture[0]);
          handleSetLoadingUploadProfileImage(false);
          prevPicture = value.picture[0]["name"];
          if (!(await validationPictureField.picture.validate(value.picture)))
            saveDataFile(value.picture[0]);
        } else if (value.picture.length === 0) {
          handleResetImage();
          prevPicture = undefined;
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return {
    loadingUploadProfileImage,
    image,
  };
};
