import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";
import { BEARERAUTH } from "utilities/authHeader.util";

export const deleteImageGallery = async (idProfile, idImage) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: BEARERAUTH(),
    };

    const deleteImage = await fetchAPI({
      method: "DELETE",
      url: `${URLs.profiles}/${idProfile}/Image/${idImage}`,
      headers,
    });

    return deleteImage;
  } catch (error) {
    console.log("error delete Link Profile Multimedia interceptor", { error });
    return { success: false, code: "X" };
  }
};
