import styled from "styled-components";
import { defaultColors, desktopInputTexts } from "styled-components/App.styled";
// import {
//   defaultColors,
//   desktopInputTexts,
// } from "../../../../styled-components/App.styled";

const InputTextStyled = styled.div`
  color: ${defaultColors.white};
  padding-bottom: 10px;
  font: ${desktopInputTexts};
  
`;

const WrapDashedButtonStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export { InputTextStyled, WrapDashedButtonStyled };
