import { addLinkPlatformIcon } from 'assets/images/imagesPlatforms';
import { SpinnerLoadInputs } from 'components/SpinnerLoad/SpinnerLoadInputs/SpinnerLoadInputs';
import { digitalPlatforms } from 'utilities/iconsPlatform.util';
import { searchingItemExistInArray } from 'utilities/searchingPlatformExist.util';
import { PlatformLinksModal } from 'views/account/components/PlatformLinksModal/PlatformLinksModal';
import {
	PlatformButtonStyled,
	TextDesktopInputs,
} from 'views/account/styled-components';

import { usePlatforms } from '../../pages/NewArtists/hooks';
import {
	AddIconStyled,
	WrapIconsAndAddStyled,
	WrapPlatformButtonsStyled,
	WrapPlatformStyled,
} from '../../pages/NewArtists/styled-components/Platforms.styled';

export const Platforms = ({
	children,
	handlelinks,
	profileLinksSources,
	loadingLinksSources,
}) => {
	const {
		centaresModalShow,
		renderWhatButtonClicked,
		handleClickPlatform,
		setCenteredModalShow,
	} = usePlatforms(handlelinks);

	const iconCheckform = (name, from) => {
		const checkExist = searchingItemExistInArray(profileLinksSources, name);
		if (from === 'platform') {
			if (!checkExist) return digitalPlatforms[name].empty;
			else {
				return checkExist.url === ''
					? digitalPlatforms[name].empty
					: digitalPlatforms[name].inUse;
			}
		} else {
			if (!checkExist) return addLinkPlatformIcon;
			else return checkExist.url === '' ? addLinkPlatformIcon : '';
		}
	};

	return (
		<>
			{children?.map(
				(platform) =>
					platform && (
						<WrapPlatformStyled key={platform.nameSection}>
							<TextDesktopInputs>{platform.nameSection}</TextDesktopInputs>
							<WrapPlatformButtonsStyled>
								{loadingLinksSources ? (
									<div className="m-auto">
										<SpinnerLoadInputs />
									</div>
								) : (
									platform.render.map((item, index) => (
										<WrapIconsAndAddStyled key={item.name}>
											<PlatformButtonStyled
												size={platform.render.length}
												index={index + 1}
												onClick={() => handleClickPlatform(item)}
											>
												{iconCheckform(item.name.toLowerCase(), 'platform')}
											</PlatformButtonStyled>
											<AddIconStyled
												size={platform.render.length}
												index={index + 1}
											>
												{iconCheckform(item.name, 'addIcon')}
											</AddIconStyled>
										</WrapIconsAndAddStyled>
									))
								)}
								<PlatformLinksModal
									show={centaresModalShow}
									onHide={() => setCenteredModalShow(false)}
									item={renderWhatButtonClicked}
									handlelinks={handlelinks}
									profileLinksSources={profileLinksSources}
								/>
							</WrapPlatformButtonsStyled>
						</WrapPlatformStyled>
					)
			)}
		</>
	);
};
