import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { defaultColors, desktopInputTexts } from 'styled-components/App.styled';

import arrowImage from 'assets/images/arrow.svg';
import { assignBackgroundOption } from 'utilities/utilAccount/utilYourAccount';
// import { defaultColors, desktopInputTexts } from "../../../styled-components/App.styled";
// import { assignBackgroundOption } from "../../../utilities/utilAccount/utilYourAccount/utilBackgroundOptions";

const SelectButtonstyled = styled(Form.Select)`
	width: 100%;
	height: 48px;
	padding: 0 15px 0 20px;
	border: 1px solid ${defaultColors.white};
	border-radius: 12px;
	color: ${defaultColors.white};
	background: ${defaultColors.transparentWhite};

	appearance: none;
	-moz-appearance: none;
	background-image: url(${arrowImage});
	background-repeat: no-repeat;
	background-position: calc(100% - 15px) center;

	font: ${desktopInputTexts};

	&:hover {
		border: 2px solid ${defaultColors.white};
	}
`;

const OptionSelectStyled = styled.option`
	color: ${defaultColors.white};
	background: ${({ type }) => assignBackgroundOption(type)} !important;
	display: flex;
	white-space: pre;
	min-height: 20px;
	padding: 0px 2px 1px;
`;

export { SelectButtonstyled, OptionSelectStyled };
