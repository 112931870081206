import { SpinnerLoadInputs } from "components/SpinnerLoad/SpinnerLoadInputs/SpinnerLoadInputs";
import { validationSchemaAccount } from "utilities/utilAccount/utilValidationFields";
import { SpanErrorsStyled } from "views/account/pages/YourAccount/styled-components";

import { useGenresSelectButton } from "../../../hooks";
import {
  OptionSelectStyled,
  SelectButtonstyled,
} from "../../../styled-components";

export const SelectButtonInNewArtist = () => {
  const { register, errors, genres, loadingGenres } = useGenresSelectButton();

  return (
    <>
      {loadingGenres ? (
        <SpinnerLoadInputs />
      ) : (
        <>
          <SelectButtonstyled
            name="genre"
            {...register("genre", validationSchemaAccount["genre"])}
          >
            <OptionSelectStyled value="" type="normal">
              * Género
            </OptionSelectStyled>
            {genres?.map((genre) => (
              <OptionSelectStyled key={genre.id} value={genre.id} type="normal">
                {genre.description}
              </OptionSelectStyled>
            ))}
          </SelectButtonstyled>
          {errors["genre"] && (
            <SpanErrorsStyled>{errors["genre"]["message"]}</SpanErrorsStyled>
          )}
        </>
      )}
    </>
  );
};
