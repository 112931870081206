/**
 * USER_RESET_NOTIFICATION reset state success or failure
 */
export const TYPES = {
  USER_REQUEST: "USER_REQUEST",
  USER_SUCCESS: "USER_SUCCESS",
  USER_FAILURE: "USER_FAILURE",
  USER_RESET_NOTIFICATION: "USER_RESET_NOTIFICATION",
  USER_RESET: "USER_RESET",
  // USER_CLOSE_SESSION: "USER_CLOSE_SESSION",
  ACCOUNT_REQUEST: "ACCOUNT_REQUEST",
  ACCOUNT_SUCCESS: "ACCOUNT_SUCCESS",
  ACCOUNT_FAILURE: "ACCOUNT_FAILURE",
  ACCOUNT_RESET_NOTIFICATION: "ACCOUNT_RESET_NOTIFICATION",
  ACCOUNT_RESET: "USER_RESET",
  GEOLOCATION_SUCCESS: "GEOLOCATION_SUCCESS",
  GEOLOCATION_FAILURE: "GEOLOCATION_FAILURE",
  FEEDBACK_USER_SHOW: "FEEDBACK_USER_SHOW",
  FEEDBACK_USER_HIDE: "FEEDBACK_USER_HIDE",
  FEEDBACK_USER_RESET_NOTIFICATION: "FEEDBACK_USER_RESET_NOTIFICATION",
};
