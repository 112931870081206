import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { es, enUS } from 'date-fns/locale';
import { DatePickerStyled } from 'views/account/styled-components/DateInput.styled';

export const DateInput = ({
	eventNum,
	dateEvent = '',
	handleDateEvent,
	dateEditEvent = undefined,
}) => {
	// console.log({ dateEditEvent });
	// const { dateEvent, handleDateEvent } = useNewArtistContext();
	// console.log({ eventNum, dateEvent, handleDateEvent, dateEditEvent });

	const languages = {
		es: { registerLocale: es, strLocale: 'es' },
		en: { registerLocale: enUS, strLocale: 'enUS' },
	};

	const lenguageNavigator = window.navigator.language.slice(0, 2);

	registerLocale(
		languages[lenguageNavigator].strLocale,
		languages[lenguageNavigator].registerLocale
	);
	// console.log({ dateEvent, eventNum });
	// console.log(dateEvent[eventNum - 1].date);
	return (
		<div className="datePicker">
			<DatePickerStyled
				name={eventNum}
				className="date-picker"
				selected={
					dateEditEvent
						? dateEditEvent
						: dateEvent.length > 0
						? dateEvent[eventNum - 1].date
						: ''
				}
				onChange={(e) => handleDateEvent(e, eventNum)}
				placeholderText="Ingresar fecha del evento"
				dateFormat={
					languages[lenguageNavigator].strLocale === 'es'
						? 'dd-MMM-yyyy h:mm aa'
						: 'MMM-dd-yyyy h:mm aa'
				}
				minDate={new Date()}
				locale={languages[lenguageNavigator].strLocale}
				timeInputLabel={
					languages[lenguageNavigator].strLocale === 'es' ? 'Horario:' : 'Time:'
				}
				showTimeInput
			/>
		</div>
	);
};
