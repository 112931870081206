import { iconMusicNote } from "assets/images/images";
import { WrapCardSectionStyled } from "styled-components/App.styled";
import { useSpecialFilterContext } from "views/Home/contexts/SpecialFilterProvider";
import {
  IconMusicNoteStyled,
  NameGenreText,
} from "views/Home/styled-components/GenresFilterSection.styled";
import { WrapCardStyled } from "views/Home/pages/CardsSectionHome/styled-components/CardsSectionHome.styled";
import { useEffect, useState } from "react";

export const GenresFilterSection = () => {
  const {
    renderGenresOrCitiesData,
    handleGenresSelected,
    genresToRenderSelected,
  } = useSpecialFilterContext();

  // change status card to selected
  const [genreCardSelected, setGenreCardSelected] = useState(undefined);

  const handleClickInCard = (genreCard) => {
    // navigate(`/profile/${clickToggleBtn}/${idCard}`);
    setGenreCardSelected(genreCard.description);
    handleGenresSelected(genreCard);
  };

  useEffect(() => {
    if (genresToRenderSelected.length === 0) setGenreCardSelected(undefined);
    else setGenreCardSelected(genresToRenderSelected[0].description);
  }, [genresToRenderSelected]);

  return (
    <WrapCardSectionStyled className="col col-12 p-0" from="home">
      {renderGenresOrCitiesData.map((genre, index) => (
        <div className="col col-4 px-1" key={index}>
          <WrapCardStyled
            className="wrap-card-styled-in-genres-filter"
            onClick={() => handleClickInCard(genre)}
            from="genresFilter"
            name={genre.description}
            select={genreCardSelected}
          >
            <IconMusicNoteStyled className="icon-music-note-styled">
              {iconMusicNote}
            </IconMusicNoteStyled>
            <NameGenreText className="name-genre-text">
              {genre.description}
            </NameGenreText>
          </WrapCardStyled>
        </div>
      ))}
    </WrapCardSectionStyled>
  );
};
