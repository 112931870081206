import { Form, Image } from 'react-bootstrap';
import styled from 'styled-components';
import {
	defaultColors,
	hoverEffectFieldsForm,
} from 'styled-components/App.styled';
// import { defaultColors } from "../../../../styled-components/App.styled";

const InputCircleImageStyled = styled.div`
	position: relative;
	/* overflow: hidden; */
	height: 208px;
	width: 208px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	${hoverEffectFieldsForm}
`;

const InputImageStyled = styled.input`
	/* display: none; */
	position: absolute;
	margin: 0;
	padding: 0;
	/* font-size: 20px; */
	cursor: pointer;
	// width: 100%;
	max-height: 200px;
	height: 100%;
	// width: 95%;
	// height: 95%;
	opacity: 0;
	filter: alpha(opacity=0);
	z-index: 2;
`;

const IconUploadImageStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const FormGroupStyled = styled(Form.Group)`
	display: flex;
	flex-direction: ${({ from }) => (from ? 'column' : 'row')};
	align-items: ${({ from }) => (from ? 'flex-start' : 'center')};
	color: ${defaultColors.white};
`;

const TextImageUploadStyled = styled.div`
	padding-left: 10px;
`;

const ProfilePictureImageStyled = styled(Image)`
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: ${({ from }) => (from ? '12px' : '50%')};
`;

export {
	InputCircleImageStyled,
	FormGroupStyled,
	TextImageUploadStyled,
	InputImageStyled,
	IconUploadImageStyled,
	ProfilePictureImageStyled,
};
