// import fetchAPI from "../../../services/fetchAPI";
// import { adapterUserData } from "../adapter/adapterUserData";

import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";
import { adapterUserData } from "../adapter/userData.adapter";

/**
 * then leave a single post interceptor
 */

const userLogin = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };

    const fetchingDataUser = await fetchAPI({
      method: "POST",
      url: `${URLs.authenticate}`,
      headers,
      body: JSON.stringify(data),
    });

    return adapterUserData(fetchingDataUser);
  } catch (error) {
    console.error("error interceptor", { error });
    return { success: false, code: "X" };
  }
};

export { userLogin };
