import { TYPES } from "../types/types";

const initialFeedbackState = {
  feedbackSuccess: false,
  //   feedbackError: false,
  feedbackValues: { variant: "", text: "" },
};

const {
  FEEDBACK_USER_SHOW,
  //   FEEDBACK_USER_HIDE,
  FEEDBACK_USER_RESET_NOTIFICATION,
} = TYPES;

export const feedbackReducer = (state = initialFeedbackState, action) => {
  switch (action.type) {
    case FEEDBACK_USER_SHOW:
      // console.log('en reducer', action)
      return {
        feedbackSuccess: true,
        feedbackValues: {
          variant: action.payload.variant,
          text: action.payload.text,
        },
      };

    case FEEDBACK_USER_RESET_NOTIFICATION:
      return {
        ...initialFeedbackState,
      };

    default:
      return state;
  }
};
