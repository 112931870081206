const genresInfo = { name: "Genres", text: "Género" };
const citiesInfo = { name: "Cities", text: "Ciudad" };

const actionsSection = [genresInfo, citiesInfo];

const buttonSection = [
  {
    text: "Buscar",
  },
];

const firstIndexArrayGenres = { id: "all", description: "Todos los géneros" };

export {
  actionsSection,
  buttonSection,
  genresInfo,
  citiesInfo,
  firstIndexArrayGenres,
};
