import { patterns } from "utilities/utilPatterns";
import { msgValidation } from "./utilValidationFields";

const editValidationPictureField = {
  picture: {
    validate: (picture) => {
      if (!picture || picture.length === 0) return undefined;

      const typeImg = picture[0].type.split("/");

      if (picture[0].size > 2000000) return msgValidation.picture.invalidSize;

      if (typeImg[1] !== "png" && typeImg[1] !== "jpg" && typeImg[1] !== "jpeg")
        return msgValidation.picture.invalidType;
    },
  },
  galleryImage1: {
    // required: { value: true, message: msgValidation.required },
    validate: (galleryImage1) => {
      if (!galleryImage1 || galleryImage1.length === 0) return undefined;

      const typeImg = galleryImage1[0].type.split("/");

      if (galleryImage1[0].size > 2000000)
        return msgValidation.picture.invalidSize;

      if (typeImg[1] !== "png" && typeImg[1] !== "jpg" && typeImg[1] !== "jpeg")
        return msgValidation.picture.invalidType;
    },
  },
  galleryImage2: {
    // required: { value: true, message: msgValidation.required },
    validate: (galleryImage2) => {
      if (!galleryImage2 || galleryImage2.length === 0) return undefined;

      const typeImg = galleryImage2[0].type.split("/");

      if (galleryImage2[0].size > 2000000)
        return msgValidation.picture.invalidSize;

      if (typeImg[1] !== "png" && typeImg[1] !== "jpg" && typeImg[1] !== "jpeg")
        return msgValidation.picture.invalidType;
    },
  },
  galleryImage3: {
    // required: { value: true, message: msgValidation.required },
    validate: (galleryImage3) => {
      if (!galleryImage3 || galleryImage3.length === 0) return undefined;

      const typeImg = galleryImage3[0].type.split("/");

      if (galleryImage3[0].size > 2000000)
        return msgValidation.picture.invalidSize;

      if (typeImg[1] !== "png" && typeImg[1] !== "jpg" && typeImg[1] !== "jpeg")
        return msgValidation.picture.invalidType;
    },
  },
  galleryImage4: {
    // required: { value: true, message: msgValidation.required },
    validate: (galleryImage4) => {
      if (!galleryImage4 || galleryImage4.length === 0) return undefined;

      const typeImg = galleryImage4[0].type.split("/");

      if (galleryImage4[0].size > 2000000)
        return msgValidation.picture.invalidSize;

      if (typeImg[1] !== "png" && typeImg[1] !== "jpg" && typeImg[1] !== "jpeg")
        return msgValidation.picture.invalidType;
    },
  },
  galleryImage5: {
    // required: { value: true, message: msgValidation.required },
    validate: (galleryImage5) => {
      if (!galleryImage5 || galleryImage5.length === 0) return undefined;

      const typeImg = galleryImage5[0].type.split("/");

      if (galleryImage5[0].size > 2000000)
        return msgValidation.picture.invalidSize;

      if (typeImg[1] !== "png" && typeImg[1] !== "jpg" && typeImg[1] !== "jpeg")
        return msgValidation.picture.invalidType;
    },
  },
  galleryImage6: {
    // required: { value: true, message: msgValidation.required },
    validate: (galleryImage6) => {
      if (!galleryImage6 || galleryImage6.length === 0) return undefined;

      const typeImg = galleryImage6[0].type.split("/");

      if (galleryImage6[0].size > 2000000)
        return msgValidation.picture.invalidSize;

      if (typeImg[1] !== "png" && typeImg[1] !== "jpg" && typeImg[1] !== "jpeg")
        return msgValidation.picture.invalidType;
    },
  },
};

const editValidationSchemaAccount = {
  email: {
    required: { value: true, message: msgValidation.required },
    pattern: {
      value: patterns.patternEmail,
      message: msgValidation.email.pattern,
    },
  },
  genre: {
    required: { value: true, message: msgValidation.required },
  },
  phone: {
    length: { message: msgValidation.phoneNumber.invalidSize },
  },
  webSite: {
    pattern: {
      value: patterns.patternURL,
      message: msgValidation.patternURL.pattern,
    },
  },
};

export { editValidationPictureField, editValidationSchemaAccount };
