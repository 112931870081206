import { createGlobalStyle, css } from 'styled-components';
import { fadeIn } from './animation.styled';
import { defaultColors, desktopInputTexts } from './App.styled';

const minHeight = '100vh - 112px';

export const GlobalStyle = createGlobalStyle`
    html{
        box-sizing: border-box;

        *, *::before, *:after {
            box-sizing: inherit;
        }
        
        ul, li, h1, h2, h3,p,button {
            margin: 0; padding: 0;
        }
        ul { list-style: none}
        button{background: transparent; border: 0; outline: 0;}

        body{
            font-family: 'Rubik', sans-serif;
            min-height: ${({ windowSize: { height } }) =>
							height > 600 ? '100vh' : css`calc(${minHeight})`};
            background: linear-gradient(#000000, #26272e);
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            -webkit-text-fill-color: ${defaultColors.white};
            transition: background-color 5000s ease-in-out 0s;
            -webkit-tap-highlight-color: transparent; 
            font-family: 'Rubik', sans-serif;
        }

        // input google place autocomplete
        .pac-container {
            background-color: #3a4042;
        }
        

        .pac-item,
        .pac-item-query {
            color: ${defaultColors.white};
        }
        
        .pac-item:hover {
            background: ${defaultColors.navyBlue};
        }

        // field phone - react-phone-number-input

        .PhoneInputCountry{
          padding-top: 15px;
        }

        .PhoneInputCountrySelect {
          border-radius: 0;
          /* padding: 30px 0 2px 3px; */
          outline: 0;
          border-width: 0 0 1px;
          border-color: #ffffff;
          background: #26272E;
          color: #ffffff;
          transition: 0.3s;

          font: ${desktopInputTexts};
        }
        
        .PhoneInputCountryIconImg {
          color: white;
        }

        .PhoneInputCountrySelectArrow {
          color: #ffffff;
          border-bottom: 2px solid #ffffff;
          border-right: 2px solid #ffffff
        }

        .PhoneInputInput {
          border-radius: 0;
          padding: 30px 0 2px 3px;
          outline: 0;
          border-width: 0 0 1px;
          border-color: #ffffff;
          background: rgba(255, 255, 255, 0) !important;
          color: #ffffff;
          transition: 0.3s;

          font: ${desktopInputTexts};
        }

        .PhoneInputInput:hover {
          border-bottom: 2px solid #ffffff;
          cursor: pointer;
        }

        .PhoneInputInput:focus {
          border-radius: 12px;
          outline: 3px solid #26272e;
          padding: 30px 0 10px 10px;
          border-bottom: 2px solid #ffffff;
          color: #ffffff;
        }

        .PhoneInputInput::placeholder {
          color: #ffffff;
        }


}
`;
