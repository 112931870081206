import { InputForm } from "components/InputForm/InputForm";
import { SpinnerLoad } from "components/SpinnerLoad/SpinnerLoad";
import { useEffect, useState } from "react";
import { feedbackSuccess } from "redux/actions/feedbackAction";
import { messagesApp } from "utilities/messagesApp.util";
import {
  imagesGroupForm,
  inputsGroups,
  platforms,
} from "utilities/utilAccount/utilNewArtist";
import {
  firstInputsGroups,
  secondInputsGroups,
} from "utilities/utilAccount/utilNewLocation";
import { ImagesGroupForm } from "views/account/components/ImagesGroupForm/ImagesGroupForm";
import { InputPhoneNumber } from "views/account/components/InputPhoneNumber/InputPhoneNumber";
import { InputPlaceAutocomplete } from "views/account/components/InputPlaceAutocomplete/InputPlaceAutocomplete";
import { Platforms } from "views/account/components/Platforms/Platforms";
import { TextAreaForm } from "views/account/components/TextAreaForm/TextAreaForm";
import { deleteImageGallery } from "views/account/interceptors/deleteImageGallery.interceptor";
import {
  DivFieldsStyled,
  FormAccountStyled,
  TitleStyled,
} from "views/account/styled-components";
import { ButtonAddOrEditEventStyled } from "views/account/styled-components/ButtonAddOrEditEvent.styled";
import { ButtonsSubmitNewArtistForm } from "../../NewArtists/components";
import { EditSelectGenresButton } from "../../NewArtists/components/FormNewArtist/EditSelectGenresButton/EditSelectGenresButton";
import { WrapImagesGroupFormStyled } from "../../NewArtists/styled-components";
import { useEditArtistOrLocationContext } from "../contexts/EditArtistOrLocationProvider";
import { EditOrCreateEvents } from "./EditOrCreateEvents/EditOrCreateEvents";
import { ModalConfirmDeleteAllImageGallery } from "./ModalsEditArtistOrLocation/ModalConfirmDeleteAllImageGallery";
import { ModalConfirmDeleteSelectImagesGallery } from "./ModalsEditArtistOrLocation/ModalConfirmDeleteSelectImagesGallery";
import { EditProfileImage } from "./ProfileImage/EditProfileImage";

export const FormEditArtistOrLocation = () => {
  const {
    handleSubmit,
    register,
    errors,
    validationSchemaAccount: validationSchema,
    onSubmitSave,
    onSubmitPublic,
    loadingSubmit,
    setValue,
    clearErrors,
    setError,
    handlePhone,
    phone,
    watch,
    handleProfileLinksSources: handlelinks,
    handleLinkProfilePicture: linksProfilePicture,
    profileLinksSources,
    profile,
    loadingLinksSources,
    data,
    data: { gallery },
    setRefreshDataDeletedGalleryImage,
    catchErrors,
    idProfile,
    setLoadingSubmit,
    dispatch,
  } = useEditArtistOrLocationContext();

  // console.log("ESTE ES EL PROFILEEEEEE", { profile });

  const [addOrEditEvents, setAddOrEditEvents] = useState(false);
  const [textButtonAddOrEdit, setTextButtonAddOrEdit] = useState(
    messagesApp.addOrEditEvents
  );

  // states modals
  const [
    showModalConfirmDeleteAllImageGallery,
    setShowModalConfirmDeleteAllImageGallery,
  ] = useState(false);

  const [
    showModalConfirmDeleteSelectImageGallery,
    setShowModalConfirmDeleteSelectImageGallery,
  ] = useState(false);

  const [modalConfirmActionText, setModalConfirmActionText] = useState("");

  const handleAddOrEditEvents = () => {
    setAddOrEditEvents(!addOrEditEvents);
  };

  useEffect(() => {
    if (addOrEditEvents)
      setTextButtonAddOrEdit(messagesApp.returnFormEditArtist);
    else setTextButtonAddOrEdit(messagesApp.addOrEditEvents);
  }, [addOrEditEvents]);

  const handleCloseConfirmModal = () => {
    setModalConfirmActionText("");
    setShowModalConfirmDeleteAllImageGallery(false);
  };

  const handleCloseModalDeleteSelectImageGallery = () => {
    setShowModalConfirmDeleteSelectImageGallery(false);
  };

  const handleDeleteSelectImagesInGallery = () => {
    setShowModalConfirmDeleteSelectImageGallery(true);
  };

  const handleDeleteAllImagesInGallery = () => {
    setModalConfirmActionText(messagesApp.sureWantToRemoveAllImagesGallery);
    setShowModalConfirmDeleteAllImageGallery(true);
  };

  const handleConfirmDeleteImagesInGallery = async () => {
    try {
      setLoadingSubmit(true);
      await Promise.all(
        gallery.map(async (imageToDelete) => {
          // delete image
          const deleteImage = await deleteImageGallery(
            idProfile,
            imageToDelete.id
          );

          if (!deleteImage || !deleteImage.success) {
            catchErrors({
              info: deleteImage,
              textDetail: "errorDeleteGalleryImage",
              where:
                "handleConfirmDeleteImagesInGallery - form edit artist or location provider",
            });
          }
          return deleteImage.success;
        })
      );

      dispatch(
        feedbackSuccess({
          variant: "success",
          text: messagesApp.deleteAllGallerySuccessfuly,
        })
      );

      setModalConfirmActionText("");
      setRefreshDataDeletedGalleryImage(true);
    } catch (error) {
      console.error("error", error.message);
    } finally {
      setLoadingSubmit(false);
    }
  };

  return (
    <>
      <FormAccountStyled>
        <DivFieldsStyled className="col col-12 mb-5">
          <EditProfileImage
            register={register}
            errors={errors}
            watch={watch}
            linksProfilePicture={linksProfilePicture}
          />
        </DivFieldsStyled>
        {profile === "artists" && (
          <div
            className="col col-12 d-flex justify-content-end"
            style={{ color: "white" }}
          >
            <ButtonAddOrEditEventStyled
              className="col-sm-6 col-md-4 col-lg-4 p-3"
              onClick={handleAddOrEditEvents}
            >
              {textButtonAddOrEdit}
            </ButtonAddOrEditEventStyled>
          </div>
        )}
        <TitleStyled className="col col-12">
          {profile === "artists" ? "Perfil" : "Locación"}
        </TitleStyled>

        {addOrEditEvents ? (
          <EditOrCreateEvents />
        ) : (
          <>
            <DivFieldsStyled className="col col-12">
              <InputForm
                register={register}
                errors={errors}
                validationSchema={validationSchema}
              >
                {profile === "artists"
                  ? [
                      {
                        type: "text",
                        name: "name",
                        placeholder: "*Nombre del Artista",
                      },
                    ]
                  : firstInputsGroups}
              </InputForm>
            </DivFieldsStyled>
            <DivFieldsStyled className="col col-12">
              <TextAreaForm
                register={register}
                errors={errors}
                validationSchema={validationSchema}
                placeholder={
                  profile === "artists" ? "*Biografía" : "*Descripción "
                }
              />
            </DivFieldsStyled>
            {profile === "artists" && (
              <DivFieldsStyled className="col col-12">
                <EditSelectGenresButton />
              </DivFieldsStyled>
            )}

            {profile === "locations" && (
              <>
                <DivFieldsStyled className="col col-12">
                  <InputForm
                    register={register}
                    errors={errors}
                    validationSchema={validationSchema}
                  >
                    {secondInputsGroups}
                  </InputForm>
                  <InputPlaceAutocomplete
                    register={register}
                    errors={errors}
                    validationSchema={validationSchema}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    data={data}
                  />
                  <InputPhoneNumber
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    handlePhone={handlePhone}
                    phone={phone}
                    fromCall="edit"
                  />
                </DivFieldsStyled>
              </>
            )}

            <DivFieldsStyled className="col col-12">
              <Platforms
                handlelinks={handlelinks}
                profileLinksSources={profileLinksSources}
                loadingLinksSources={loadingLinksSources}
              >
                {profile === "artists" && {
                  render: platforms.digitalPlatforms,
                  nameSection: platforms.section.digitalPlatform,
                }}
                {{
                  render: platforms.socialMedia,
                  nameSection: platforms.section.socialNetworks,
                }}
              </Platforms>
            </DivFieldsStyled>

            {profile === "artists" && (
              <DivFieldsStyled className="col col-12">
                <InputForm
                  register={register}
                  errors={errors}
                  validationSchema={validationSchema}
                >
                  {profile === "artists" ? inputsGroups : secondInputsGroups}
                </InputForm>
                <InputPlaceAutocomplete
                  register={register}
                  errors={errors}
                  validationSchema={validationSchema}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  data={data}
                />
                <InputPhoneNumber
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                  handlePhone={handlePhone}
                  phone={phone}
                />
              </DivFieldsStyled>
            )}

            <DivFieldsStyled className="col col-12">
              {gallery && gallery.length > 0 && (
                <div className="d-flex justify-content-evenly">
                  <div
                    className="col col-4 btn btn-danger mt-3"
                    onClick={handleDeleteAllImagesInGallery}
                  >
                    Eliminar toda la galería
                  </div>
                  <div
                    className="col col-4 btn btn-danger mt-3"
                    onClick={handleDeleteSelectImagesInGallery}
                  >
                    Seleccionar imagenes a eliminar
                  </div>
                </div>
              )}
              <WrapImagesGroupFormStyled>
                {imagesGroupForm.map((item, index) => (
                  <ImagesGroupForm
                    key={item.name}
                    item={item}
                    index={index}
                    register={register}
                    errors={errors}
                    watch={watch}
                    gallery={gallery}
                  />
                ))}
              </WrapImagesGroupFormStyled>
            </DivFieldsStyled>
            {/* <FooterFormTextStyled>
          <SmallTextStyled className="col col-7">
            {utilMessagesForms.formNewArtist}
          </SmallTextStyled>
        </FooterFormTextStyled> */}

            {loadingSubmit && <SpinnerLoad />}
            <div className="d-flex justify-content-between col col-12">
              <ButtonsSubmitNewArtistForm
                text={"Guardar"}
                from="newArtist"
                background="50717B"
                color="FFFFFF"
                click={handleSubmit((data, e) => onSubmitSave(data, e))}
              />

              <ButtonsSubmitNewArtistForm
                text={"Publicar"}
                from="newArtist"
                click={handleSubmit((data, e) => onSubmitPublic(data, e))}
              />
            </div>
          </>
        )}
      </FormAccountStyled>
      <ModalConfirmDeleteAllImageGallery
        show={showModalConfirmDeleteAllImageGallery}
        handleConfirm={handleConfirmDeleteImagesInGallery}
        onHide={() => handleCloseConfirmModal()}
        actionText={modalConfirmActionText}
      />
      <ModalConfirmDeleteSelectImagesGallery
        show={showModalConfirmDeleteSelectImageGallery}
        gallery={gallery}
        onHide={() => handleCloseModalDeleteSelectImageGallery()}
        idProfile={idProfile}
        setRefreshDataDeletedGalleryImage={setRefreshDataDeletedGalleryImage}
        catchErrors={catchErrors}
      />
    </>
  );
};
