import { TYPES } from "../types/types";

const initialUserState = {
  loadingUser: false,
  user: {},
  // account: {},
  error: false,
  success_message: "",
  success_request: false,
  session: true,
};

const {
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAILURE,
  USER_RESET_NOTIFICATION,
  USER_RESET,
  // USER_CLOSE_SESSION,
} = TYPES;

export const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case USER_REQUEST:
      return { ...state, loadingUser: true };

    case USER_SUCCESS:
      return {
        ...initialUserState,
        user: action.payload.user,
        account: action.payload.account,
        success_message: action.payload.success_message,
        success_request: true,
      };

    case USER_FAILURE:
      return {
        ...initialUserState,
        user: state.user,
        account: state.account,
        error: action.payload,
      };

    case USER_RESET_NOTIFICATION:
      return { ...initialUserState, user: state.user, account: state.account };

    case USER_RESET:
      const resetUser = { ...initialUserState, session: false };
      return resetUser;

    // case USER_CLOSE_SESSION:
    //   return { ...initialUserState, session: false };

    default:
      return state;
  }
};
