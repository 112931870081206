import { cameraIcon } from "assets/images/images";
import { SpinnerLoadInputs } from "components/SpinnerLoad/SpinnerLoadInputs/SpinnerLoadInputs";
import { useConvertImageInBase64 } from "hooks/useConvertImageInBase64";
import { useRef, useState } from "react";
import { utilStatusRequest } from "utilities/utilStatusRequest/utilStatusRequest";

import { validationPicture } from "utilities/utilValidationPicture";
import { whatsDoRenderInImageProfile } from "views/account/components/WathsDoRenderInImage";
import { setImageInProfileSection } from "views/account/interceptors";
import {
  InputImageStyled,
  ProfilePictureImageStyled,
} from "views/account/pages/NewArtists/styled-components";
import { SpanErrorsStyled } from "views/account/pages/YourAccount/styled-components";
import {
  CoverFieldStyled,
  WrapImageCoverStyled,
} from "views/account/styled-components/Cover.styled";
import { useEditArtistOrLocationContext } from "../../contexts/EditArtistOrLocationProvider";

export const EditCoverAccount = ({ handleLink }) => {
  const { data, loadingData } = useEditArtistOrLocationContext();

  const ref = useRef(null);

  const { image, convertBase64, handleResetImage } = useConvertImageInBase64();

  const [messageError, setMessageError] = useState("");

  const [loadingUpdateImg, setLoadingUpdateImg] = useState(false);

  const handleSelectImage = async (e) => {
    try {
      setLoadingUpdateImg(true);

      const file = e.currentTarget.files[0];

      const isNotValid = validationPicture(file);

      if (isNotValid) {
        handleResetImage();
        setMessageError(isNotValid);
        throw new Error(isNotValid);
      } else {
        let formdata = new FormData();
        formdata.append("image", file, file.name);

        const uploadPictureProfile = await setImageInProfileSection(formdata);

        if (!uploadPictureProfile.success) {
          let msgError = utilStatusRequest({
            code: "X",
            msgRequest: uploadPictureProfile.title,
          });
          setMessageError(msgError);
          throw new Error(msgError);
        }
        setMessageError("");

        convertBase64(file);

        handleLink(uploadPictureProfile.data.fileUrl);
      }
    } catch (error) {
      console.error("error", error.message);
    } finally {
      setLoadingUpdateImg(false);
    }
  };

  const imageToRender =
    !image && data?.cover ? data?.cover : image ? image : cameraIcon;

  const coverBackground = {
    backgroundImage: "url(" + imageToRender + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    height: "528px",
    width: "100%",
  };

  return (
    <CoverFieldStyled>
      <WrapImageCoverStyled
        className={`col ${image ? "col-12" : "col-5"}`}
        style={coverBackground}
      >
        {loadingUpdateImg && <SpinnerLoadInputs />}
        {!loadingData && !data.cover && <SpinnerLoadInputs />}
        {!image && !data.cover && (
          <ProfilePictureImageStyled
            from="cover"
            // src={data?.cover}
            alt={`Artist image - ${data?.name}`}
          />
        )}

        {/* {!image && !data.cover ? (
          <ProfilePictureImageStyled
            from="cover"
            // src={data?.cover}
            alt={`Artist image - ${data?.name}`}
          />
        ) : (
          whatsDoRenderInImageProfile({
            load: loadingUpdateImg,
            img: image,
            errorPicture: messageError,
            camImg: cameraIcon,
            from: "cover",
            cover: data?.cover ? data?.cover : undefined,
            name: data?.name ? data?.cover : undefined,
          })
        )} */}

        {messageError && <SpanErrorsStyled>{messageError}</SpanErrorsStyled>}
      </WrapImageCoverStyled>

      <InputImageStyled type="file" ref={ref} onChange={handleSelectImage} />
    </CoverFieldStyled>
  );
};
