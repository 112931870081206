import { SpinnerLoad } from "components/SpinnerLoad/SpinnerLoad";
import { useCatchErrors } from "hooks/useCatchErrors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { feedbackSuccess } from "redux/actions/feedbackAction";
import { addHttpsInUrl } from "utilities/addHttpsInUrl.util";
import { messagesApp } from "utilities/messagesApp.util";
import { CreateEventsInputs } from "views/account/components/CreateEventsInputs/CreateEventsInputs";
import { setNewEvent } from "views/account/interceptors/setNewEvent.interceptor";
import { ButtonsSubmitNewArtistForm } from "views/account/pages/NewArtists/components";
import { SpanErrorsStyled } from "views/account/pages/YourAccount/styled-components";
import { patterns } from "utilities/utilPatterns";

export const CreateEvent = ({ parameter }) => {
  const { catchErrors } = useCatchErrors();
  const dispatch = useDispatch();

  const { register, handleSubmit, reset } = useForm();

  const [loadingCreteEvent, setLoadingCreteEvent] = useState(false);

  const [dateEvent, setDateEvent] = useState("");

  const [disabledButtonEdit, setDisabledButtonEdit] = useState(false);

  const [messageErrorInputsEmpty, setMessageErrorInputsEmpty] = useState(false);

  const handleDateEvent = (date) => {
    setDateEvent(date);
  };

  const onSubmitCreteEvent = async (data) => {
    try {
      if (
        data.eventDescription === "" ||
        data.eventUrl === "" ||
        dateEvent === ""
      ) {
        setMessageErrorInputsEmpty(messagesApp.completeAllFields);
        throw new Error(messagesApp.completeAllFields);
      }

      if (!patterns.patternHTTPS.test(data.eventUrl)) {
        setMessageErrorInputsEmpty(messagesApp.validateURLMessage);
        throw new Error(messagesApp.validateURLMessage);
      }

      setLoadingCreteEvent(true);
      setDisabledButtonEdit(true);

      const objEventToEdit = {
        description: data.eventDescription,
        url: addHttpsInUrl(data.eventUrl),
        date: dateEvent ? new Date(dateEvent).toISOString() : dateEvent,
      };

      const profileId = parameter;
      const addEvent = await setNewEvent(profileId, "Events", objEventToEdit);

      if (!addEvent || !addEvent.success) {
        catchErrors({
          info: addEvent,
          textDetail: "errorCreateEvent",
          where: "on submit crete event - CreateEvent",
        });
      }

      dispatch(
        feedbackSuccess({
          variant: "success",
          text: messagesApp.successCreateEvent,
        })
      );

      reset();
      setDateEvent("");
    } catch (error) {
      console.error("error al guardar nuevo artista", error.message);
      dispatch(
        feedbackSuccess({
          variant: "danger",
          text: error.message,
        })
      );
    } finally {
      setLoadingCreteEvent(false);
      setDisabledButtonEdit(false);
      setTimeout(() => {
        setMessageErrorInputsEmpty("");
      }, 5000);
    }
  };

  return (
    <div className="my-4">
      <CreateEventsInputs
        register={register}
        dateEvent={dateEvent}
        handleDateEvent={handleDateEvent}
      />

      {loadingCreteEvent && <SpinnerLoad />}
      <ButtonsSubmitNewArtistForm
        text={messagesApp.saveText}
        from="createEvents"
        click={handleSubmit((data) => onSubmitCreteEvent(data))}
        disabled={disabledButtonEdit}
      />
      {messageErrorInputsEmpty && (
        <SpanErrorsStyled>{messageErrorInputsEmpty}</SpanErrorsStyled>
      )}
    </div>
  );
};
