import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";
import { BEARERAUTH } from "utilities/authHeader.util";

export const postDataForm = async (data, id, restUrl) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: BEARERAUTH(),
    };

    const fetchingData = await fetchAPI({
      method: "POST",
      url: `${URLs.accounts}/${id}/${restUrl}`,
      headers,
      body: JSON.stringify(data),
    });

    return fetchingData;
  } catch (error) {
    console.log("error post interceptor", { error });
    return { success: false, code: "X" };
  }
};
