import styled from "styled-components";
import {
  defaultColors,
  hoverEffectFieldsForm,
} from "styled-components/App.styled";
// import {
//   defaultColors,
//   hoverEffectFieldsForm,
// } from "../../../styled-components/App.styled";

const marginAccordingToItem = {
  first: "0 20px 0 0",
  last: "0",
};

const defineMargin = (size, index) =>
  size === index ? marginAccordingToItem.last : marginAccordingToItem.first;

const PlatformButtonStyled = styled.div`
  width: 95px;
  height: 96px;
  margin: ${({ size, index }) => defineMargin(size, index)};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${defaultColors.somethingTransparentWhite};
  border: 1px solid ${defaultColors.white};

  &:hover {
    ${hoverEffectFieldsForm}
  }
`;

export { PlatformButtonStyled };
