import { TYPES } from "redux/types/types";

export const userRequest = () => ({
  type: TYPES.USER_REQUEST,
});

export const userSuccess = (data) => ({
  type: TYPES.USER_SUCCESS,
  payload: data,
});

export const userFailure = (error) => ({
  type: TYPES.USER_FAILURE,
  payload: error,
});

export const resetUserNotification = () => ({
  type: TYPES.USER_RESET_NOTIFICATION,
});

export const resetUser = () => ({
  type: TYPES.USER_RESET,
});

// export const userCloseSession = () => ({
//   type: TYPES.USER_CLOSE_SESSION,
// });
