// import fetchAPI from "../../../services/fetchAPI";
// import { adapterUserData } from "../adapter/adapterUserData";

import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";

const userSignUp = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };

    const dataToSend = { ...data, createAccount: true };

    const sendDataUserSignUp = await fetchAPI({
      method: "POST",
      url: `${URLs.users}`,
      headers,
      body: JSON.stringify(dataToSend),
    });

    return sendDataUserSignUp;
  } catch (error) {
    console.log("error signUp interceptor", { error });
    return { success: false, error };
  }
};

export { userSignUp };
