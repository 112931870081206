import styled from 'styled-components';

const BodyStyled = styled.div`
	// mobile first
	max-width: 890px;
	overscroll-behavior: none;
	width: 100%;
	margin: 0 auto;
`;

export { BodyStyled };
