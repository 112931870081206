import styled from "styled-components";
import { fadeIn } from "styled-components/animation.styled";
import {
  defaultColors,
  desktopButtonTexts,
} from "styled-components/App.styled";

const ButtonsSubmitNewArtistFormStyled = styled.button`
  width: ${({ from }) => (from === "editEvents" ? " 50% " : "318px")};
  height: 56px;
  border-radius: 12px;
  font: ${desktopButtonTexts};
  background-color: ${({ background }) => `#${background}`};
  color: ${({ color }) => (color ? `#${color}` : defaultColors.darkBlue)};
  ${fadeIn({ time: "1.5s" })};

  &:hover {
    background-color: ${defaultColors.greyBlue} !important;
    color: ${defaultColors.white} !important;
    outline: 2px solid ${({ background }) => `#${background}`} !important;
  }
`;

export { ButtonsSubmitNewArtistFormStyled };
