import {
	facebookIcon,
	instagramIcon,
	tiktokIcon,
	twitterIcon,
} from '../../../assets/images/imagesPlatforms';

const firstInputsGroups = [
	{
		type: 'text',
		name: 'name',
		placeholder: '*Nombre de la locación',
	},
	{
		type: 'text',
		name: 'capacity',
		placeholder: '*Capacidad (aforo)',
	},
];

const secondInputsGroups = [
	{
		type: 'email',
		name: 'email',
		placeholder: '*Email',
	},
	{
		type: 'text',
		name: 'webSite',
		placeholder: 'Sitio web',
	},
];

const platforms = {
	section: {
		socialNetworks: 'Redes sociales',
	},

	socialMedia: [
		{ name: 'instagram', icon: instagramIcon, source: 'Instagram', typeId: 2 },
		{ name: 'facebook', icon: facebookIcon, source: 'Facebook', typeId: 2 },
		{ name: 'twitter', icon: twitterIcon, source: 'twitter', typeId: 2 },
		{ name: 'tiktok', icon: tiktokIcon, source: 'Tiktok', typeId: 2 },
	],
};

const imagesGroupForm = [
	{
		name: 'galleryImage1',
	},
	{
		name: 'galleryImage2',
	},
	{
		name: 'galleryImage3',
	},
	{
		name: 'galleryImage4',
	},
	{
		name: 'galleryImage5',
	},
	{
		name: 'galleryImage6',
	},
];

export { platforms, secondInputsGroups, imagesGroupForm, firstInputsGroups };
