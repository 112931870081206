import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import {
	defaultColors,
	desktopInputTexts,
	hoverEffectFieldsForm,
} from 'styled-components/App.styled';
// import {
//   defaultColors,
//   desktopInputTexts,
//   hoverEffectFieldsForm,
// } from "../../../../styled-components/App.styled";

const TextAreaFieldStyled = styled(Form.Control)`
	width: 100%;
	min-height: ${({ from }) => (from ? '200px' : '250px')};
	background: ${({ from }) =>
		from
			? defaultColors.transparentWhite
			: defaultColors.somethingTransparentWhite};
	border: ${({ from }) =>
		from ? 'none' : ` 1px solid ${defaultColors.white}`};
	border-radius: 8px;
	color: ${defaultColors.white};
	/* padding: 15px 10px 0 15px; */

	padding: 15px 5px 0 20px;

	font: ${desktopInputTexts};

	&:focus {
		outline: ${({ from }) =>
			from ? 'none' : ` 2px solid ${defaultColors.darkBlue}`};
		border-bottom: ${({ from }) =>
			from ? 'none' : ` 2px solid ${defaultColors.white}`};
	}

	/* &:hover {
    ${hoverEffectFieldsForm}
    outline: ${({ from }) =>
		from ? 'none' : ` 2px solid ${defaultColors.darkBlue}`};
  } */
`;

export { TextAreaFieldStyled };
