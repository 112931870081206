/**
 * render login input fields
 */
const loginFields = [
	{
		type: 'email',
		name: 'email',
		placeholder: 'Email',
	},
	{
		type: 'password',
		name: 'password',
		placeholder: 'Contraseña',
	},
];

/**
 * render register input fields
 */
const registerFields = [
	{
		type: 'text',
		name: 'firstName',
		placeholder: 'Nombre',
		properties: { width: '100%', height: '56px' },
	},
	{
		type: 'text',
		name: 'lastName',
		placeholder: 'Apellido',
		properties: { width: '100%', height: '56px' },
	},
	...loginFields,
	{
		type: 'password',
		name: 'passwordConfirmation',
		placeholder: 'Confirmar contraseña',
	},
];

export { loginFields, registerFields };
