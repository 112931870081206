// "email": "test@test.com",
// "webSite": "string",
// "city": "string",
// "phone": "+541165544554",

import {
  amazonMusic,
  appleMusicIcon,
  deezerIcon,
  facebookIcon,
  instagramIcon,
  spotifyIcon,
  tidalIcon,
  tiktokIcon,
  twitterIcon,
  youtubeIcon,
} from "../../../assets/images/imagesPlatforms";

const inputsGroups = [
  {
    type: "email",
    name: "email",
    placeholder: "*Email",
  },
  {
    type: "text",
    name: "webSite",
    placeholder: "Sitio web",
  },
];

const eventsGroups = {
  nameSection: "Eventos del artista",
  inputsEventsGroups: [
    {
      type: "text",
      name: "eventDescription",
      placeholder: "Descripción",
    },
    {
      type: "url",
      name: "eventUrl",
      placeholder: "Agrega algún link del evento",
    },
    // {
    //   type: "date",
    //   name: "eventDate",
    //   placeholder: "Fecha",
    // },
  ],
};

// cuando tenga liink -> añadir icono en color
const platforms = {
  section: {
    digitalPlatform: "Plataformas digitales",
    socialNetworks: "Redes sociales",
  },
  digitalPlatforms: [
    { name: "spotify", icon: spotifyIcon, source: "Spotify", typeId: 1 },
    { name: "youtube", icon: youtubeIcon, source: "Youtube", typeId: 1 },
    {
      name: "appleMusic",
      icon: appleMusicIcon,
      source: "AppleMusic",
      typeId: 1,
    },
    { name: "tidal", icon: tidalIcon, source: "Tidal", typeId: 1 },
    { name: "deezer", icon: deezerIcon, source: "Deezer", typeId: 1 },
    {
      name: "amazonMusic",
      icon: amazonMusic,
      source: "AmazonMusic",
      typeId: 1,
    },
  ],

  socialMedia: [
    { name: "instagram", icon: instagramIcon, source: "Instagram", typeId: 2 },
    { name: "facebook", icon: facebookIcon, source: "Facebook", typeId: 2 },
    { name: "twitter", icon: twitterIcon, source: "twitter", typeId: 2 },
    { name: "tiktok", icon: tiktokIcon, source: "Tiktok", typeId: 2 },
  ],
};

const imagesGroupForm = [
  {
    name: "galleryImage1",
  },
  {
    name: "galleryImage2",
  },
  {
    name: "galleryImage3",
  },
  {
    name: "galleryImage4",
  },
  {
    name: "galleryImage5",
  },
  {
    name: "galleryImage6",
  },
];

export { platforms, inputsGroups, imagesGroupForm, eventsGroups };
