import { createContext, useContext } from "react";
import { debounce } from "debounce";
import { useState } from "react";
import { useEffect } from "react";
import { utilStatusRequest } from "utilities/utilStatusRequest/utilStatusRequest";
import { useNavigateInHome } from "../hooks/useNavigateInHome";

import { filterTo } from "utilities/filterTo.util";
import { messagesApp } from "utilities/messagesApp.util";

import { useSearchParamsBy } from "hooks/useSearchParamsBy";
import { getGenresOrCity } from "../interceptors/getGenresOrCity.interceptor";
import {
  firstIndexArrayGenres,
  genresInfo,
} from "utilities/utilHome/utilSpecialFilter/specialFilterArtistForm.util";
import { getSpecialFilterSearch } from "../interceptors/getSpecialFilterSearch.interceptor";
import { useScrollToTop } from "hooks/useScrollToTop";

const SpecialFilterContext = createContext();

const SpecialFilterProvider = ({ children }) => {
  const { handleMoveToTop } = useScrollToTop();

  // values: artists or locations
  const show = useSearchParamsBy("show");
  const { clickToggleBtn, handleToggleBtnClick } = useNavigateInHome(
    show.parameter
  );

  // show type special filter - init artist (genres or cities)
  const [showType, setShowType] = useState(show.parameter);

  // filter result
  // const [information, setInformation] = useState([]);
  const [informationToRender, setInformationToRender] = useState([]);
  const [messageinformationToRender, setMessageInformationToRender] =
    useState("");

  const [messageEmptyFilter, setMessageEmptyFilter] = useState(null);

  const [genresOrCitiesData, setGenresOrCitiesData] = useState([]);
  const [renderGenresOrCitiesData, setRenderGenresOrCitiesData] = useState([]);

  // const [genresSelected, setGenresSelected] = useState([]);
  const [genresToRenderSelected, setGenresToRenderSelected] = useState([]);
  // const [citiesSelected, setCitiesSelected] = useState([]);
  const [citiesToRenderSelected, setCitiesToRenderSelected] = useState("");
  const [locationCitiesSelected, setLocationCitiesSelected] = useState({
    lat: "",
    lng: "",
  });

  // const [messageError, setMessageError] = useState(null);

  const [allLocations, setAllLocations] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    if (informationToRender.length > 0) {
      setLoadingData(false);
    }
  }, [informationToRender]);

  useEffect(() => {
    const fetchGenresOrCity = async (getfilterTypeParam) => {
      try {
        setLoadingData(true);
        const fetch = await getGenresOrCity(getfilterTypeParam);

        if (!fetch || !fetch.success) {
          fetch?.validationMessages?.map((messageError) =>
            console.error(
              utilStatusRequest({
                code: messageError.code,
                msgRequest: messageError.message,
              })
            )
          );

          throw new Error(
            utilStatusRequest({
              code: "X",
              msgRequest: messagesApp.catchFilterGenreOrCities,
            })
          );
        }

        const arrayGenres = [firstIndexArrayGenres, ...fetch.data];
        setGenresOrCitiesData(arrayGenres);
        setRenderGenresOrCitiesData(arrayGenres);
      } catch (error) {
        console.error("error", error.message);
      } finally {
        setLoadingData(false);
      }
    };

    if (clickToggleBtn === "artists" && showType === genresInfo.name)
      fetchGenresOrCity(showType);
  }, [showType, clickToggleBtn]);

  const handleType = (value) => setShowType(value);

  const handleGenresSelected = (value) => {
    const checkExist = genresToRenderSelected.findIndex(
      (item) => item.id === value.id
    );

    if (checkExist === -1) {
      setGenresToRenderSelected([value]);
    } else {
      setGenresToRenderSelected([]);
    }
  };

  const handleLocationsSearch = debounce(async (event) => {
    try {
      // setLoadingData(true);
      setMessageEmptyFilter(null);
      if (!event.target.value) {
        setInformationToRender(allLocations);
      } else {
        const filter = filterTo(event.target.value, allLocations, "name");

        if (filter.length === 0)
          throw new Error(messagesApp.catchspecialFilter);

        setInformationToRender(filter);
      }
    } catch (error) {
      console.error("error", error.message);
      setMessageEmptyFilter(messagesApp.catchspecialFilter);
      setTimeout(() => {
        setMessageEmptyFilter(null);
      }, 2500);
    } finally {
      // setLoadingData(false);
    }
  }, 700);

  const resetGenres = () => {
    // setGenresSelected([]);
    setGenresToRenderSelected([]);
  };
  const resetCities = () => {
    // setCitiesSelected([]);
    setCitiesToRenderSelected("");
    setLocationCitiesSelected({ lat: "", lng: "" });
  };

  const whatIs = {
    artists: 1,
    locations: 2,
  };

  const handleGenreFilter = debounce(async (e) => {
    try {
      setLoadingData(true);
      setMessageEmptyFilter(null);
      if (!e.target.value) {
        setRenderGenresOrCitiesData(genresOrCitiesData);
      } else {
        const filter = filterTo(
          e.target.value,
          genresOrCitiesData,
          "description"
        );

        if (filter.length === 0)
          throw new Error(messagesApp.catchspecialFilter);

        setRenderGenresOrCitiesData(filter);
      }
    } catch (error) {
      console.error("error", error.message);
      setMessageEmptyFilter(messagesApp.catchspecialFilter);
    } finally {
      setLoadingData(false);
    }
  }, 700);

  const handleClickSearchButton = async () => {
    try {
      let objQuery = {
        type: whatIs[show.parameter],
        isGenre: "",
        isCity: "",
        latitude: "",
        longitude: "",
      };

      if (whatIs[show.parameter] === 1) {
        // artist
        if (genresToRenderSelected.length > 0)
          objQuery = {
            ...objQuery,
            isGenre: "Genre",
            genre: genresToRenderSelected[0].id,
          };

        if (citiesToRenderSelected !== "") {
          objQuery = {
            ...objQuery,
            isCity: "City",
            city: citiesToRenderSelected,
          };
        }

        if (
          locationCitiesSelected.lat !== "" ||
          locationCitiesSelected.lng !== ""
        ) {
          objQuery = {
            ...objQuery,
            latitude: locationCitiesSelected.lat ? "Latitude" : "",
            longitude: locationCitiesSelected.lng ? "Longitude" : "",
            coordinates: { locationCitiesSelected },
          };
        }

        const filter = await getSpecialFilterSearch({
          whatIs: whatIs[show.parameter],
          dataUrl: objQuery,
        });

        if (!filter || !filter.success) {
          filter?.validationMessages?.map((messageError) =>
            console.error(
              utilStatusRequest({
                code: messageError.code,
                msgRequest: messageError.message,
              })
            )
          );

          throw new Error(
            utilStatusRequest({
              code: "X",
              msgRequest: messagesApp.catchSearchGenreOrCities,
            })
          );
        }

        if (filter.data.data.length === 0) {
          setMessageInformationToRender(messagesApp.emptyFilter);
          setTimeout(() => {
            setMessageInformationToRender("");
          }, 2500);
        }
        handleMoveToTop(true);
        setInformationToRender(filter.data.data);
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  return (
    <SpecialFilterContext.Provider
      value={{
        clickToggleBtn,
        handleToggleBtnClick,
        informationToRender,
        loadingData,
        handleGenreFilter,
        messageEmptyFilter,
        showType,
        handleType,
        setRenderGenresOrCitiesData,
        genresOrCitiesData,
        renderGenresOrCitiesData,
        handleClickSearchButton,
        handleGenresSelected,
        genresToRenderSelected,
        resetGenres,
        resetCities,
        messageinformationToRender,
        setInformationToRender,
        setCitiesToRenderSelected,
        citiesToRenderSelected,
        setLocationCitiesSelected,
        handleLocationsSearch,
        whatIs,
        setMessageInformationToRender,
        allLocations,
        setAllLocations,
        setMessageEmptyFilter,
      }}
    >
      {children}
    </SpecialFilterContext.Provider>
  );
};

const useSpecialFilterContext = () => {
  const context = useContext(SpecialFilterContext);
  if (context === undefined)
    throw new Error(
      "Special Filter  context must be used within a Special Filter provider"
    );

  return context;
};

export { useSpecialFilterContext, SpecialFilterProvider };
