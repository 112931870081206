import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";
import { BEARERAUTH } from "utilities/authHeader.util";

export const setNewEvent = async (profileId, restUrl, objEvents) => {
  try {
    // console.log({ profileId, restUrl, objEvents });
    // console.log(BEARERAUTH());
    const headers = {
      "Content-Type": "application/json",
      Authorization: BEARERAUTH(),
    };

    const fetchingData = await fetchAPI({
      method: "POST",
      url: `${URLs.profiles}/${profileId}/${restUrl}`,
      headers,
      body: JSON.stringify(objEvents),
    });
    // console.log({ fetchingData });
    return fetchingData;
  } catch (error) {
    console.log("error post - set new event interceptor", { error });
    return { success: false, code: "X" };
  }
};
