import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";
import { BEARERAUTH } from "utilities/authHeader.util";

export const patchLinkProfile = async (data, idProfile, restUrl) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: BEARERAUTH(),
    };
    // console.log("patchLinkProfile -->>", { data });

    const sendData = await fetchAPI({
      method: "POST",
      url: `${URLs.profiles}/${idProfile}/${restUrl}`,
      headers,
      body: JSON.stringify(data),
    });
    // console.log({ sendData });
    return sendData;
  } catch (error) {
    console.log("error patch Link Profile interceptor", { error });
    return { success: false, code: "X" };
  }
};
