import { messagesApp } from "utilities/messagesApp.util";

const { useEffect, useState } = require("react");

export const useGeoLocation = () => {
  const [location, setLocation] = useState({
    success: "",
    coords: { lat: "", lng: "" },
  });

  const onSuccess = (location) => {
    setLocation({
      success: "success",
      coords: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });
  };

  const onError = (error) => {
    setLocation({
      success: "error",
      error: {
        code: error.code,
        message: error.message,
      },
    });
  };

  useEffect(() => {
    if (!("geolocation" in window.navigator)) {
      onError({
        code: 0,
        message: messagesApp.geoLocationNotSupport,
      });
    }

    window.navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);

  return location;
};
