import fetchAPI from 'services/fetchAPI.services';
import { BEARERAUTH } from 'utilities/authHeader.util';

export const postPayments = async (data) => {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: BEARERAUTH(),
		};

		const setPayment = await fetchAPI({
			url: 'https://api.showbookie.com/v1/Payments',
			method: 'POST',
			headers,
			body: JSON.stringify(data),
		});

		return setPayment;
	} catch (error) {
		console.error('error post interceptor postPayments', { error });
		return { success: false, code: 'X' };
	}
};
