export const adapterUserData = (data) => {
  if (data.success)
    return {
      success: data.success,
      account: data.data.account,
      token: data.data.token.token,
      user: data.data.user,
    };
  else
    return {
      success: false,
      code: data.validationMessages[0].code,
      message: data.validationMessages[0].message,
    };
};

export const adapterPersistUserData = (data) => {
  if (data.success)
    return {
      success: data.success,
      user: data.data,
    };
  else
    return {
      success: false,
      code: data.validationMessages[0].code,
      message: data.validationMessages[0].message,
    };
};
