import { editValidationPictureField } from "utilities/utilAccount/editValidationFields.utils";
import {
  InputCircleImageStyled,
  InputImageStyled,
} from "views/account/pages/NewArtists/styled-components";
import {
  FormGroupStyled,
  SpanErrorsStyled,
} from "views/Auth/styled-components/InputAuthForm.styled";
import { useEditArtistOrLocationContext } from "../../contexts/EditArtistOrLocationProvider";
import { EditIconUploadImage } from "../EditIconUploadImage/EditIconUploadImage";

export const EditProfileImage = ({
  register,
  errors,
  watch,
  linksProfilePicture,
}) => {
  return (
    <FormGroupStyled from="form" controlId="formFile">
      <InputCircleImageStyled className="col col-4">
        <EditIconUploadImage
          errors={errors}
          watch={watch}
          linksProfilePicture={linksProfilePicture}
        />
        <InputImageStyled
          type="file"
          {...register("picture", editValidationPictureField["picture"])}
        />
      </InputCircleImageStyled>
      {errors.picture && (
        <SpanErrorsStyled className="ps-3">
          {errors["picture"]["message"]}
        </SpanErrorsStyled>
      )}
    </FormGroupStyled>
  );
};
