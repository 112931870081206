import { Image } from 'react-bootstrap';
import styled from 'styled-components';
import { fadeIn } from 'styled-components/animation.styled';
import {
	defaultColors,
	desktopButtonTexts,
} from 'styled-components/App.styled';

const cardGenreSelected = {
	select: defaultColors.navyBlue,
	noSelect: defaultColors.darkBlue,
};

const WrapCardStyled = styled.div`
	width: ${({ from }) => (from === 'genresFilter' ? '100%' : '202px')};
	height: ${({ from }) => (from === 'genresFilter' ? '96px' : '202px')};
	background-color: ${({ from, name, select }) =>
		from === 'genresFilter'
			? name === select
				? cardGenreSelected.select
				: cardGenreSelected.noSelect
			: defaultColors.transparentWhite};

	border-radius: 8px;

	margin: ${({ from }) =>
		from === 'genresFilter' ? '5px 10px 5px 0' : '5px 0'};
	cursor: pointer;
	position: relative;
	display: flex;

	align-items: center;
	${({ from }) => from === 'genresFilter' && 'justify-content: space-between '};

	flex-direction: column;
`;

const CheckImageStyled = styled.div`
	position: absolute;
	bottom: 83%;
	left: 83%;
	z-index: 2;

	${fadeIn({ time: '3s' })}
`;

const QrImageStyled = styled.div`
	position: absolute;
	bottom: 80%;
	right: 81%;
	z-index: 2;

	${fadeIn({ time: '3s' })}
`;

const CardNameStyled = styled.div`
	width: 100%;
	height: 24px;
	font-weight: 300;
	font-size: 18px !important;
	line-height: 17px;
	position: relative;
	bottom: 40%;
	left: 5px;
	z-index: 2;
	color: ${defaultColors.white};
	font: ${desktopButtonTexts};

	${fadeIn({ time: '3s' })};
`;

const GenreNameStyled = styled.div`
	width: 100%;
	height: 24px;
	font-weight: 300;
	font-size: 20px;
	line-height: 17px;
	position: relative;
	bottom: 35%;
	left: 5px;
	z-index: 2;
	color: ${defaultColors.white};

	font: ${desktopButtonTexts};
	${fadeIn({ time: '3s' })};
`;

const ImageCardStyled = styled(Image)`
	width: 100%;
`;

export {
	WrapCardStyled,
	CheckImageStyled,
	CardNameStyled,
	GenreNameStyled,
	ImageCardStyled,
	QrImageStyled,
};
