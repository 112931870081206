import { validationPictureField } from "utilities/utilAccount/utilValidationFields";
import { SpanErrorsStyled } from "views/Auth/styled-components/InputAuthForm.styled";

import {
  FormGroupStyled,
  InputCircleImageStyled,
  InputImageStyled,
} from "../../pages/NewArtists/styled-components";
import { IconUploadImage } from "./IconUploadImage";

export const ProfileImage = ({ register, errors, watch, linksProfilePicture }) => {
  return (
    <FormGroupStyled from="form" controlId="formFile">
      <InputCircleImageStyled className="col col-4">
        <IconUploadImage errors={errors} watch={watch} linksProfilePicture={linksProfilePicture} />

        <InputImageStyled
          type="file"
          {...register("picture", validationPictureField["picture"])}
        />
      </InputCircleImageStyled>
      {errors.picture && (
        <SpanErrorsStyled className="ps-3">
          {errors["picture"]["message"]}
        </SpanErrorsStyled>
      )}
    </FormGroupStyled>
  );
};
