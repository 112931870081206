import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { accountReducer } from "./accountReducer";
import { geolocationReducer } from "./geolocationReducer";
import { feedbackReducer } from "./feedbackReducer";

const rootReducer = combineReducers({
  userReducer,
  accountReducer,
  geolocationReducer,
  feedbackReducer,
});

export default rootReducer;
