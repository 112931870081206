import styled from 'styled-components';
import { fadeIn } from 'styled-components/animation.styled';
import {
	defaultColors,
	desktopInputTexts,
	tabletSize,
} from 'styled-components/App.styled';
// import { fadeIn } from "../../../styled-components/animation.styled";
// import {
//   defaultColors,
//   desktopInputTexts,
// } from "../../../styled-components/App.styled";

const InputStyled = styled.input`
	/* width: ${({ properties }) => (properties ? properties.width : '430px')}; */
	width: 100%;
	height: ${({ properties }) => (properties ? properties.height : '56px')};
	margin: 10px 0 5px 0;
	font: ${desktopInputTexts};
	/* backdrop-filter: blur(8px); */
	border-radius: 12px;
	padding: 0 20px;
	border: 1px solid
		${({ showErrors }) =>
			showErrors?.errors ? defaultColors.red : defaultColors.white};
	/* background: ${defaultColors.somethingTransparentWhite} !important; */
	background: ${({ showErrors }) =>
		showErrors?.errors
			? defaultColors.transparentRed
			: defaultColors.somethingTransparentWhite} !important;
	color: ${defaultColors.white};

	${fadeIn({ time: '1.5s' })}

	${tabletSize} {
		width: ${({ properties }) => (properties ? properties.width : '100%')};
	}

	&:focus {
		outline: 2px solid ${defaultColors.golden};
		color: ${defaultColors.white};
	}

	&::placeholder {
		color: ${defaultColors.white};
	}
`;

const SpanErrorsStyled = styled.span`
	color: ${defaultColors.red};

	${fadeIn()}
`;

const FormGroupStyled = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	${tabletSize} {
		width: ${({ properties }) => (properties ? properties.width : '100%')};
	}
`;

export { InputStyled, SpanErrorsStyled, FormGroupStyled };
