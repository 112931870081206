import styled from "styled-components";
import {
  defaultColors,
  desktopInputTexts,
  disabledButton,
} from "styled-components/App.styled";

const ActionsSectionSetyled = styled.div`
  height: 64px;
  text-align: center;
  background: ${({ isActive, nameBtn }) =>
    isActive === "all"
      ? defaultColors.skyBlue
      : isActive === nameBtn
        ? defaultColors.skyBlue
        : defaultColors.transparentWhite};
  border-radius: 8px;
  padding-top: 1.3rem;
  color: ${({ isActive, nameBtn }) =>
    isActive === "all"
      ? defaultColors.darkBlue
      : isActive === nameBtn
        ? defaultColors.darkBlue
        : defaultColors.white};
  border: 1px solid
    ${({ isActive, nameBtn }) =>
      isActive === "all"
        ? defaultColors.skyBlue
        : isActive === nameBtn
          ? defaultColors.skyBlue
          : defaultColors.white};

  cursor: pointer;

  font: ${desktopInputTexts};

  &:hover {
    background: ${defaultColors.greyBlue};
    color: ${defaultColors.white};
  }
`;

const SearchButtonSectionStyled = styled.button`
  width: 100%;
  height: 64px;
  background: ${defaultColors.skyBlue};
  mix-blend-mode: normal;
  border-radius: 8px;

  &:hover {
    background: ${defaultColors.transparentWhite};
    border: 1px solid ${defaultColors.skyBlue};
    color: ${defaultColors.white};
  }

  &:disabled {
    ${disabledButton}
  }
`;

export { ActionsSectionSetyled, SearchButtonSectionStyled };
