import { URLs } from "services/servicesUrls.services";
import { BEARERAUTH } from "utilities/authHeader.util";
import fetchAPI from "../../../services/fetchAPI.services";

export const setImageInProfileSection = async (data) => {
  try {
    const headers = {
      Authorization: BEARERAUTH(),
    };

    const fetchingData = await fetchAPI({
      method: "POST",
      url: `${URLs.image}`,
      headers,
      body: data,
    });

    if (!fetchingData)
      throw new Error("Tuvimos un problema para cargar la imagen");

    return fetchingData;
  } catch (error) {
    console.log("error post interceptor", { error });
    return { success: false, code: "X" };
  }
};
