import styled from "styled-components";
import { fadeIn } from "styled-components/animation.styled";
import {
  defaultColors,
  desktopButtonTexts,
  desktopInputTexts,
  subTitlesStyled,
} from "styled-components/App.styled";
import { FormAccountStyled } from "./FormAccount.styled";

const WrapButtonsEditOrCreateEventsStyled = styled.div`
  width: 100%;
`;

const whatButtonPaint = (checked, nameButton) =>
  checked === nameButton ? `1px solid ${defaultColors.skyBlue}` : "none";

const whatButtonPaintBackground = (checked, nameButton, from) =>
  checked === nameButton
    ? from
      ? defaultColors.skyBlue
      : defaultColors.transparentWhite
    : from
    ? defaultColors.somethingTransparentWhite
    : defaultColors.transparentWhite;

const whatButtonPaintColor = (checked, nameButton, disabled, from) =>
  checked === nameButton
    ? from
      ? defaultColors.darkBlue
      : defaultColors.skyBlue
    : disabled
    ? defaultColors.greyBlue
    : defaultColors.white;

const ToggleButtonEditOrCreateEventsStyled = styled.div`
  text-align: center;
  /* padding: 1rem; */
  padding: 0.7rem;
  // margin: 1rem 0 2rem 0;
  background-color: ${({ checked, nameButton, from }) =>
    whatButtonPaintBackground(checked, nameButton, from)};
  color: ${({ checked, nameButton, disabled, from }) =>
    whatButtonPaintColor(checked, nameButton, disabled, from)};

  border-bottom: ${({ checked, nameButton, from }) =>
    from ? "none" : whatButtonPaint(checked, nameButton)};
  text-decoration: none;
  border-radius: ${({ from }) => (from ? "8px" : "none")};
  display: ${({ displayBtn }) => (displayBtn ? "none" : "block")};

  cursor: pointer;

  font: ${({ from }) => (from ? desktopInputTexts : subTitlesStyled)};

  ${fadeIn({ time: "1.5s" })}

  &:hover {
    background-color: ${({ disabled }) => (disabled ? "" : "")};
    color: ${({ disabled }) =>
      disabled ? defaultColors.greyBlue : defaultColors.skyBlue};
  }

  &:active {
    outline: 2px solid ${defaultColors.skyBlue};
  }
`;

const WrapEditOrCreateEventsStyled = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const FormEditOrCreateEventsStyled = styled(FormAccountStyled)`
  margin: 2rem;
`;

const ButtonsEditEventStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 50%; */
  /* height: 56px; */
  padding: 1rem;
  border-radius: 12px;
  font: ${desktopButtonTexts};
  background-color: ${defaultColors.skyBlue};
  color: ${defaultColors.darkBlue};
  cursor: pointer;
  ${fadeIn({ time: "1.5s" })};

  &:hover {
    background-color: ${defaultColors.greyBlue} !important;
    color: ${defaultColors.white} !important;
    outline: 2px solid ${defaultColors.white};
  }
`;

export {
  WrapButtonsEditOrCreateEventsStyled,
  ToggleButtonEditOrCreateEventsStyled,
  WrapEditOrCreateEventsStyled,
  FormEditOrCreateEventsStyled,
  ButtonsEditEventStyled,
};
