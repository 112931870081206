import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";
import { BEARERAUTH } from "utilities/authHeader.util";

export const putAccount = async ({ data, id }) => {
  try {
    // console.log("BEARERAUTH() -->>", BEARERAUTH());
    const headers = {
      "Content-Type": "application/json",
      Authorization: BEARERAUTH(),
    };
    // console.log("data put account", { data, id });

    const sendData = await fetchAPI({
      method: "PUT",
      url: `${URLs.accounts}/${id}`,
      headers,
      body: JSON.stringify(data),
    });
    // console.log("q poija put account", { sendData });
    return sendData;
  } catch (error) {
    console.error("error put account interceptor", { error });
    return { success: false, code: "X" };
  }
};
