import { searchIcon, filterIcon, listIcon } from "assets/images/images";

const baseTitle = "Administrar";
const titleAccountInfoSection = {
  artists: baseTitle + " Artistas",
  locations: baseTitle + " Locaciones",
};

const inputSearch = [
  {
    type: "text",
    name: "search",
    placeholder: "Buscar",
    icon: searchIcon,
    properties: {
      padding: "15px 0 10px 25px",
    },
  },
];

// const actionsButtonsAccountInfo = [
//   {
//     // type: "text",
//     action: "filter",
//     name: "Filtrar",
//     icon: filterIcon,
//   },
//   {
//     // type: "text",
//     action: "list",
//     name: "Ordenar por:",
//     icon: listIcon,
//   },
// ];

export { inputSearch, titleAccountInfoSection };
