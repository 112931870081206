import { addIcon } from "assets/images/images";
import { InputForm } from "components/InputForm/InputForm";
import { SpinnerLoad } from "components/SpinnerLoad/SpinnerLoad";
import { useEffect } from "react";
import { useState } from "react";
import { messagesApp } from "utilities/messagesApp.util";
import { utilMessagesForms } from "utilities/utilAccount/messagesForms.util";
import {
  eventsGroups,
  imagesGroupForm,
  inputsGroups,
  platforms,
} from "utilities/utilAccount/utilNewArtist";
import { ImagesGroupForm } from "views/account/components/ImagesGroupForm/ImagesGroupForm";
import { InputPhoneNumber } from "views/account/components/InputPhoneNumber/InputPhoneNumber";
import { InputPlaceAutocomplete } from "views/account/components/InputPlaceAutocomplete/InputPlaceAutocomplete";
import { Platforms } from "views/account/components/Platforms/Platforms";
import { ProfileImage } from "views/account/components/ProfileImage/ProfileImage";
import { TextAreaForm } from "views/account/components/TextAreaForm/TextAreaForm";
import {
  DivFieldsStyled,
  FormAccountStyled,
  SmallTextStyled,
  TextDesktopInputs,
  TitleStyled,
} from "views/account/styled-components";
import { ButtonAddNewEventStyled } from "views/account/styled-components/ButtonAddNewEven.styled";
import { InputStyled } from "views/Auth/styled-components/InputAuthForm.styled";
import { ButtonsSubmitNewArtistForm, SelectButtonInNewArtist } from "..";
import { useNewArtistContext } from "../../contexts/NewArtistProvider";
import {
  FooterFormTextStyled,
  FormGroupStyled,
  WrapImagesGroupFormStyled,
} from "../../styled-components";
import { DateInput } from "./DateInput/DateInput";

export const FormNewArtists = () => {
  const {
    handleSubmit,
    register,
    errors,
    validationSchemaAccount: validationSchema,
    onSubmitSave,
    onSubmitPublic,
    loadingSubmit,
    setValue,
    clearErrors,
    setError,
    handlePhone,
    phone,
    watch,
    handleProfileLinksSources: handlelinks,
    handleLinkProfilePicture: linksProfilePicture,
    profileLinksSources,
    setDateEvent,
    dateEvent,
    handleDateEvent,
  } = useNewArtistContext();

  const [countDateInput, setCountDateInput] = useState(1);
  const [amountDateInput, setAmountDateInput] = useState([1]);

  const handleAddInputEvent = () => {
    setCountDateInput(countDateInput + 1);
    setAmountDateInput(amountDateInput.concat([countDateInput + 1]));
    setDateEvent([...dateEvent, { date: "", eventNum: countDateInput + 1 }]);
  };

  useEffect(() => {
    setCountDateInput(1);
    setAmountDateInput([1]);
  }, []);

  return (
    <>
      <FormAccountStyled>
        <DivFieldsStyled className="col col-12 mb-5">
          <ProfileImage
            register={register}
            errors={errors}
            watch={watch}
            linksProfilePicture={linksProfilePicture}
          />
        </DivFieldsStyled>
        <TitleStyled className="col col-12">Perfil</TitleStyled>
        <DivFieldsStyled className="col col-12">
          <InputForm
            register={register}
            errors={errors}
            validationSchema={validationSchema}
          >
            {[
              {
                type: "text",
                name: "name",
                placeholder: "*Nombre del Artista",
              },
            ]}
          </InputForm>
        </DivFieldsStyled>
        <DivFieldsStyled className="col col-12">
          <TextAreaForm
            register={register}
            errors={errors}
            validationSchema={validationSchema}
            placeholder="Biografía"
          />
        </DivFieldsStyled>
        <DivFieldsStyled className="col col-12">
          <SelectButtonInNewArtist />
        </DivFieldsStyled>

        <DivFieldsStyled className="col col-12">
          <Platforms
            handlelinks={handlelinks}
            profileLinksSources={profileLinksSources}
          >
            {{
              render: platforms.digitalPlatforms,
              nameSection: platforms.section.digitalPlatform,
            }}
            {{
              render: platforms.socialMedia,
              nameSection: platforms.section.socialNetworks,
            }}
          </Platforms>
        </DivFieldsStyled>

        <DivFieldsStyled className="col col-12">
          <InputForm
            register={register}
            errors={errors}
            validationSchema={validationSchema}
          >
            {inputsGroups}
          </InputForm>
          <InputPlaceAutocomplete
            register={register}
            errors={errors}
            validationSchema={validationSchema}
            setValue={setValue}
            clearErrors={clearErrors}
          />
          <InputPhoneNumber
            register={register}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            handlePhone={handlePhone}
            phone={phone}
          />
        </DivFieldsStyled>

        <DivFieldsStyled className="col col-12">
          <WrapImagesGroupFormStyled className="wrap-images-group-form-styled">
            <h3>Maximo peso permitido por imagen 2MB</h3>
            {imagesGroupForm.map((item, index) => (
              <ImagesGroupForm
                key={item.name}
                item={item}
                index={index}
                register={register}
                errors={errors}
                watch={watch}
              />
            ))}
          </WrapImagesGroupFormStyled>
        </DivFieldsStyled>

        <DivFieldsStyled className="col col-12">
          <TextDesktopInputs>{eventsGroups.nameSection}</TextDesktopInputs>

          {amountDateInput?.map((eventInput, index) => (
            <span key={index}>
              <TextDesktopInputs className="mt-2">
                {messagesApp.event} {eventInput}
              </TextDesktopInputs>
              {eventsGroups.inputsEventsGroups.map((field, index) => (
                <FormGroupStyled key={index} className="form-group">
                  <InputStyled
                    type={field.type}
                    className="form-control"
                    name={`${eventInput}-${field.name}`}
                    placeholder={field.placeholder}
                    {...register(eventInput + "-" + field.name)}
                  />
                </FormGroupStyled>
              ))}
              <DateInput
                dateEvent={dateEvent}
                handleDateEvent={handleDateEvent}
                eventNum={eventInput}
              />
            </span>
          ))}

          {/* <InputForm
            register={register}
            errors={errors}
            validationSchema={validationSchema}
          >
            {eventsGroups.inputsEventsGroups}
          </InputForm> 
          <DateInput />*/}

          <ButtonAddNewEventStyled
            className="my-3"
            onClick={handleAddInputEvent}
          >
            {messagesApp.addEvent} {addIcon({ width: "16px", height: "16px" })}
          </ButtonAddNewEventStyled>
        </DivFieldsStyled>

        <FooterFormTextStyled>
          <SmallTextStyled className="col col-7">
            {utilMessagesForms.formNewArtist}
          </SmallTextStyled>
        </FooterFormTextStyled>

        {loadingSubmit && <SpinnerLoad />}

        <div className="  d-flex justify-content-center col col-12">
          <ButtonsSubmitNewArtistForm
            text={"Guardar"}
            from="newArtist"
            background="50717B"
            color="FFFFFF"
            click={handleSubmit((data, e) => onSubmitSave(data, e))}
          />

          <ButtonsSubmitNewArtistForm
            text={"Publicar"}
            from="newArtist"
            click={handleSubmit((data, e) => onSubmitPublic(data, e))}
          />
        </div>
      </FormAccountStyled>
    </>
  );
};
