import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";
import { BEARERAUTH } from "utilities/authHeader.util";

export const putDataForm = async (data, accountId, restUrl, idProfile) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: BEARERAUTH(),
    };
    // console.log(
    //   { accountId, idProfile },
    //   `URL usada: ${URLs.accounts}/${accountId}/${restUrl}/${idProfile}`
    // );
    const fetchingData = await fetchAPI({
      method: "PUT",
      url: `${URLs.accounts}/${accountId}/${restUrl}/${idProfile}`,
      headers,
      body: JSON.stringify(data),
    });
    // console.log({ fetchingData });
    return fetchingData;
  } catch (error) {
    console.log("error post interceptor", { error });
    return { success: false, code: "X" };
  }
};
