import styled from "styled-components";
import { fadeIn } from "styled-components/animation.styled";
import {
  defaultColors,
  desktopInputTexts,
  disabledButton,
} from "styled-components/App.styled";
// import { fadeIn } from "../../../styled-components/animation.styled";
// import {
//   defaultColors,
//   desktopInputTexts,
// } from "../../../styled-components/App.styled";

const InputStyled = styled.input`
  width: 100%;
  /* max-width: 652px; */
  height: ${({ properties }) => (properties ? properties.height : "56px")};
  border: none;
  /* backdrop-filter: blur(8px); */
  border-radius: 8px;
  /* padding: ${({ properties }) =>
    properties?.padding ? properties.padding : "13px 0 2px 10px"}; */
  padding: ${({ properties }) =>
    properties?.padding ? properties.padding : "2px 0 2px 10px"};
  outline: 0;
  /* border-width: 0 0 1px;
  border-color: ${defaultColors.white}; */
  background: ${defaultColors.somethingTransparentWhite};
  color: ${defaultColors.white};
  transition: 0.3s;

  font: ${desktopInputTexts};

  ${fadeIn({ time: "1.5s" })}

  &:hover {
    // border-bottom: 1px solid ${defaultColors.white};
    cursor: pointer;
  }

  &:focus {
    /* border-radius: 12px; */
    outline: 3px solid ${defaultColors.darkBlue};
    padding: 15px 0 10px 10px;
    border-bottom: 2px solid ${defaultColors.white};
    background: ${defaultColors.transparentWhite};
    color: ${defaultColors.white};
  }

  &::placeholder {
    color: ${({ disabled }) =>
      disabled ? disabledButton : defaultColors.white};
  }

  &:disabled {
    ${disabledButton}
  }
`;

export { InputStyled };
