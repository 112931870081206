import { Routes, Route } from "react-router-dom";
import { BodyStyled } from "./styled-components/BodyStyled/Body.styled";

import { GlobalStyle } from "./styled-components/GlobalStyle.styled";
import { windowSize } from "./utilities/utilWindowSize";
import { HomeProvider } from "views/Home/contexts/HomeProvider";
import { ProfileMultimediaProvider } from "views/Home/contexts/ProfileMultimediaProvider";
import { lazy, Suspense, useEffect } from "react";
import { SpinnerLoad } from "components/SpinnerLoad/SpinnerLoad";
import { EditArtistOrLocationProvider } from "views/account/pages/EditArtistOrLocation/contexts/EditArtistOrLocationProvider";
import { SpecialFilterProvider } from "views/Home/contexts/SpecialFilterProvider";

import EditArtistOrLocation from "views/account/pages/EditArtistOrLocation/EditArtistOrLocation";
import { SpecialFilter } from "views/Home/pages/SpecialFilter/SpecialFilter";
import { SpecialFilterArtistForm } from "views/Home/pages/SpecialFilter/components/SpecialFilterArtistForm/SpecialFilterArtistForm";
import { SpecialFilterLocationForm } from "views/Home/pages/SpecialFilter/components/SpecialFilterLocationForm/SpecialFilterLocationForm";
import { useSelector } from "react-redux";
import { useCookies } from "hooks/useCookies";
import { useAuthenticate } from "views/Auth/hooks";
import TermsAndConditions from "static-pages/TermsAndConditions";
import PrivacyPolicy from "static-pages/PrivacyPolicy";
import Disclamer from "static-pages/Disclamer";
import { PaymentSection } from "views/account/pages/PaymentSection/PaymentSection";
import AddPaymentMethod from "views/account/pages/PaymentSection/AddPaymentMethod";

const PrivateRoutes = lazy(() =>
  import("./components/TypeRoutes/PrivateRoutes")
);
const PublicRoutes = lazy(() => import("./components/TypeRoutes/PublicRoutes"));
const Header = lazy(() => import("./components/Header/Header"));
const Home = lazy(() => import("./views/Home/Home"));
const CardsSectionHome = lazy(() =>
  import("views/Home/pages/CardsSectionHome/CardsSectionHome")
);
const DetailsProfileMultimedia = lazy(() =>
  import("views/Home/pages/DetailsProfileMultimedia/DetailsProfileMultimedia")
);

const ForgotPassword = lazy(() =>
  import("views/ForgotPassword/ForgotPassword")
);
const Auth = lazy(() => import("views/Auth/Auth"));
const Account = lazy(() => import("./views/account/Account"));
const YourAccount = lazy(() =>
  import("./views/account/pages/YourAccount/YourAccount")
);
const NewArtists = lazy(() =>
  import("./views/account/pages/NewArtists/NewArtists")
);
const NewLocation = lazy(() =>
  import("./views/account/pages/NewLocation/NewLocation")
);
const AccountInfo = lazy(() =>
  import("views/account/pages/AccountInfo/AccountInfo")
);
// const EditArtistOrLocation = lazy(() =>
//   import("views/account/pages/EditArtistOrLocation/EditArtistOrLocation")
// );
const ProfilesInfo = lazy(() =>
  import("views/account/pages/ProfilesInfo/ProfilesInfo")
);

const FeedbackNotification = lazy(() =>
  import("components/FeedbackNotification/FeedbackNotification")
);

const Dashboard = lazy(() => import("./views/Dashboard/Dashboard"));

const { REACT_APP_TOKEN: USERTOKEN } = process.env;

function App() {
  const { user, session } = useSelector((state) => state.userReducer);
  const { feedbackSuccess } = useSelector((state) => state.feedbackReducer);
  const { getNameCookie } = useCookies();
  const { getUserById } = useAuthenticate();

  useEffect(() => {
    if (Object.keys(user).length === 0 && session) {
      const userP = getNameCookie("UP");
      const userT = getNameCookie("AT");
      const sessionP = sessionStorage.getItem("userP");
      const sessionT = sessionStorage.getItem(USERTOKEN);
      if (userP || sessionT) {
        getUserById({
          userId: userP ? userP : sessionP,
          code: "userPersist",
          token: userT ? userT : sessionT,
        });
      }
    }
  }, [user]);

  return (
    <>
      <GlobalStyle windowSize={windowSize()} />
      <Header />
      <Suspense fallback={<SpinnerLoad />}>
        {feedbackSuccess && <FeedbackNotification />}
        <BodyStyled>
          <Routes>
            <Route element={<PublicRoutes />}>
              <Route
                path="/"
                element={
                  <HomeProvider>
                    <Home />
                  </HomeProvider>
                }
              >
                <Route path="artists" element={<CardsSectionHome />} />
                <Route path="locations" element={<CardsSectionHome />} />
              </Route>

              <Route
                path="special-filter"
                element={
                  <SpecialFilterProvider>
                    <SpecialFilter />
                  </SpecialFilterProvider>
                }
              >
                <Route path="artists" element={<SpecialFilterArtistForm />} />

                <Route
                  path="locations"
                  element={<SpecialFilterLocationForm />}
                />
              </Route>

              <Route
                path="/profile/artists/:idartists"
                element={
                  <ProfileMultimediaProvider>
                    <DetailsProfileMultimedia />
                  </ProfileMultimediaProvider>
                }
              />

              <Route
                path="/profile/locations/:idlocations"
                element={
                  <ProfileMultimediaProvider>
                    <DetailsProfileMultimedia />
                  </ProfileMultimediaProvider>
                }
              />

              <Route path="/login" element={<Auth />} />
              <Route path="/signup" element={<Auth />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />

              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
              <Route path="/disclamer" element={<Disclamer />} />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route path="/account/:id/" element={<Account />}>
                <Route index element={<YourAccount />} />
                <Route path="new-artist" element={<NewArtists />} />
                <Route path="new-location" element={<NewLocation />} />

                <Route path="account-info" element={<ProfilesInfo />}>
                  <Route index element={<AccountInfo />} />
                  <Route
                    path="edit-profile"
                    element={
                      <EditArtistOrLocationProvider>
                        <EditArtistOrLocation />
                      </EditArtistOrLocationProvider>
                    }
                  />
                </Route>

                <Route path="payments-section" element={<PaymentSection />} />
                <Route
                  path="add-payment-method"
                  element={<AddPaymentMethod />}
                />
              </Route>
              <Route path="/dashboard/:id" element={<Dashboard />} />
            </Route>

            <Route
              path="*"
              element={
                <HomeProvider>
                  <Home />
                </HomeProvider>
              }
            />
          </Routes>
        </BodyStyled>
      </Suspense>
    </>
  );
}

export default App;
