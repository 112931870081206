import styled from 'styled-components';
import { fadeIn } from 'styled-components/animation.styled';
import { defaultColors, desktopInputTexts } from 'styled-components/App.styled';

const InputPlaceAutocompleteStyled = styled.div`
	position: fixed;
	padding: 0;
	opacity: 0;
	filter: alpha(opacity=0);
`;

const InputAutocompleteStyled = styled.input`
	width: ${({ from }) => (from ? '100%' : '100%')};
	height: ${({ properties }) => (properties ? properties.height : '56px')};
	border-radius: ${({ from }) => (from ? '8px' : '0')};
	padding: ${({ from }) => (from ? '15px 0 2px 10px' : '30px 0 2px 3px')};
	outline: 0;
	background: rgba(58, 64, 66, 0.4);
	color: ${defaultColors.white};
	transition: 0.3s;
	font: ${desktopInputTexts};
	${fadeIn({ time: '1.5s' })}
	border-style: none !important;
	z-index: 3;
	/* display: absolute; */
	/* backdrop-filter: blur(8px); */
	// border-width: ${({ from }) => (from ? '1px' : '0 0 1px')};
	// border-color: ${defaultColors.white};
	&:hover {
		border-bottom: 2px solid ${defaultColors.white};
		cursor: pointer;
	}

	&:focus {
		border-radius: 12px;
		outline: 3px solid ${defaultColors.darkBlue};
		padding: 30px 0 10px 10px;
		// border-bottom: 1px solid ${defaultColors.white};
		color: ${defaultColors.white};
	}

	&::placeholder {
		color: ${defaultColors.white};
	}
`;

export { InputPlaceAutocompleteStyled, InputAutocompleteStyled };
