import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";

export const getProfileMultimedia = async (id) => {
  try {

    const url = `${URLs.profiles}/${id}`;

    const fetchData = await fetchAPI({
      method: "GET",
      url: `${url}`,
    });

    return fetchData;
  } catch (error) {
    console.log("error getInfoHome interceptor", { error });
    return { success: false, code: "X" };
  }
};
