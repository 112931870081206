import { InputStyled } from "components/InputForm/styled-components/Input.styled";
import { forwardRef } from "react";
import {
  IconSearchStyled,
  WrapInputSearchStyled,
} from "styled-components/InputSearch/InputSearch.styled";

export const InputSearchInSpecialFilter = forwardRef((props, ref) => {
  return (
    <WrapInputSearchStyled from="home">
      <IconSearchStyled>{props.input.icon}</IconSearchStyled>
      <InputStyled
        type={props.input.type}
        name={props.input.name}
        placeholder={props.input.placeholder}
        properties={props.input.properties}
        onChange={props.change}
        ref={ref}
      />
    </WrapInputSearchStyled>
  );
});
