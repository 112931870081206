import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { messagesApp } from "utilities/messagesApp.util";

export const ModalConfirmDeleteAllImageGallery = ({
  show,
  onHide,
  actionText,
  handleConfirm,
}) => {
  const handleCloseModal = () => {
    onHide();
  };

  const handlePressConfirm = () => {
    handleConfirm();
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={() => handleCloseModal()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: "black" }}
        >
          {messagesApp.confirmActionText}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ color: "white", backgroundColor: "black" }}>
        {actionText}
      </Modal.Body>
      <Modal.Footer className="d-flex flex-nowrap justify-content-around">
        <Button className="col col-4" onClick={() => handlePressConfirm()}>
          {messagesApp.confirmText}
        </Button>

        <Button
          className="col col-4"
          variant="danger"
          onClick={() => handleCloseModal()}
        >
          {messagesApp.cancelText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
