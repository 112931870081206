/**
 * linksFooterAuth used for link in Auth component
 */

const linksFooterAuth = {
  login: {
    to: "/signup",
    text: "Crear una cuenta",
  },
  signup: {
    to: "/login",
    text: "Ya tengo una cuenta",
  },
  forgotPassword: {
    to: "/forgot-password",
    text: "Olvidé mi contraseña",
  },
};

export { linksFooterAuth };
