import { useEffect, useRef } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { validationSchemaAccount } from 'utilities/utilAccount/utilValidationFields';
import {
	FormGroupStyled,
	SpanErrorsStyled,
} from 'views/account/pages/YourAccount/styled-components';

export const InputPhoneNumber = ({
	errors,
	setError,
	clearErrors,
	handlePhone,
	phone,
	fromCall = null,
}) => {
	const ref = useRef();

	const handleChangePhone = (event) => {
		if (!event) {
			handlePhone('');
			return setError('phone', {
				type: 'required',
				message: validationSchemaAccount.phone.required.message,
			});
		}

		if (event.length < 12 || event.length > 15) {
			setError('phone', {
				type: 'length',
				message: validationSchemaAccount.phone.length.message,
			});
		} else {
			ref.current.value = event;
			clearErrors('phone');
			handlePhone(event);
		}
	};

	useEffect(() => {
		if (ref.current.value === '') {
			if (phone) {
				clearErrors('phone');
				ref.current.value = phone;
			} else {
				setError('phone', {
					type: 'required',
					message: validationSchemaAccount.phone.required.message,
				});
			}
		}

		return () => {
			// console.log('desmontando efecto handleChangePhone');
		};
	}, [ref, phone]);

	return (
		<FormGroupStyled className="form-group">
			<input ref={ref} className="d-none" />
			<PhoneInput
				placeholder="* WhatsApp"
				value={phone}
				onChange={handleChangePhone}
			/>
			{errors.phone && (
				<SpanErrorsStyled>{errors['phone']['message']}</SpanErrorsStyled>
			)}
		</FormGroupStyled>
	);
};
