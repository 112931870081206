import { useEffect, useRef, useState } from "react";
import { searchingItemExistInArray } from "utilities/searchingPlatformExist.util";
import { patterns } from "utilities/utilPatterns";

export const usePlatformLinksModal = (props) => {
  const ref = useRef(null);

  const [link, setLink] = useState("");
  const [inputUrl, setInputUrl] = useState("");
  const [loadingUrl, setLoadingUrl] = useState(false);

  const [disabledSaveButton, setDisabledSaveButton] = useState(true);

  const handleChangeURL = (event, typeId) => {
    const url = event.currentTarget.value;
    setInputUrl(url);
    setLink({ url, typeId });
  };

  const handleClickInSaveButon = () => {

    if (link.url === "") {
      props.handlelinks({
        source: props.item.source,
        url: false,
        typeId: link.typeId,
      });
    } else {
      props.handlelinks({
        source: props.item.source,
        url: link.url,
        typeId: link.typeId,
      });
    }
    ref.current.click();
  };

  useEffect(() => {
    if (link.url && patterns.patternURL.test(link.url)) {
      setDisabledSaveButton(false);
    } else {
      setDisabledSaveButton(true);
    }
  }, [link]);

  useEffect(() => {
    if (
      props?.item?.name &&
      props?.show &&
      props.profileLinksSources.length > 0
    ) {
      setLoadingUrl(true);

      const checkExist = searchingItemExistInArray(
        props.profileLinksSources,
        props.item.name
      );

      if (!checkExist) setInputUrl("");
      else setInputUrl(checkExist.url);

      setLoadingUrl(false);
    }
  }, [props]);

  return {
    handleClickInSaveButon,
    handleChangeURL,
    ref,
    link,
    disabledSaveButton,
    inputUrl,
    loadingUrl,
  };
};
