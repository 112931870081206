import {
	spotifyIcon,
	youtubeIcon,
	appleMusicIcon,
	tidalIcon,
	deezerIcon,
	amazonMusic,
	spotifyInUseIcon,
	youtubeInUseIcon,
	tidalInUseIcon,
	appleMusicInUseIcon,
	deezerInUseIcon,
	amazonInUseMusic,
	instagramIcon,
	facebookIcon,
	twitterIcon,
	tiktokIcon,
	instagramInUseIcon,
	facebookInUseIcon,
	twitterInUseIcon,
	tiktokInUseIcon,
} from 'assets/images/imagesPlatforms';

const digitalPlatforms = {
	spotify: { empty: spotifyIcon, inUse: spotifyInUseIcon },
	youtube: { empty: youtubeIcon, inUse: youtubeInUseIcon },
	applemusic: { empty: appleMusicIcon, inUse: appleMusicInUseIcon },
	tidal: { empty: tidalIcon, inUse: tidalInUseIcon },
	deezer: { empty: deezerIcon, inUse: deezerInUseIcon },
	amazonmusic: { empty: amazonMusic, inUse: amazonInUseMusic },
	instagram: { empty: instagramIcon, inUse: instagramInUseIcon },
	facebook: { empty: facebookIcon, inUse: facebookInUseIcon },
	twitter: { empty: twitterIcon, inUse: twitterInUseIcon },
	tiktok: { empty: tiktokIcon, inUse: tiktokInUseIcon },
};

export { digitalPlatforms };
