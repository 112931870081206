// import { iconUser } from '../../assets/images/images';

const signLinks = [
	{
		name: 'Ingresar',
		path: '/login',
	},
	{
		name: 'Registrate',
		path: '/signup',
	},
];

const protectedLinks = [
	// {
	//   name: "Dashboard",
	//   path: "/dashboard",
	// },
	{
		name: 'Tu cuenta',
		path: '/account',
		// icon: iconUser,
	},
];

export { signLinks, protectedLinks };
