import styled from "styled-components";
import { defaultColors, hoverEffectFieldsForm } from "styled-components/App.styled";
// import { defaultColors, hoverEffectFieldsForm } from "../../../styled-components/App.styled";

const CircleButtonStyled = styled.button`
  width: 208px;
  height: 208px;
  background: ${defaultColors.somethingTransparentWhite};
  border: 1px solid ${defaultColors.white};
  border-radius: 50%;

  &:hover {
    ${hoverEffectFieldsForm};
  }
`;

export { CircleButtonStyled };
