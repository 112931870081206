import fetchAPI from 'services/fetchAPI.services';
import { BEARERAUTH } from 'utilities/authHeader.util';

export const postPaymentMethodsSession = async (accountId, userId) => {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: BEARERAUTH(),
		};

		const fetchPaymentMethods = await fetchAPI({
			url: `https://api.showbookie.com/v1/Account/${accountId}/PaymentMethods/Session`,
			method: 'POST',
			headers,
			body: JSON.stringify({ id: userId }),
		});

		return fetchPaymentMethods;
	} catch (error) {
		console.error('error post interceptor postPaymentMethodsSession', {
			error,
		});
		return { success: false, code: 'X' };
	}
};
