import { TYPES } from "redux/types/types";

export const feedbackSuccess = (data) => ({
  type: TYPES.FEEDBACK_USER_SHOW,
  payload: data,
});

export const feedbackResetNotification = (error) => ({
  type: TYPES.FEEDBACK_USER_RESET_NOTIFICATION,
});
