import { patterns } from "../utilPatterns";

const msgValidation = {
  required: "El campo es requerido",
  match: {
    password: "Las contraseñas deben coincidir",
  },
  email: {
    pattern: "Ingresa un email válido",
  },
  passwordField: {
    pattern: "Debe contener al menos una letra y un número",
    numberOfCharacters: "Debe contener entre 6 y 20 caracteres",
  },
};

const validationSchema = {
  email: {
    required: { value: true, message: "El email es requerido" },
    pattern: {
      value: patterns.patternEmail,
      message: msgValidation.email.pattern,
    },
  },
  password: {
    required: { value: true, message: "Contraseña es requerida" },
    pattern: {
      value: patterns.patternPassword,
      message: msgValidation.passwordField.pattern,
    },
    minLength: {
      value: 6,
      message: msgValidation.passwordField.numberOfCharacters,
    },
    maxLength: {
      value: 20,
      message: msgValidation.passwordField.numberOfCharacters,
    },
  },
  firstName: {
    required: { value: true, message: "Nombre es requerido" },
  },
  lastName: {
    required: { value: true, message: "Apellido es requerido" },
  },
};

const validationConfirmField = (getValues) => ({
  passwordConfirmation: {
    required: { value: true, message: "Confirmar contraseña es requerido" },
    validate: (value) => {
      const { password } = getValues();
      return password === value || msgValidation.match.password;
    },
  },
});

export { validationSchema, validationConfirmField };
