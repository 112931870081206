import { Image } from 'react-bootstrap';
import styled from 'styled-components';
import { fadeIn } from 'styled-components/animation.styled';
import {
	defaultColors,
	desktopInputTexts,
	hoverEffectFieldsForm,
	subTitlesStyled,
} from 'styled-components/App.styled';

const CoverFieldStyled = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	width: 100%;
	height: 528px;
	background: linear-gradient(0deg, #0c0d0f 0%, rgba(0, 0, 0, 0) 100%);
	// border-radius: 8px;
	color: ${defaultColors.white};
	/* padding: 15px 0 0 15px; */
	font: ${desktopInputTexts};
	&:hover {
		border: none;
	}

	${fadeIn}
`;

const SubtitleCoverStyled = styled.div`
	width: 206px;
	text-align: center;
	padding-top: 10px;
	font: ${subTitlesStyled};
`;

const WrapImageCoverStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const CoverImageStyled = styled(Image)`
	width: 100%;
	height: 100%;
`;

export {
	CoverFieldStyled,
	CoverImageStyled,
	SubtitleCoverStyled,
	WrapImageCoverStyled,
};
