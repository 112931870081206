import React, { useEffect } from 'react';

export default function PrivacyPolicy() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="index-page">
			<div className="text-container">
				<div className="c9 doc-content">
					<h1>PRIVACY POLICY</h1>
					<p className="c1">
						<span className="c0">Effective date: 2022-07-11</span>
					</p>
					<p className="c1">
						<span>1. </span>
						<span className="c2">Introduction</span>
					</p>
					<p className="c1">
						<span>Welcome to </span>
						<span className="c5">ShowBookie</span>
						<span className="c0">.</span>
					</p>
					<p className="c1">
						<span className="c5">ShowBookie</span>
						<span>
							&nbsp;(&ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;)
							operates{' '}
						</span>
						<span className="c5">ShowBookie.com</span>
						<span>&nbsp;(hereinafter referred to as </span>
						<span className="c5">&ldquo;Service&rdquo;</span>
						<span className="c0">).</span>
					</p>
					<p className="c1">
						<span>Our Privacy Policy governs your visit to </span>
						<span className="c5">ShowBookie.com</span>
						<span className="c0">
							, and explains how we collect, safeguard and disclose information
							that results from your use of our Service.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							We use your data to provide and improve Service. By using Service,
							you agree to the collection and use of information in accordance
							with this policy. Unless otherwise defined in this Privacy Policy,
							the terms used in this Privacy Policy have the same meanings as in
							our Terms and Conditions.
						</span>
					</p>
					<p className="c1">
						<span>Our Terms and Conditions (</span>
						<span className="c5">&ldquo;Terms&rdquo;</span>
						<span>
							) govern all use of our Service and together with the Privacy
							Policy constitutes your agreement with us (
						</span>
						<span className="c5">&ldquo;agreement&rdquo;</span>
						<span className="c0">).</span>
					</p>
					<p className="c1">
						<span>2. </span>
						<span className="c2">Definitions</span>
					</p>
					<p className="c1">
						<span className="c5">SERVICE</span>
						<span className="c0">
							&nbsp;means the ShowBookie.com website operated by ShowBookie.
						</span>
					</p>
					<p className="c1">
						<span className="c5">PERSONAL DATA</span>
						<span className="c0">
							&nbsp;means data about a living individual who can be identified
							from those data (or from those and other information either in our
							possession or likely to come into our possession).
						</span>
					</p>
					<p className="c1">
						<span className="c5">USAGE DATA</span>
						<span className="c0">
							&nbsp;is data collected automatically either generated by the use
							of Service or from Service infrastructure itself (for example, the
							duration of a page visit).
						</span>
					</p>
					<p className="c1">
						<span className="c5">COOKIES</span>
						<span className="c0">
							&nbsp;are small files stored on your device (computer or mobile
							device).
						</span>
					</p>
					<p className="c1">
						<span className="c5">DATA CONTROLLER</span>
						<span className="c0">
							&nbsp;means a natural or legal person who (either alone or jointly
							or in common with other persons) determines the purposes for which
							and the manner in which any personal data are, or are to be,
							processed. For the purpose of this Privacy Policy, we are a Data
							Controller of your data.
						</span>
					</p>
					<p className="c1">
						<span className="c5">DATA PROCESSORS (OR SERVICE PROVIDERS)</span>
						<span className="c0">
							&nbsp;means any natural or legal person who processes the data on
							behalf of the Data Controller. We may use the services of various
							Service Providers in order to process your data more effectively.
						</span>
					</p>
					<p className="c1">
						<span className="c5">DATA SUBJECT</span>
						<span className="c0">
							&nbsp;is any living individual who is the subject of Personal
							Data.
						</span>
					</p>
					<p className="c1">
						<span className="c5">THE USER</span>
						<span className="c0">
							&nbsp;is the individual using our Service. The User corresponds to
							the Data Subject, who is the subject of Personal Data.
						</span>
					</p>
					<p className="c1">
						<span>3. </span>
						<span className="c2">Information Collection and Use</span>
					</p>
					<p className="c1">
						<span className="c0">
							We collect several different types of information for various
							purposes to provide and improve our Service to you.
						</span>
					</p>
					<p className="c1">
						<span>4. </span>
						<span className="c2">Types of Data Collected</span>
					</p>
					<p className="c1">
						<span className="c2">Personal Data</span>
					</p>
					<p className="c1">
						<span>
							While using our Service, we may ask you to provide us with certain
							personally identifiable information that can be used to contact or
							identify you (
						</span>
						<span className="c5">&ldquo;Personal Data&rdquo;</span>
						<span className="c0">
							). Personally identifiable information may include, but is not
							limited to:
						</span>
					</p>
					<p className="c1">
						<span className="c0">0.1. Email address</span>
					</p>
					<p className="c1">
						<span className="c0">0.2. First name and last name</span>
					</p>
					<p className="c1">
						<span className="c0">0.3. Phone number</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.4. Address, Country, State, Province, ZIP/Postal code, City
						</span>
					</p>
					<p className="c1">
						<span className="c0">0.5. Cookies and Usage Data</span>
					</p>
					<p className="c1">
						<span className="c0">
							We may use your Personal Data to contact you with newsletters,
							marketing or promotional materials and other information that may
							be of interest to you. You may opt out of receiving any, or all,
							of these communications from us by following the unsubscribe link.
						</span>
					</p>
					<p className="c1">
						<span className="c2">Usage Data</span>
					</p>
					<p className="c1">
						<span>
							We may also collect information that your browser sends whenever
							you visit our Service or when you access Service by or through any
							device (
						</span>
						<span className="c5">&ldquo;Usage Data&rdquo;</span>
						<span className="c0">).</span>
					</p>
					<p className="c1">
						<span className="c0">
							This Usage Data may include information such as your
							computer&rsquo;s Internet Protocol address (e.g. IP address),
							browser type, browser version, the pages of our Service that you
							visit, the time and date of your visit, the time spent on those
							pages, unique device identifiers and other diagnostic data.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							When you access Service with a device, this Usage Data may include
							information such as the type of device you use, your device unique
							ID, the IP address of your device, your device operating system,
							the type of Internet browser you use, unique device identifiers
							and other diagnostic data.
						</span>
					</p>
					<p className="c1">
						<span className="c2">Location Data</span>
					</p>
					<p className="c1">
						<span>
							We may use and store information about your location if you give
							us permission to do so (
						</span>
						<span className="c5">&ldquo;Location Data&rdquo;</span>
						<span className="c0">
							). We use this data to provide features of our Service, to improve
							and customize our Service.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							You can enable or disable location services when you use our
							Service at any time by way of your device settings.
						</span>
					</p>
					<p className="c1">
						<span className="c2">Tracking Cookies Data</span>
					</p>
					<p className="c1">
						<span className="c0">
							We use cookies and similar tracking technologies to track the
							activity on our Service and we hold certain information.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							Cookies are files with a small amount of data which may include an
							anonymous unique identifier. Cookies are sent to your browser from
							a website and stored on your device. Other tracking technologies
							are also used such as beacons, tags and scripts to collect and
							track information and to improve and analyze our Service.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							You can instruct your browser to refuse all cookies or to indicate
							when a cookie is being sent. However, if you do not accept
							cookies, you may not be able to use some portions of our Service.
						</span>
					</p>
					<p className="c1">
						<span className="c0">Examples of Cookies we use:</span>
					</p>
					<p className="c1">
						<span>0.1. </span>
						<span className="c5">Session Cookies:</span>
						<span className="c0">
							&nbsp;We use Session Cookies to operate our Service.
						</span>
					</p>
					<p className="c1">
						<span>0.2. </span>
						<span className="c5">Preference Cookies:</span>
						<span className="c0">
							&nbsp;We use Preference Cookies to remember your preferences and
							various settings.
						</span>
					</p>
					<p className="c1">
						<span>0.3. </span>
						<span className="c5">Security Cookies:</span>
						<span className="c0">
							&nbsp;We use Security Cookies for security purposes.
						</span>
					</p>
					<p className="c1">
						<span>0.4. </span>
						<span className="c5">Advertising Cookies:</span>
						<span className="c0">
							&nbsp;Advertising Cookies are used to serve you with
							advertisements that may be relevant to you and your interests.
						</span>
					</p>
					<p className="c1">
						<span className="c2">Other Data</span>
					</p>
					<p className="c1">
						<span>
							While using our Service, we may also collect the following
							information: sex, age, date of birth, place of birth, passport
							details, citizenship, registration at place of residence and
							actual address, telephone number (work, mobile), details of
							documents on education, qualification, professional training,
							employment agreements,
						</span>
						<span>
							<a
								className="c4"
								href="https://www.google.com/url?q=https://policymaker.io/non-disclosure-agreement/&amp;sa=D&amp;source=editors&amp;ust=1657787565899536&amp;usg=AOvVaw081aHyRCvDICAWUQLVtMlx"
							>
								&nbsp;
							</a>
						</span>
						<span className="c3">
							<a
								className="c4"
								href="https://www.google.com/url?q=https://policymaker.io/non-disclosure-agreement/&amp;sa=D&amp;source=editors&amp;ust=1657787565899697&amp;usg=AOvVaw3heit6ggdkb4BzrnYEAegk"
							>
								NDA agreements
							</a>
						</span>
						<span className="c0">
							, information on bonuses and compensation, information on marital
							status, family members, social security (or other taxpayer
							identification) number, office location and other data.
						</span>
					</p>
					<p className="c1">
						<span>5. </span>
						<span className="c2">Use of Data</span>
					</p>
					<p className="c1">
						<span className="c0">
							ShowBookie uses the collected data for various purposes:
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.1. to provide and maintain our Service;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.2. to notify you about changes to our Service;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.3. to allow you to participate in interactive features of our
							Service when you choose to do so;
						</span>
					</p>
					<p className="c1">
						<span className="c0">0.4. to provide customer support;</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.5. to gather analysis or valuable information so that we can
							improve our Service;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.6. to monitor the usage of our Service;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.7. to detect, prevent and address technical issues;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.8. to fulfil any other purpose for which you provide it;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.9. to carry out our obligations and enforce our rights arising
							from any contracts entered into between you and us, including for
							billing and collection;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.10. to provide you with notices about your account and/or
							subscription, including expiration and renewal notices,
							email-instructions, etc.;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.11. to provide you with news, special offers and general
							information about other goods, services and events which we offer
							that are similar to those that you have already purchased or
							enquired about unless you have opted not to receive such
							information;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.12. in any other way we may describe when you provide the
							information;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.13. for any other purpose with your consent.
						</span>
					</p>
					<p className="c1">
						<span>6. </span>
						<span className="c2">Retention of Data</span>
					</p>
					<p className="c1">
						<span className="c0">
							We will retain your Personal Data only for as long as is necessary
							for the purposes set out in this Privacy Policy. We will retain
							and use your Personal Data to the extent necessary to comply with
							our legal obligations (for example, if we are required to retain
							your data to comply with applicable laws), resolve disputes, and
							enforce our legal agreements and policies.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							We will also retain Usage Data for internal analysis purposes.
							Usage Data is generally retained for a shorter period, except when
							this data is used to strengthen the security or to improve the
							functionality of our Service, or we are legally obligated to
							retain this data for longer time periods.
						</span>
					</p>
					<p className="c1">
						<span>7. </span>
						<span className="c2">Transfer of Data</span>
					</p>
					<p className="c1">
						<span className="c0">
							Your information, including Personal Data, may be transferred to
							&ndash; and maintained on &ndash; computers located outside of
							your state, province, country or other governmental jurisdiction
							where the data protection laws may differ from those of your
							jurisdiction.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							If you are located outside United States Of America and choose to
							provide information to us, please note that we transfer the data,
							including Personal Data, to United States Of America and process
							it there.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							Your consent to this Privacy Policy followed by your submission of
							such information represents your agreement to that transfer.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							ShowBookie will take all the steps reasonably necessary to ensure
							that your data is treated securely and in accordance with this
							Privacy Policy and no transfer of your Personal Data will take
							place to an organisation or a country unless there are adequate
							controls in place including the security of your data and other
							personal information.
						</span>
					</p>
					<p className="c1">
						<span>8. </span>
						<span className="c2">Disclosure of Data</span>
					</p>
					<p className="c1">
						<span className="c0">
							We may disclose personal information that we collect, or you
							provide:
						</span>
					</p>
					<p className="c1">
						<span>0.1. </span>
						<span className="c2">Disclosure for Law Enforcement.</span>
					</p>
					<p className="c1">
						<span className="c0">
							Under certain circumstances, we may be required to disclose your
							Personal Data if required to do so by law or in response to valid
							requests by public authorities.
						</span>
					</p>
					<p className="c1">
						<span>0.2. </span>
						<span className="c2">Business Transaction.</span>
					</p>
					<p className="c1">
						<span className="c0">
							If we or our subsidiaries are involved in a merger, acquisition or
							asset sale, your Personal Data may be transferred.
						</span>
					</p>
					<p className="c1">
						<span>0.3. </span>
						<span className="c2">
							Other cases. We may disclose your information also:
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.3.1. to our subsidiaries and affiliates;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.3.2. to contractors, service providers, and other third parties
							we use to support our business;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.3.3. to fulfill the purpose for which you provide it;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.3.4. for the purpose of including your company&rsquo;s logo on
							our website;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.3.5. for any other purpose disclosed by us when you provide the
							information;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.3.6. with your consent in any other cases;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.3.7. if we believe disclosure is necessary or appropriate to
							protect the rights, property, or safety of the Company, our
							customers, or others.
						</span>
					</p>
					<p className="c1">
						<span>9. </span>
						<span className="c2">Security of Data</span>
					</p>
					<p className="c1">
						<span className="c0">
							The security of your data is important to us but remember that no
							method of transmission over the Internet or method of electronic
							storage is 100% secure. While we strive to use commercially
							acceptable means to protect your Personal Data, we cannot
							guarantee its absolute security.
						</span>
					</p>
					<p className="c1">
						<span>10. </span>
						<span className="c2">
							Your Data Protection Rights Under General Data Protection
							Regulation (GDPR)
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							If you are a resident of the European Union (EU) and European
							Economic Area (EEA), you have certain data protection rights,
							covered by GDPR.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							We aim to take reasonable steps to allow you to correct, amend,
							delete, or limit the use of your Personal Data.
						</span>
					</p>
					<p className="c1">
						<span>
							If you wish to be informed what Personal Data we hold about you
							and if you want it to be removed from our systems, please email us
							at{' '}
						</span>
						<span className="c5">support@webdesignpilot.com</span>
						<span className="c0">.</span>
					</p>
					<p className="c1">
						<span className="c0">
							In certain circumstances, you have the following data protection
							rights:
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.1. the right to access, update or to delete the information we
							have on you;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.2. the right of rectification. You have the right to have your
							information rectified if that information is inaccurate or
							incomplete;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.3. the right to object. You have the right to object to our
							processing of your Personal Data;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.4. the right of restriction. You have the right to request that
							we restrict the processing of your personal information;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.5. the right to data portability. You have the right to be
							provided with a copy of your Personal Data in a structured,
							machine-readable and commonly used format;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.6. the right to withdraw consent. You also have the right to
							withdraw your consent at any time where we rely on your consent to
							process your personal information;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							Please note that we may ask you to verify your identity before
							responding to such requests. Please note, we may not able to
							provide Service without some necessary data.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							You have the right to complain to a Data Protection Authority
							about our collection and use of your Personal Data. For more
							information, please contact your local data protection authority
							in the European Economic Area (EEA).
						</span>
					</p>
					<p className="c1">
						<span>11. </span>
						<span className="c2">
							Your Data Protection Rights under the California Privacy
							Protection Act (CalOPPA)
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							CalOPPA is the first state law in the nation to require commercial
							websites and online services to post a privacy policy. The
							law&rsquo;s reach stretches well beyond California to require a
							person or company in the United States (and conceivable the world)
							that operates websites collecting personally identifiable
							information from California consumers to post a conspicuous
							privacy policy on its website stating exactly the information
							being collected and those individuals with whom it is being
							shared, and to comply with this policy.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							According to CalOPPA we agree to the following:
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.1. users can visit our site anonymously;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.2. our Privacy Policy link includes the word
							&ldquo;Privacy&rdquo;, and can easily be found on the home page of
							our website;
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.3. users will be notified of any privacy policy changes on our
							Privacy Policy Page;
						</span>
					</p>
					<p className="c1">
						<span>
							0.4. users are able to change their personal information by
							emailing us at{' '}
						</span>
						<span className="c5">support@webdesignpilot.com</span>
						<span className="c0">.</span>
					</p>
					<p className="c1">
						<span className="c0">
							Our Policy on &ldquo;Do Not Track&rdquo; Signals:
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							We honor Do Not Track signals and do not track, plant cookies, or
							use advertising when a Do Not Track browser mechanism is in place.
							Do Not Track is a preference you can set in your web browser to
							inform websites that you do not want to be tracked.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							You can enable or disable Do Not Track by visiting the Preferences
							or Settings page of your web browser.
						</span>
					</p>
					<p className="c1">
						<span>12. </span>
						<span className="c2">
							Your Data Protection Rights under the California Consumer Privacy
							Act (CCPA)
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							If you are a California resident, you are entitled to learn what
							data we collect about you, ask to delete your data and not to sell
							(share) it. To exercise your data protection rights, you can make
							certain requests and ask us:
						</span>
					</p>
					<p className="c1">
						<span className="c2">
							0.1. What personal information we have about you. If you make this
							request, we will return to you:
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.0.1. The categories of personal information we have collected
							about you.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.0.2. The categories of sources from which we collect your
							personal information.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.0.3. The business or commercial purpose for collecting or
							selling your personal information.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.0.4. The categories of third parties with whom we share personal
							information.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.0.5. The specific pieces of personal information we have
							collected about you.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.0.6. A list of categories of personal information that we have
							sold, along with the category of any other company we sold it to.
							If we have not sold your personal information, we will inform you
							of that fact.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							0.0.7. A list of categories of personal information that we have
							disclosed for a business purpose, along with the category of any
							other company we shared it with.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							Please note, you are entitled to ask us to provide you with this
							information up to two times in a rolling twelve-month period. When
							you make this request, the information provided may be limited to
							the personal information we collected about you in the previous 12
							months.
						</span>
					</p>
					<p className="c1">
						<span className="c2">
							0.2. To delete your personal information. If you make this
							request, we will delete the personal information we hold about you
							as of the date of your request from our records and direct any
							service providers to do the same. In some cases, deletion may be
							accomplished through de-identification of the information. If you
							choose to delete your personal information, you may not be able to
							use certain functions that require your personal information to
							operate.
						</span>
					</p>
					<p className="c1">
						<span className="c2">
							0.3. To stop selling your personal information. We don&rsquo;t
							sell or rent your personal information to any third parties for
							any purpose. We do not sell your personal information for monetary
							consideration. However, under some circumstances, a transfer of
							personal information to a third party, or within our family of
							companies, without monetary consideration may be considered a
							&ldquo;sale&rdquo; under California law. You are the only owner of
							your Personal Data and can request disclosure or deletion at any
							time.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							If you submit a request to stop selling your personal information,
							we will stop making such transfers.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							Please note, if you ask us to delete or stop selling your data, it
							may impact your experience with us, and you may not be able to
							participate in certain programs or membership services which
							require the usage of your personal information to function. But in
							no circumstances, we will discriminate against you for exercising
							your rights.
						</span>
					</p>
					<p className="c1">
						<span>
							To exercise your California data protection rights described
							above, please send your request(s) by email:{' '}
						</span>
						<span className="c5">support@webdesignpilot.com</span>
						<span className="c0">.</span>
					</p>
					<p className="c1">
						<span className="c0">
							Your data protection rights, described above, are covered by the
							CCPA, short for the California Consumer Privacy Act. To find out
							more, visit the official California Legislative Information
							website. The CCPA took effect on 01/01/2020.
						</span>
					</p>
					<p className="c1">
						<span>13. </span>
						<span className="c2">Service Providers</span>
					</p>
					<p className="c1">
						<span>
							We may employ third party companies and individuals to facilitate
							our Service (
						</span>
						<span className="c5">&ldquo;Service Providers&rdquo;</span>
						<span className="c0">
							), provide Service on our behalf, perform Service-related services
							or assist us in analysing how our Service is used.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							These third parties have access to your Personal Data only to
							perform these tasks on our behalf and are obligated not to
							disclose or use it for any other purpose.
						</span>
					</p>
					<p className="c1">
						<span>14. </span>
						<span className="c2">Analytics</span>
					</p>
					<p className="c1">
						<span className="c0">
							We may use third-party Service Providers to monitor and analyze
							the use of our Service.
						</span>
					</p>
					<p className="c1">
						<span>15. </span>
						<span className="c2">CI/CD tools</span>
					</p>
					<p className="c1">
						<span className="c0">
							We may use third-party Service Providers to automate the
							development process of our Service.
						</span>
					</p>
					<p className="c1">
						<span>16. </span>
						<span className="c2">Behavioral Remarketing</span>
					</p>
					<p className="c1">
						<span className="c0">
							We may use remarketing services to advertise on third party
							websites to you after you visited our Service. We and our
							third-party vendors use cookies to inform, optimise and serve ads
							based on your past visits to our Service.
						</span>
					</p>
					<p className="c1">
						<span>17. </span>
						<span className="c2">Payments</span>
					</p>
					<p className="c1">
						<span className="c0">
							We may provide paid products and/or services within Service. In
							that case, we use third-party services for payment processing
							(e.g. payment processors).
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							We will not store or collect your payment card details. That
							information is provided directly to our third-party payment
							processors whose use of your personal information is governed by
							their Privacy Policy. These payment processors adhere to the
							standards set by PCI-DSS as managed by the PCI Security Standards
							Council, which is a joint effort of brands like Visa, Mastercard,
							American Express and Discover. PCI-DSS requirements help ensure
							the secure handling of payment information.
						</span>
					</p>
					<p className="c1">
						<span>18. </span>
						<span className="c2">Links to Other Sites</span>
					</p>
					<p className="c1">
						<span className="c0">
							Our Service may contain links to other sites that are not operated
							by us. If you click a third party link, you will be directed to
							that third party&rsquo;s site. We strongly advise you to review
							the Privacy Policy of every site you visit.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							We have no control over and assume no responsibility for the
							content, privacy policies or practices of any third party sites or
							services.
						</span>
					</p>
					<p className="c1">
						<span>19. </span>
						<span className="c2">Children&rsquo;s Privacy</span>
					</p>
					<p className="c1">
						<span>
							Our Services are not intended for use by children under the age of
							18 (
						</span>
						<span className="c5">&ldquo;Child&rdquo;</span>
						<span>&nbsp;or </span>
						<span className="c5">&ldquo;Children&rdquo;</span>
						<span className="c0">).</span>
					</p>
					<p className="c1">
						<span className="c0">
							We do not knowingly collect personally identifiable information
							from Children under 18. If you become aware that a Child has
							provided us with Personal Data, please contact us. If we become
							aware that we have collected Personal Data from Children without
							verification of parental consent, we take steps to remove that
							information from our servers.
						</span>
					</p>
					<p className="c1">
						<span>20. </span>
						<span className="c2">Changes to This Privacy Policy</span>
					</p>
					<p className="c1">
						<span className="c0">
							We may update our Privacy Policy from time to time. We will notify
							you of any changes by posting the new Privacy Policy on this page.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							We will let you know via email and/or a prominent notice on our
							Service, prior to the change becoming effective and update
							&ldquo;effective date&rdquo; at the top of this Privacy Policy.
						</span>
					</p>
					<p className="c1">
						<span className="c0">
							You are advised to review this Privacy Policy periodically for any
							changes. Changes to this Privacy Policy are effective when they
							are posted on this page.
						</span>
					</p>
					<p className="c1">
						<span>21. </span>
						<span className="c2">Contact Us</span>
					</p>
					<p className="c1">
						<span>
							If you have any questions about this Privacy Policy, please
							contact us by email:{' '}
						</span>
						<span className="c5">support@webdesignpilot.com</span>
						<span>.</span>
					</p>
					<p className="c10">
						<span className="c0"></span>
					</p>
				</div>
			</div>
		</div>
	);
}
