import { Card } from "components/Card/Card";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WrapCardSectionStyled } from "styled-components/App.styled";
import { messagesApp } from "utilities/messagesApp.util";
import { inputSearch } from "utilities/utilAccount/utilAccountInfo";
import { utilStatusRequest } from "utilities/utilStatusRequest/utilStatusRequest";
import { SpanErrorsStyled } from "views/Auth/styled-components/InputAuthForm.styled";
import { useSpecialFilterContext } from "views/Home/contexts/SpecialFilterProvider";
import { getInfoHome } from "views/Home/interceptors/getInfoHome.interceptor";
import {
  CardNameStyled,
  GenreNameStyled,
  WrapCardStyled,
} from "views/Home/pages/CardsSectionHome/styled-components/CardsSectionHome.styled";
import { InputSearchInSpecialFilter } from "../InputSearchInSpecialFilter/InputSearchInSpecialFilter";

export const SpecialFilterLocationForm = () => {
  const { coords } = useSelector((state) => state.geolocationReducer);

  const {
    handleLocationsSearch,
    handleType,
    showType,
    setMessageInformationToRender,
    clickToggleBtn,
    whatIs,
    informationToRender,
    setInformationToRender,
    setAllLocations,
    messageEmptyFilter,
    setMessageEmptyFilter,
  } = useSpecialFilterContext();

  const refSearchBtn = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (showType === "location") handleType("");

    const fetchData = async () => {
      try {
        refSearchBtn.current.value = "";
        setInformationToRender([]);
        setMessageEmptyFilter(null);
        // setLoadingData(true);

        const fetch = await getInfoHome({
          type: whatIs[clickToggleBtn],
          coords,
        });

        if (!fetch || !fetch.success) {
          fetch?.validationMessages?.map((messageError) =>
            console.error(
              utilStatusRequest({
                code: messageError.code,
                msgRequest: messageError.message,
              })
            )
          );

          throw new Error(
            utilStatusRequest({
              code: "X",
              msgRequest: messagesApp.problemsToSearchLocations,
            })
          );
        }

        setMessageInformationToRender(null);
        // setInformation(fetch.data.data);
        setInformationToRender(fetch.data.data);
        setAllLocations(fetch.data.data);
      } catch (error) {
        console.error("error", error.message);
        setMessageInformationToRender(error.message);
      } finally {
        // setLoadingData(false);
      }
    };

    if (clickToggleBtn === "locations") fetchData();

    return () => {
      setInformationToRender([]);
    };
  }, [clickToggleBtn]);

  const handleClickInCard = (idCard) => {
    navigate(`/profile/${clickToggleBtn}/${idCard}`);
  };

  return (
    <div className="special-filter-location-form col col-12">
      {inputSearch.map((input, index) => (
        <InputSearchInSpecialFilter
          key={index}
          input={input}
          change={handleLocationsSearch}
          ref={refSearchBtn}
        />
      ))}

      <WrapCardSectionStyled className="col col-12 p-0" from="home">
        {messageEmptyFilter ? (
          <SpanErrorsStyled>{messageEmptyFilter}</SpanErrorsStyled>
        ) : (
          informationToRender?.map((card, index) => (
            <WrapCardStyled
              // className="col col-5"
              key={index}
              onClick={() => handleClickInCard(card.id)}
            >
              <Card
                image={card.picture}
                altImg={`${card.name} location image`}
              />
              <CardNameStyled>{card.name}</CardNameStyled>
              <GenreNameStyled>{card.country}</GenreNameStyled>
            </WrapCardStyled>
          ))
        )}
      </WrapCardSectionStyled>
    </div>
  );
};
