import styled from "styled-components";
import { desktopButtonTexts } from "styled-components/App.styled";

const whoInputSearch = {
  home: {
    margin: "10px 0 1rem 0",
    "padding-top": "0",
  },
};

const WrapInputSearchStyled = styled.div`
  margin: ${({ from }) =>
    from ? whoInputSearch[from]["margin"] : "15px 0 0 0"};
  padding-top: ${({ from }) =>
    from ? whoInputSearch[from]["padding-top"] : "15px 0 0 0"};
`;

const IconSearchStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  position: relative;
  top: 32px;
  z-index: -1;

  font: ${desktopButtonTexts};
`;

export { WrapInputSearchStyled, IconSearchStyled };
