import { useCatchErrors } from "hooks/useCatchErrors";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { feedbackSuccess } from "redux/actions/feedbackAction";
// import { catchErrors } from "utilities/catchErrors.util";
import { loginFields, registerFields } from "utilities/utilAuth";
import { utilStatusRequest } from "utilities/utilStatusRequest/utilStatusRequest";
import { userSignUp } from "../interceptor";
import { useAuthenticate } from "./useAuthenticate";

export const useFormAuth = (location) => {
  const [loadingSignUp, setloadingSignUp] = useState(false);
  const { loginUser } = useAuthenticate();
  const { catchErrors } = useCatchErrors();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  const render = location === "login" ? loginFields : registerFields;
  const formButtonText = location === "login" ? "Entra" : "Siguiente";

  const onSubmit = async (data) => {
    if (location === "login") return loginUser(data);

    try {
      setloadingSignUp(true);
      const sendDataUser = await userSignUp(data);
      console.log({ sendDataUser });
      if (!sendDataUser.success) {
        catchErrors({
          info: sendDataUser,
          textDetail: "errorSignUp",
          where: "error submit - use form auth ",
        });
      }
      // show success msg ;
      dispatch(
        feedbackSuccess({
          variant: "success",
          text: utilStatusRequest({ code: "successCreateuser" }),
        })
      );
      navigate("/login", { replace: true });
    } catch (error) {
      // show error with msg;
      console.error("error submit - use form auth ", error.message);
    } finally {
      setloadingSignUp(false);
    }
  };

  useEffect(() => {
    reset();
    //eslint-disable-next-line
  }, [render]);

  return {
    render,
    formButtonText,
    register,
    handleSubmit,
    errors,
    onSubmit,
    getValues,
    loadingSignUp,
  };
};
