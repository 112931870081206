import { URLs } from "services/servicesUrls.services";
import { BEARERAUTH } from "utilities/authHeader.util";
import fetchAPI from "../../../services/fetchAPI.services";

export const updateGalleryImage = async (data, idProfile, restUrl) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: BEARERAUTH(),
    };
    
    // console.log(
    //   { idProfile, restUrl }, 'data -->>', data,
    //   `${URLs.profiles}/${idProfile}/${restUrl}`
    // );

    const fetchingData = await fetchAPI({
      method: "POST",
      url: `${URLs.profiles}/${idProfile}/${restUrl}`,
      headers,
      body: JSON.stringify(data),
    });
    // console.log({ fetchingData });
    return fetchingData;
  } catch (error) {
    console.log("error post updateGalleryImages interceptor", { error });
    return { success: false, code: "X" };
  }
};
