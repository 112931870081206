export const editOrCreateEventsButtons = [
  {
    name: "Editar eventos",
    value: "edit-events",
  },
  {
    name: "Crear eventos",
    value: "create-events",
  },
];
