import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";
import { BEARERAUTH } from "utilities/authHeader.util";

export const deleteLinkProfileMultimedia = async (
  idLink,
  idProfile,
  restUrl
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: BEARERAUTH(),
    };
    // console.log("deleteLinkProfileMultimedia -->>", { idLink, idProfile });
    const sendData = await fetchAPI({
      method: "DELETE",
      url: `${URLs.profiles}/${idProfile}/${restUrl}/${idLink}`,
      headers,
    });
    // console.log({ sendData });
    return sendData;
  } catch (error) {
    console.log("error delete Link Profile Multimedia interceptor", { error });
    return { success: false, code: "X" };
  }
};
