import { useCatchErrors } from "hooks/useCatchErrors";
import { createContext, useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAccount } from "redux/actions/accountAction";
import { feedbackSuccess } from "redux/actions/feedbackAction";
import { messagesApp } from "utilities/messagesApp.util";
import { inputsGroups } from "utilities/utilAccount/utilNewArtist";
import { validationSchemaAccount } from "utilities/utilAccount/utilValidationFields";
import { protectedLinks } from "utilities/utilHeader/utilHeaderNavLinks";
import { utilStatusRequest } from "utilities/utilStatusRequest/utilStatusRequest";
import { useFormAccount } from "views/account/hooks/useFormAccount";
import { setImageInProfileSection } from "views/account/interceptors";
import { deleteImageGallery } from "views/account/interceptors/deleteImageGallery.interceptor";
import { deleteLinkProfileMultimedia } from "views/account/interceptors/deleteLinkProfileMultimedia.interceptor";
import { patchLinkProfile } from "views/account/interceptors/patchLinkProfile.interceptor";
import { putDataForm } from "views/account/interceptors/putDataForm.interceptor";
import { updateGalleryImage } from "views/account/interceptors/updateGalleryImage.interceptor";

import { getProfileMultimedia } from "views/Home/interceptors/getProfileMultimedia.interceptor";

const EditArtistOrLocationContext = createContext();

const EditArtistOrLocationProvider = ({ children }) => {
  const { catchErrors } = useCatchErrors();
  const { user } = useSelector((state) => state.userReducer);
  const { account } = useSelector((state) => state.accountReducer);

  const searchParams = useSearchParams()[0];

  const idProfile = searchParams.get("id_profile");
  const profile = searchParams.get("type");

  const [data, setData] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  // link portada (cover)
  const [profileLinkCover, setProfileLinkCover] = useState([]);

  // link profile image
  const [linkProfilePicture, setLinkProfilePicture] = useState(null);

  // links platforms
  const [profileLinksSources, setProfileLinksSources] = useState([]);
  const [loadingLinksSources, setLoadingLinksSources] = useState(false);

  // numPhone
  const [phone, setPhone] = useState("");

  // refresh data by deleted gallery image
  const [refreshDataDeletedGalleryImage, setRefreshDataDeletedGalleryImage] =
    useState(false);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  // fetching data to artist or location selected
  const fetchData = async () => {
    try {
      setLoadingData(true);
      const getInfo = await getProfileMultimedia(idProfile);

      if (!getInfo || !getInfo.success) {
        getInfo.validationMessages?.map((error) =>
          console.error(error.code, " - ", error.message)
        );
        throw new Error(messagesApp.catchDetailsProfileMultimedia);
      }

      setData(getInfo.data);
    } catch (error) {
      console.error("error", error.message);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refreshDataDeletedGalleryImage) {
      fetchData();
      setRefreshDataDeletedGalleryImage(false);
    }

    // eslint-disable-next-line
  }, [refreshDataDeletedGalleryImage]);

  const defaultSchema =
    profile === "artists"
      ? {
          name: "",
          email: "",
          webSite: "",
          ubication: "",
        }
      : {
          name: "",
          email: "",
          webSite: "",
          ubication: "",
          capacity: "",
        };

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useFormAccount({ fields: inputsGroups, defaultSchema });

  useEffect(() => {
    if (profile === "artists") {
      if (data?.genres?.length > 0) {
        setValue("genre", data.genres[0].id);
      }
    } else {
      if (data.capacity >= 0 || data.capacity)
        setValue("capacity", data.capacity);
    }
    if (data.name) setValue("name", data.name);
    if (data.description) setValue("description", data.description);

    if (data?.links?.length > 0) {
      setProfileLinksSources(
        data.links.map((item) => ({
          source: item.source,
          typeId: item.typeId,
          url: item.url,
        }))
      );
    }

    if (data.email) setValue("email", data.email);
    if (data.webSite) setValue("webSite", data.webSite);

    if (data.phone) {
      setValue("phone", data.phone);
      setPhone(data.phone);
    }
  }, [data]);

  const handleLinkProfilePicture = (link) => {
    setLinkProfilePicture(link);
  };

  const handleLinkProfileLinkCover = (link) => {
    setProfileLinkCover(link);
  };

  const handlePhone = (num) => {
    setPhone(num);
  };

  const handleLoadingSubmit = (value) => setLoadingSubmit(value);

  const handleProfileLinksSources = (objWithLink) => {
    setLoadingLinksSources(true);

    let filterSource;
    if (!objWithLink) setProfileLinksSources([...profileLinksSources]);
    else {
      if (!objWithLink?.url) {
        filterSource = profileLinksSources.filter(
          (item) =>
            item.source.toLowerCase() !== objWithLink.source.toLowerCase()
        );

        setProfileLinksSources([...filterSource]);
      } else {
        filterSource = profileLinksSources.filter(
          (item) =>
            item.source.toLowerCase() !== objWithLink.source.toLowerCase()
        );
        setProfileLinksSources([...filterSource, objWithLink]);
      }
    }
    setTimeout(() => {
      setLoadingLinksSources(false);
    }, 1000);
  };

  const saveDataFile = async (file, input) => {
    try {
      if (!file) return false;

      let formdata = new FormData();
      formdata.append("image", file, file.name);

      const uploadPictureProfile = await setImageInProfileSection(formdata);

      if (!uploadPictureProfile.success)
        throw new Error(
          utilStatusRequest({
            code: "X",
            msgRequest: uploadPictureProfile.title,
          })
        );

      return { link: uploadPictureProfile.data.fileUrl, sourceGallery: input };
    } catch (error) {
      console.error(error.message);
    }
  };

  const manageDataForm = async ({
    dataSubmit,
    isPost = false,
    from = "save",
  }) => {
    try {
      handleLoadingSubmit(true);

      let city;
      let country;
      let state;
      let address;
      let latitude;
      let longitude;

      const dataPlace = dataSubmit.ubication.split("/");
      city = dataPlace[0];
      country = dataPlace[1];
      state = dataPlace[2];
      address = dataPlace[3];
      latitude = dataPlace[4];
      longitude = dataPlace[5];

      // links gallery
      let fetchLinksGallery = await Promise.all([
        await saveDataFile(
          dataSubmit?.galleryImage1[0],
          dataSubmit?.galleryImage1[0]?.name
        ),
        await saveDataFile(
          dataSubmit?.galleryImage2[0],
          dataSubmit?.galleryImage2[0]?.name
        ),
        await saveDataFile(
          dataSubmit?.galleryImage3[0],
          dataSubmit?.galleryImage3[0]?.name
        ),
        await saveDataFile(
          dataSubmit?.galleryImage4[0],
          dataSubmit?.galleryImage4[0]?.name
        ),
        await saveDataFile(
          dataSubmit?.galleryImage5[0],
          dataSubmit?.galleryImage5[0]?.name
        ),
        await saveDataFile(
          dataSubmit?.galleryImage6[0],
          dataSubmit?.galleryImage6[0]?.name
        ),
      ]);

      let i = 0;
      // observer and select images gallery. Define what to use
      function whatUrl(item) {
        let itemToReturn;

        if (item) {
          itemToReturn = item.link;
        } else {
          if (!data.gallery[i]) itemToReturn = false;
          else itemToReturn = data.gallery[i].url;
        }

        i++;
        return itemToReturn;
      }

      const arrayLinksGallery = fetchLinksGallery.map((item) => whatUrl(item));

      let idImageToDelete = [];
      let arrayImagesToUp = [];
      let j = 0;
      arrayLinksGallery.map((item) => {
        if (!item) {
          if (data.gallery[j] && data.gallery[j].id) {
            arrayImagesToUp = [
              ...arrayImagesToUp,
              { url: [data.gallery[j].url] },
            ];
            idImageToDelete = [
              ...idImageToDelete,
              { url: [data.gallery[j].url], id: data.gallery[j].id },
            ];
          }
        } else {
          if (data.gallery[j] && data.gallery[j]?.id) {
            idImageToDelete = [
              ...idImageToDelete,
              { url: [data.gallery[j].url], id: data.gallery[j].id },
            ];
          }
          arrayImagesToUp = [...arrayImagesToUp, { url: [item] }];
        }
        j = j + 1;
        return true;
      });

      let errorDeleteImage = false;

      // let deleteImg = await Promise.all(
      await Promise.all(
        idImageToDelete.map(async (imageToDelete) => {
          // delete image to replace
          const deleteImage = await deleteImageGallery(
            idProfile,
            imageToDelete.id
          );

          if (!deleteImage || !deleteImage.success) {
            errorDeleteImage = catchErrors({
              info: deleteImage,
              textDetail: "errorDeleteImage",
              where: "whatUrl deleteImage - edit artist or location provider",
              withTry: false,
            });
          }
          return deleteImage.success;
        })
      );

      // if errorDeleteImage is true, then do update
      if (!errorDeleteImage) {
        await Promise.all(
          arrayImagesToUp.map(async (imageToUp) => {
            // up new image in gallery
            const editGallery = await updateGalleryImage(
              imageToUp,
              idProfile,
              "Image"
            );

            if (!editGallery || !editGallery.success) {
              catchErrors({
                info: editGallery,
                textDetail: "errorUpdateGallery",
                where: "whatUrl editGallery - edit artist or location provider",
                withTry: false,
              });
            }
            return editGallery.success;
          })
        );
      }

      const parseGenre = parseInt(dataSubmit.genre);

      let dataToSend = {
        name: dataSubmit.name,
        email: dataSubmit.email,
        phone,
        description: dataSubmit.description,
        address,
        webSite: dataSubmit.webSite,
        city,
        country,
        state,
        latitude,
        longitude,
        certified: false,
        createdAt: data.createdAt,
        active: data.active,
        type: profile === "artists" ? 1 : 2,
      };

      if (profileLinkCover && profileLinkCover.length > 0)
        dataToSend.cover = profileLinkCover;
      else if (data.cover) dataToSend.cover = data.cover;

      if (linkProfilePicture && linkProfilePicture.length > 0)
        dataToSend.picture = linkProfilePicture;
      else if (data.picture) dataToSend.picture = data.picture;

      if (profile === "artists") {
        dataToSend.genres = [parseGenre];
        dataToSend.bio = dataSubmit.description;
      } else {
        dataToSend.description = dataSubmit.description;
        dataToSend.capacity = dataSubmit.capacity;
      }

      const sendData = await putDataForm(
        dataToSend,
        account.id,
        "Profiles",
        idProfile
      );

      if (!sendData || !sendData.success) {
        catchErrors({
          info: sendData,
          textDetail: "errorUpdateArtistOrLocation",
          where: "onSubmitSave - edit artist or location provider",
        });
      }

      // delete Links
      let errorDeleteProfileLinks = false;
      let deleteProfileLinks;
      if (data.links.length > 0) {
        deleteProfileLinks = await Promise.all(
          await data.links.map(
            async (link) =>
              await deleteLinkProfileMultimedia(link.id, idProfile, "Link")
          )
        );

        // check errors in delete profile links
        const checkExistErrorDelete = !deleteProfileLinks
          ? undefined
          : deleteProfileLinks.find((item) => item.success === false);

        if (checkExistErrorDelete && !checkExistErrorDelete.success) {
          deleteProfileLinks?.ValidationMessages?.map((error) =>
            console.error(error.code, " - ", error.message)
          );
          console.error(messagesApp.catchDeleteLinkProfile);
          dispatch(
            feedbackSuccess({
              variant: "danger",
              text: messagesApp.catchDeleteLinkProfile,
            })
          );
          errorDeleteProfileLinks = true;
        }
      }

      // update Links
      let updateProfileLinks;
      if (!errorDeleteProfileLinks) {
        if (profileLinksSources.length > 0) {
          updateProfileLinks = await Promise.all(
            await profileLinksSources.map(
              async (link) => await patchLinkProfile(link, idProfile, "Link")
            )
          );
        }

        // check errors in update
        const checkExistErrorUpdate = !updateProfileLinks
          ? undefined
          : updateProfileLinks.find((item) => item.success === false);

        if (checkExistErrorUpdate && !checkExistErrorUpdate.success) {
          checkExistErrorUpdate?.ValidationMessages?.map((error) =>
            console.error(error.Code, " - ", error.Message)
          );
          console.error(messagesApp.catchUpdateLinkProfile);
          dispatch(
            feedbackSuccess({
              variant: "danger",
              text: messagesApp.catchUpdateLinkProfile,
            })
          );
        }
      }

      // console.log("ENVIANDING", {
      //   dataToSend,
      //   sendData,
      //   deleteProfileLinks,
      //   updateProfileLinks,
      // });

      dispatch(getAccount(user.id, undefined, "Successful edit operation"));

      if (isPost) return { success: true };

      if (from === "public") return { success: false };

      // when use dashboard in 'protectedLinks' options, then use protectedLinks[1].path
      // navigate(`${protectedLinks[1].path}/${account.id}`, { replace: true });
      dispatch(
        feedbackSuccess({
          variant: "success",
          text: messagesApp.updateDataSuccessfuly,
        })
      );
      navigate(`${protectedLinks[0].path}/${account.id}`, { replace: true });
    } catch (error) {
      console.error(
        "error al editar artista o locación - manageDataForm - edit artist or location provider",
        error.message
      );
    } finally {
      handleLoadingSubmit(false);
    }
  };

  const onSubmitSave = async (dataSubmit) => {
    await manageDataForm({ dataSubmit });
  };

  const onSubmitPublic = async (dataSubmit, e) => {
    try {
      const setData = await manageDataForm({
        dataSubmit,
        isPost: true,
        from: "public",
      });

      if (!setData || !setData.success) {
        throw new Error(messagesApp.catchUpdateArtistOrLocation);
      }

      dispatch(
        feedbackSuccess({
          variant: "success",
          text: messagesApp.updateDataSuccessfulyAndGoPayment,
        })
      );

      // when use dashboard in 'protectedLinks' options, then use protectedLinks[1].path
      navigate(
        `${protectedLinks[0].path}/${user.id}/payments-section?pi=${idProfile}&ai=${account.id}&type=${profile}`,
        { replace: true }
      );
    } catch (error) {
      console.error(
        "error onSubmitPublic - edit artist or location provider",
        error.message
      );
      dispatch(
        feedbackSuccess({
          variant: "danger",
          text: error.message,
        })
      );
    }
  };

  return (
    <EditArtistOrLocationContext.Provider
      value={{
        register,
        handleSubmit,
        errors,
        watch,
        handleProfileLinksSources,
        onSubmitSave,
        onSubmitPublic,
        validationSchemaAccount,
        handleLinkProfilePicture,
        setValue,
        clearErrors,
        handleLinkProfileLinkCover,
        loadingSubmit,
        handlePhone,
        phone,
        setError,
        profileLinksSources,
        data,
        loadingData,
        profile,
        loadingLinksSources,
        setRefreshDataDeletedGalleryImage,
        catchErrors,
        idProfile,
        setLoadingSubmit,
        dispatch,
      }}
    >
      {children}
    </EditArtistOrLocationContext.Provider>
  );
};

const useEditArtistOrLocationContext = () => {
  const context = useContext(EditArtistOrLocationContext);
  if (context === undefined)
    throw new Error(
      "Edit Artist Or Location context must be used within a Edit Artist Or Location provider"
    );

  return context;
};

export { useEditArtistOrLocationContext, EditArtistOrLocationProvider };
