import { homeLinks } from "utilities/utilHome/utilHomeNavLnk";
// import { useToggleBtnGtoup } from "../hooks/useToggleBtnGtoup";
import { ToggleButtonStyled } from "../styled-components/ToggleBtnGroup.styled";

export const ToggleBtnGroup = ({ handleToggleBtnClick, clickToggleBtn }) => {
  // const { isDisabled } = useToggleBtnGtoup();

  return (
    <div>
      <div className="col col-12 btn-group" role="group">
        {homeLinks.map((elements, idx) => (
          <ToggleButtonStyled
            key={idx}
            className={`col col-12${idx !== 0 && "ms-2"}`}
            id={`btns-actions-${idx}`}
            type="radio"
            name="btns"
            onClick={() => handleToggleBtnClick(elements.value)}
            data-toggle="toggle"
            checked={clickToggleBtn}
            nameButton={elements.value}
            // disabled={isDisabled(elements.value)}
          >
            {elements.name}
          </ToggleButtonStyled>
        ))}
      </div>
    </div>
  );
};
