import { useEffect, useState } from "react";
import { utilStatusRequest } from "utilities/utilStatusRequest/utilStatusRequest";
import { adaptGenres } from "views/account/adapters/genres.adapter";
import { getGenres } from "views/account/interceptors";
import { useNewArtistContext } from "../contexts/NewArtistProvider";

export const useGenresSelectButton = () => {
  const { register, errors } = useNewArtistContext();

  const [genres, setGenres] = useState([]);
  const [loadingGenres, setLoadingGenres] = useState(false);

  const fetchGenres = async () => {
    try {
      setLoadingGenres(true);
      const fetchGenres = await getGenres();

      if (!fetchGenres.success || !fetchGenres)
        throw new Error(utilStatusRequest({ code: "400" }));

      setGenres(adaptGenres(fetchGenres.data));
    } catch (error) {
      console.log("error fetchGenres", { error });
      console.error(error.message);
    } finally {
      setLoadingGenres(false);
    }
  };

  useEffect(() => {
    fetchGenres();
  }, []);

  return { register, errors, genres, loadingGenres };
};
