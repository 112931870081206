import { TYPES } from "../types/types";

const initialGeolocationState = {
  loaded: false,
  coords: { lat: "", lng: "" },
  error: false,
};

const { GEOLOCATION_SUCCESS, GEOLOCATION_FAILURE } = TYPES;

export const geolocationReducer = (state = initialGeolocationState, action) => {
  switch (action.type) {
    case GEOLOCATION_SUCCESS:
      return {
        ...initialGeolocationState,
        loaded: true,
        coords: {
          lat: action.payload.lat,
          lng: action.payload.lng,
        },
      };

    case GEOLOCATION_FAILURE:
      return {
        ...initialGeolocationState,
        error: action.payload,
      };

    default:
      return state;
  }
};
