import { TYPES } from "redux/types/types";

export const geolocationSuccess = (data) => ({
  type: TYPES.GEOLOCATION_SUCCESS,
  payload: data,
});

export const geolocationFailure = (error) => ({
  type: TYPES.GEOLOCATION_FAILURE,
  payload: error,
});
