import styled from 'styled-components';

const WrapPlatformStyled = styled.div`
	margin-top: 10px;
`;

const WrapPlatformButtonsStyled = styled.div`
	display: flex;
	justify-content: start;
	padding: 10px 0 5px 0;
	flex-wrap: wrap;
`;

const WrapIconsAndAddStyled = styled.span`
	position: relative;
`;

const leftPositionToItem = {
	first: '62%',
	last: '78%',
};

const defineLeftPosition = (size, index) =>
	size === index ? leftPositionToItem.last : leftPositionToItem.first;

const AddIconStyled = styled.div`
	position: absolute;
	bottom: 72%;
	left: ${({ size, index }) => defineLeftPosition(size, index)};
	z-index: 2;
`;

export {
	WrapPlatformStyled,
	WrapPlatformButtonsStyled,
	WrapIconsAndAddStyled,
	AddIconStyled,
};
