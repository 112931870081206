import styled from 'styled-components';
import {
	defaultColors,
	hoverEffectFieldsForm,
} from 'styled-components/App.styled';
// import {
//   defaultColors,
//   hoverEffectFieldsForm,
// } from "../../../../styled-components/App.styled";

const WrapImagesGroupFormStyled = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	padding: 20px 0;
	max-width: 680px;
`;

const alignItems = {
	1: 'start',
	2: 'center',
	3: 'end',
};

const WrapImageAndErrorMsgStyled = styled.span`
	display: flex;
	align-items: ${(index) => alignItems[index]};
	flex-direction: column;
`;

const BoxAddImageFormStyled = styled.div`
	// position: relative;
	width: 208px;
	height: 208px;
	background: ${defaultColors.somethingTransparentWhite};
	border: 1px solid ${defaultColors.white};
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 5px;

	&:hover {
		${hoverEffectFieldsForm};
	}
`;

export {
	WrapImagesGroupFormStyled,
	BoxAddImageFormStyled,
	WrapImageAndErrorMsgStyled,
};
