export const messagesApp = {
	addAmountDays: 'Agregar cantidad de días',
	addEvent: 'Agregar evento',
	addOrEditEvents: 'Agregar/Editar Eventos',
	addPaymentMethodToUse: 'Agregá método de pago',
	amountDaysValueOneDollar: 'Cantidad de días (valor $1 / Dia)',
	areSureDeleteProfileSelected:
		'Estás seguro de eliminar el perfil seleccionado',
	areSureDeleteConfirmPlease: 'Estás seguro de eliminar lo seleccionado?',
	atRateOf: ' Valor apagar ',
	backToPaymentSection: 'Volver a lasección de pagos',
	cancelText: 'Cancelar',
	catchDeleteEvents: 'Ocurrió un problema al eliminar los eventos',
	catchDeleteLinkProfile:
		'Ocurrió un problema al eliminar los links del perfil',
	catchDetailsProfileMultimedia:
		'Ocurrió un problema al recuperar información de artistas o locaciones',
	catchFilter: 'Ocurrió un problema al filtrar artistas o locaciones',
	catchFilterGenreOrCities: 'Ocurrió un problema al filtrar géneros o ciudades',
	catchNewArtist: 'Ocurrió un problema al guardar los datos del artista',
	catchNewLocation: 'Ocurrió un problema al guardar los datos de la locación',
	catchUpdateArtistOrLocation:
		'Ocurrió un problema al guardar los datos del artista o locación',
	catchPaymentSection: 'Tuvimos problemas para realizar el pago',
	catchSearchGenreOrCities: 'Ocurrió un problema al buscar géneros o ciudades',
	catchspecialFilter: 'No encontramos lo que estás buscando',
	catchUpdateLinkProfile:
		'Ocurrió un problema al actualizar los links del perfil',
	completeAllFields: 'Debes completar todos los campos',
	confirmActionText: 'Confirmar acción',
	confirmPaymentText: 'Confirmar pago',
	confirmText: 'Confirmar',
	daysText: 'días',
	deleteAllEventsSuccessfuly: 'Todos los eventos se eliminaron correctamente',
	deleteAllGallerySuccessfuly: 'La galería se eliminó correctamente',
	deleteGalleryImage: 'Eliminar imagen de galería',
	deleteImgSelectedGallerySuccessfuly:
		'Lo seleccionado en galería se eliminó correctamente',
	deleteEventSuccessfuly: 'El evento se eliminó correctamente',
	deleteProfileSuccesfull: 'Perfil eliminado con éxito',
	didYouSearch: 'Buscaste',
	dontHavePaymentMethod: 'Aún no tienes un método de pago',
	editText: 'Editar',
	emptyFilter: 'No encontramos lo que buscaste',
	enterNumberEqualToOrGreaterOne: 'Debes ingresar un número igual o mayor a 1',
	enterValidCapacity:
		'Debes agregar una capacidad válida. Debe ser un número mayor a 0',
	enterValidEmail: 'Ingresar un email válido.',
	enterValidNumber: 'Ingresar un número válido.',
	eventText: 'Evento',
	expirationMonth: 'Mes expiración',
	expirationYear: 'Año expiración',
	dateText: 'Fecha',
	descriptionText: 'Descripción',
	fieldIsRequired: 'El campo es requerido',
	galleryEmpty: 'Esta galería aún está vacía',
	geoLocationNotSupport: 'Geolocalización no soportada',
	imageCannotExceed: 'The image cannot exceed 2MB',
	imageFormatPermited: 'Format image permited png/jpg/jpeg',
	morInfoHere: 'Más info aquí',
	mustContainOneLetterAndNumber: 'Debe contener al menos una letra y un número',
	mustContainBetween: 'Debe contener entre 6 y 20 caracteres',
	mustSelectNumberDaysWantToAdd:
		'Debes seleccionar la cantidad de días que quieres agregar',
	mustSelectPaymentMethod: 'Debes seleccionar un método de pago',
	numberText: 'Número',
	operationSuccefull: 'Operación raelizada con éxito',
	platformEmpty: 'Aún no añadiste estas plataformas',
	passwordsMustMatch: 'Las contraseñas deben coincidir',
	pay: 'Pagar',
	paymentMethodText: 'Seleccionar tarjeta',
	postText: 'Publicar',
	problemsToSearchArtistOrLocations:
		'Ocurrió un problema al buscar artistas o locaciones',
	problemsToSearchLocations: 'Ocurrió un problema al buscar locaciones',
	profileExpiredAt: '',
	returnFormEditArtist: 'Volver a Formulario',
	saveLocationDataSuccessfuly: 'Datos de la locación guardados con éxito',
	saveDataSuccessfuly: 'Datos guardados con éxito',
	saveDataSuccessfulyAndGoPayment:
		'Se guardaron los datos correctamente, procede con el pago para que sean publicados',
	saveText: 'Guardar',
	search: 'Buscar',
	selectImagesToDelete: 'Selecciona las imagenes a eliminar',
	selectPaymentMethod: 'Selecciona el medio para pagar',
	successCreateEvent: 'Evento creado con éxito',
	successfullPayment: 'Pago realizado correctamente',
	successfullPost: 'Publicación exitosa',
	searchLocation: 'Buscar una ubicación',
	sureWantToMakePayment: '¿Estás seguro que deseas realizar el pago?',
	sureWantToRemoveAllImagesGallery:
		'¿Estás seguro de eliminar todos las imagenes de la gelería?',
	thereAreProblems: 'Ocurrió un problema. Comunicate con el administrador.',
	typeText: 'Tipo',
	unknownError: 'Error desconocido - chequear validationMessages',
	updateDataSuccessfuly: 'Se actualizaron los datos correctamente',
	updateDataSuccessfulyAndGoPayment:
		'Se actualizaron los datos correctamente, procede con el pago para que sean publicados',
	urlText: 'URL',
	validateURLMessage:
		"La URL debe comenzar con https:// y contener por ejemplo '.com'",
	youAreAdd: 'Usted está agregando',
	youSelected: 'Seleccionaste',
};
