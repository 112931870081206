const { REACT_APP_API_URL: BASEURL } = process.env;

export const URLs = {
  accounts: BASEURL + "/Accounts",
  authenticate: BASEURL + "/Authenticate",
  forgotPassword: BASEURL + "/ForgotPassword",
  genres: BASEURL + "/Genres",
  image: BASEURL + "/Image/Upload",
  imageMultiple: BASEURL + "/Image/Upload/Multiple",
  payments: BASEURL + "/Payments",
  paymentMethods: BASEURL + "/PaymentMethods",
  profiles: BASEURL + "/Profiles",
  users: BASEURL + "/Users",
};
