import { useGooglePlaceAutocomplete } from "views/account/hooks/useGooglePlaceAutocomplete";
import { InputAutocompleteStyled } from "views/account/styled-components";

export const AutoComplete = ({ placeholder, setValue, clearErrors, data }) => {
  const { inputRef, handleChangeValue } = useGooglePlaceAutocomplete({
    setValue,
    clearErrors,
    data
  });

  return (
    <InputAutocompleteStyled
      className="col col-12"
      ref={inputRef}
      onChange={handleChangeValue}
      placeholder={placeholder}
      data={data}
    />
  );
};

