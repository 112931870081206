import { SpinnerLoadInputs } from "components/SpinnerLoad/SpinnerLoadInputs/SpinnerLoadInputs";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { feedbackSuccess } from "redux/actions/feedbackAction";
import { messagesApp } from "utilities/messagesApp.util";
import { deleteImageGallery } from "views/account/interceptors/deleteImageGallery.interceptor";

export const ModalConfirmDeleteSelectImagesGallery = ({
  show,
  onHide,
  gallery,
  setRefreshDataDeletedGalleryImage,
  catchErrors,
  idProfile,
}) => {
  const { register, handleSubmit, watch, reset } = useForm();

  const [confirmDeleteText, setConfirmDeleteText] = useState("");
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    reset();
    setConfirmDeleteText("");
    onHide();
  };

  useEffect(() => {
    const subscription = watch((value) => {
      if (!Object.values(value).every((val) => val === false))
        setDisabledSubmit(false);
      else {
        setDisabledSubmit(true);
        setConfirmDeleteText("");
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => {
    if (!confirmDeleteText)
      return setConfirmDeleteText(messagesApp.areSureDeleteConfirmPlease);

    setConfirmDeleteText("");
    handlePressConfirm(data);
  };

  const handlePressConfirm = async (data) => {
    try {
      setLoadingDelete(true);

      const arrayToDelete = Object.values(data).filter(
        (item) => item !== false
      );

      await Promise.all(
        arrayToDelete.map(async (imageToDelete) => {
          // delete image
          const deleteImage = await deleteImageGallery(
            idProfile,
            imageToDelete
          );

          if (!deleteImage || !deleteImage.success) {
            catchErrors({
              info: deleteImage,
              textDetail: "errorDeleteGalleryImage",
              where:
                "handlePressConfirm - Modal Confirm Delete Select Images Gallery",
            });
          }
          return deleteImage.success;
        })
      );

      dispatch(
        feedbackSuccess({
          variant: "success",
          text: messagesApp.deleteImgSelectedGallerySuccessfuly,
        })
      );

      setRefreshDataDeletedGalleryImage(true);
      reset();
      onHide();
    } catch (error) {
      console.error(
        "handlePressConfirm - Modal Confirm Delete Select Images Gallery",
        error.message
      );
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => handleCloseModal()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: "black" }}
        >
          {messagesApp.deleteGalleryImage}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ color: "white", backgroundColor: "black" }}>
        <div>{messagesApp.selectImagesToDelete}</div>
        <Form
          className="col col-12 d-flex justify-content-center align-items-center flex-wrap"
          onSubmit={handleSubmit(onSubmit)}
        >
          {gallery &&
            gallery.length > 0 &&
            gallery?.map((image, index) => (
              <div
                key={index}
                className="col col-4 d-flex flex-column justify-content-center align-items-center"
              >
                <div className="form-check">
                  <input
                    className="form-check-input m-3"
                    type="checkbox"
                    value={image.id}
                    {...register(`${index}-image`)}
                    style={{
                      backgroundImage: "url(" + image.url + ")",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "top center",
                      height: "150px",
                      width: "150px",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              </div>
            ))}

          {confirmDeleteText && (
            <div className="col col-12 d-flex justify-content-center align-items-center mt-3">
              {confirmDeleteText}
            </div>
          )}
          <div className="col col-12 d-flex flex-nowrap justify-content-around mt-3">
            <Button
              className="col col-4 d-flex align-items-center justify-content-evenly"
              type="submit"
              disabled={disabledSubmit}
            >
              {messagesApp.confirmText}
              {loadingDelete && <SpinnerLoadInputs />}
            </Button>
            <div
              className="col col-4 btn btn-danger"
              variant="danger"
              onClick={() => handleCloseModal()}
            >
              {messagesApp.cancelText}
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
