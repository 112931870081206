const homeLinks = [
	{
		name: 'Artistas',
		value: 'artists',
		path: 'artists',
	},
	{
		name: 'Locaciones',
		value: 'locations',
		path: 'locations',
	},
];

export { homeLinks };
