const iconUser = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
      fill="#8ECCCC"
    />
  </svg>
);

const checkImg = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.465 20.6408C39.7748 20.2698 39.7748 19.7302 39.465 19.3592L35.8283 15.0028C35.6588 14.7997 35.5766 14.5378 35.5998 14.2743L36.1061 8.51762C36.1496 8.0234 35.8241 7.57203 35.3415 7.45717L30.0432 6.19638C29.7722 6.1319 29.5404 5.9573 29.4036 5.71465L26.6303 0.79553C26.3763 0.344983 25.8207 0.162475 25.349 0.374668L20.4103 2.59639C20.1493 2.71377 19.8507 2.71377 19.5897 2.59639L14.6499 0.374164C14.1787 0.162182 13.6236 0.3441 13.3692 0.79386L10.5973 5.69516C10.4599 5.93806 10.2273 6.1125 9.95569 6.17636L4.6612 7.42089C4.1773 7.53463 3.85042 7.98655 3.89385 8.48173L4.40038 14.2564C4.42343 14.5192 4.3417 14.7805 4.173 14.9833L0.532898 19.3594C0.224199 19.7305 0.224672 20.2691 0.534024 20.6397L4.17178 24.9973C4.34123 25.2003 4.42339 25.4622 4.40029 25.7256L3.89364 31.5016C3.8503 31.9958 4.17575 32.447 4.65832 32.5618L9.95756 33.8228C10.2281 33.8872 10.4596 34.0614 10.5965 34.3034L13.3681 39.2041C13.6229 39.6547 14.1795 39.8363 14.6511 39.6227L19.5888 37.3862C19.8502 37.2678 20.1499 37.2675 20.4117 37.3852L25.3501 39.6068C25.8213 39.8188 26.3764 39.6369 26.6308 39.1871L29.4035 34.2844C29.5404 34.0423 29.7719 33.8682 30.0424 33.8038L35.3415 32.5428C35.8241 32.428 36.1496 31.9766 36.1061 31.4824L35.5998 25.7257C35.5766 25.4622 35.6588 25.2003 35.8283 24.9972L39.465 20.6408Z"
      fill="white"
    />
    <path
      d="M39.465 20.6408C39.7748 20.2698 39.7748 19.7302 39.465 19.3592L35.8283 15.0028C35.6588 14.7997 35.5766 14.5378 35.5998 14.2743L36.1061 8.51762C36.1496 8.0234 35.8241 7.57203 35.3415 7.45717L30.0432 6.19638C29.7722 6.1319 29.5404 5.9573 29.4036 5.71465L26.6303 0.79553C26.3763 0.344983 25.8207 0.162475 25.349 0.374668L20.4103 2.59639C20.1493 2.71377 19.8507 2.71377 19.5897 2.59639L14.6499 0.374164C14.1787 0.162182 13.6236 0.3441 13.3692 0.79386L10.5973 5.69516C10.4599 5.93806 10.2273 6.1125 9.95569 6.17636L4.6612 7.42089C4.1773 7.53463 3.85042 7.98655 3.89385 8.48173L4.40038 14.2564C4.42343 14.5192 4.3417 14.7805 4.173 14.9833L0.532898 19.3594C0.224199 19.7305 0.224672 20.2691 0.534024 20.6397L4.17178 24.9973C4.34123 25.2003 4.42339 25.4622 4.40029 25.7256L3.89364 31.5016C3.8503 31.9958 4.17575 32.447 4.65832 32.5618L9.95756 33.8228C10.2281 33.8872 10.4596 34.0614 10.5965 34.3034L13.3681 39.2041C13.6229 39.6547 14.1795 39.8363 14.6511 39.6227L19.5888 37.3862C19.8502 37.2678 20.1499 37.2675 20.4117 37.3852L25.3501 39.6068C25.8213 39.8188 26.3764 39.6369 26.6308 39.1871L29.4035 34.2844C29.5404 34.0423 29.7719 33.8682 30.0424 33.8038L35.3415 32.5428C35.8241 32.428 36.1496 31.9766 36.1061 31.4824L35.5998 25.7257C35.5766 25.4622 35.6588 25.2003 35.8283 24.9972L39.465 20.6408ZM17.2515 28.2297C16.8574 28.6437 16.1971 28.6437 15.803 28.2297L10.2755 22.4238C9.9075 22.0372 9.9079 21.4298 10.2764 21.0438L11.5842 19.6737C11.9787 19.2604 12.6387 19.2611 13.0324 19.6753L15.8028 22.5901C16.1969 23.0047 16.8578 23.0049 17.2521 22.5904L26.4403 12.9318C26.834 12.5179 27.4938 12.5173 27.8882 12.9305L29.1964 14.3009C29.5648 14.687 29.5653 15.2944 29.1973 15.6809L17.2515 28.2297Z"
      fill="#4BCDF7"
    />
  </svg>
);

const addIcon = ({ width, height }) => (
  <svg
    width={`${width}`}
    height={`${height}`}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.8 0V4.8H0V7.2H4.8V12H7.2V7.2H12V4.8H7.2V0H4.8Z" fill="white" />
  </svg>
);

const cameraIcon = (
  <svg
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4444 0L9.19111 3.55556H3.55556C1.6 3.55556 0 5.15556 0 7.11111V28.4444C0 30.4 1.6 32 3.55556 32H32C33.9556 32 35.5556 30.4 35.5556 28.4444V7.11111C35.5556 5.15556 33.9556 3.55556 32 3.55556H26.3644L23.1111 0H12.4444ZM17.7778 26.6667C12.8711 26.6667 8.88889 22.6844 8.88889 17.7778C8.88889 12.8711 12.8711 8.88889 17.7778 8.88889C22.6844 8.88889 26.6667 12.8711 26.6667 17.7778C26.6667 22.6844 22.6844 26.6667 17.7778 26.6667Z"
      fill="white"
    />
  </svg>
);

const pencilIcon = (
  <svg
    width="25"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.12576 15.5H0.5V12.8742L9.82975 3.54449L12.4555 6.17025L3.12576 15.5ZM15.3865 2.69323C15.5378 2.84458 15.5378 3.08792 15.3865 3.23928L14.1136 4.51217L11.4878 1.8864L12.7607 0.613517C12.9121 0.462161 13.1554 0.462161 13.3068 0.613517L15.3865 2.69323Z"
      stroke="white"
    />
  </svg>
);

const locationOnIcon = (
  <svg
    width="35"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2Z"
      fill="white"
    />
    <path
      d="M12 11.5C13.3807 11.5 14.5 10.3807 14.5 9C14.5 7.61929 13.3807 6.5 12 6.5C10.6193 6.5 9.5 7.61929 9.5 9C9.5 10.3807 10.6193 11.5 12 11.5Z"
      fill="#50717B"
    />
  </svg>
);

const peopleIcon = (
  <svg
    width="35"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 11C17.66 11 18.99 9.66 18.99 8C18.99 6.34 17.66 5 16 5C14.34 5 13 6.34 13 8C13 9.66 14.34 11 16 11ZM8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V18C1 18.55 1.45 19 2 19H14C14.55 19 15 18.55 15 18V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C15.05 13.06 15.06 13.08 15.07 13.09C16.21 13.92 17 15.03 17 16.5V18C17 18.35 16.93 18.69 16.82 19H22C22.55 19 23 18.55 23 18V16.5C23 14.17 18.33 13 16 13Z"
      fill="white"
    />
  </svg>
);

const searchIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
      fill="white"
    />
  </svg>
);

const listIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 13C4.55 13 5 12.55 5 12C5 11.45 4.55 11 4 11C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM4 17C4.55 17 5 16.55 5 16C5 15.45 4.55 15 4 15C3.45 15 3 15.45 3 16C3 16.55 3.45 17 4 17ZM4 9C4.55 9 5 8.55 5 8C5 7.45 4.55 7 4 7C3.45 7 3 7.45 3 8C3 8.55 3.45 9 4 9ZM8 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H8C7.45 11 7 11.45 7 12C7 12.55 7.45 13 8 13ZM8 17H20C20.55 17 21 16.55 21 16C21 15.45 20.55 15 20 15H8C7.45 15 7 15.45 7 16C7 16.55 7.45 17 8 17ZM7 8C7 8.55 7.45 9 8 9H20C20.55 9 21 8.55 21 8C21 7.45 20.55 7 20 7H8C7.45 7 7 7.45 7 8ZM4 13C4.55 13 5 12.55 5 12C5 11.45 4.55 11 4 11C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM4 17C4.55 17 5 16.55 5 16C5 15.45 4.55 15 4 15C3.45 15 3 15.45 3 16C3 16.55 3.45 17 4 17ZM4 9C4.55 9 5 8.55 5 8C5 7.45 4.55 7 4 7C3.45 7 3 7.45 3 8C3 8.55 3.45 9 4 9ZM8 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H8C7.45 11 7 11.45 7 12C7 12.55 7.45 13 8 13ZM8 17H20C20.55 17 21 16.55 21 16C21 15.45 20.55 15 20 15H8C7.45 15 7 15.45 7 16C7 16.55 7.45 17 8 17ZM7 8C7 8.55 7.45 9 8 9H20C20.55 9 21 8.55 21 8C21 7.45 20.55 7 20 7H8C7.45 7 7 7.45 7 8Z"
      fill="white"
    />
  </svg>
);

const filterIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.25018 5.61C6.57018 8.59 10.0002 13 10.0002 13V18C10.0002 19.1 10.9002 20 12.0002 20C13.1002 20 14.0002 19.1 14.0002 18V13C14.0002 13 17.4302 8.59 19.7502 5.61C20.2602 4.95 19.7902 4 18.9502 4H5.04018C4.21018 4 3.74018 4.95 4.25018 5.61Z"
      fill="white"
    />
  </svg>
);

const closeIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z"
      fill="white"
    />
  </svg>
);

const QrIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 11H11V3H3V11ZM5 5H9V9H5V5Z" fill="white" />
    <path d="M3 21H11V13H3V21ZM5 15H9V19H5V15Z" fill="white" />
    <path d="M13 3V11H21V3H13ZM19 9H15V5H19V9Z" fill="white" />
    <path d="M21 19H19V21H21V19Z" fill="white" />
    <path d="M15 13H13V15H15V13Z" fill="white" />
    <path d="M17 15H15V17H17V15Z" fill="white" />
    <path d="M15 17H13V19H15V17Z" fill="white" />
    <path d="M17 19H15V21H17V19Z" fill="white" />
    <path d="M19 17H17V19H19V17Z" fill="white" />
    <path d="M19 13H17V15H19V13Z" fill="white" />
    <path d="M21 15H19V17H21V15Z" fill="white" />
  </svg>
);

const iconMusicNote = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6 0H7.2C5.88 0 4.8 1.08 4.8 2.4V16.8C4.8 18.12 5.88 19.2 7.2 19.2H21.6C22.92 19.2 24 18.12 24 16.8V2.4C24 1.08 22.92 0 21.6 0ZM19.2 6H15.6V12.6C15.6 14.256 14.256 15.6 12.6 15.6C10.944 15.6 9.6 14.256 9.6 12.6C9.6 10.944 10.944 9.6 12.6 9.6C13.284 9.6 13.896 9.828 14.4 10.212V3.6H19.2V6ZM2.4 4.8H0V21.6C0 22.92 1.08 24 2.4 24H19.2V21.6H2.4V4.8Z"
      fill="white"
    />
  </svg>
);

const avancedFilterIcon = (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 12H10C10.55 12 11 11.55 11 11C11 10.45 10.55 10 10 10H8C7.45 10 7 10.45 7 11C7 11.55 7.45 12 8 12ZM0 1C0 1.55 0.45 2 1 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H1C0.45 0 0 0.45 0 1ZM4 7H14C14.55 7 15 6.55 15 6C15 5.45 14.55 5 14 5H4C3.45 5 3 5.45 3 6C3 6.55 3.45 7 4 7Z"
      fill="white"
    />
  </svg>
);

const menuMobileIcon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1249_954)">
      <path
        d="M15.9999 10.6673C17.4666 10.6673 18.6666 9.46732 18.6666 8.00065C18.6666 6.53398 17.4666 5.33398 15.9999 5.33398C14.5333 5.33398 13.3333 6.53398 13.3333 8.00065C13.3333 9.46732 14.5333 10.6673 15.9999 10.6673ZM15.9999 13.334C14.5333 13.334 13.3333 14.534 13.3333 16.0007C13.3333 17.4673 14.5333 18.6673 15.9999 18.6673C17.4666 18.6673 18.6666 17.4673 18.6666 16.0007C18.6666 14.534 17.4666 13.334 15.9999 13.334ZM15.9999 21.334C14.5333 21.334 13.3333 22.534 13.3333 24.0007C13.3333 25.4673 14.5333 26.6673 15.9999 26.6673C17.4666 26.6673 18.6666 25.4673 18.6666 24.0007C18.6666 22.534 17.4666 21.334 15.9999 21.334Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1249_954">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export {
  iconUser,
  checkImg,
  addIcon,
  cameraIcon,
  pencilIcon,
  locationOnIcon,
  peopleIcon,
  searchIcon,
  listIcon,
  filterIcon,
  closeIcon,
  QrIcon,
  iconMusicNote,
  avancedFilterIcon,
  menuMobileIcon,
};
