import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  defaultColors,
  desktopInputTexts,
  hoverEffectFieldsForm,
} from "styled-components/App.styled";

const DashedButtonStyled = styled(Link)`
  width: 318px;
  height: 48px;
  border-radius: 17px;
  padding: 0 15px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${defaultColors.white};
  border: 1px dashed ${defaultColors.white};
  background: ${defaultColors.somethingTransparentWhite};

  text-decoration: none;

  cursor: pointer;

  font: ${desktopInputTexts};

  &:hover {
    ${hoverEffectFieldsForm}
  }
`;

export { DashedButtonStyled };
