import styled from "styled-components";
import { fadeIn } from "styled-components/animation.styled";
import { defaultColors } from "styled-components/App.styled";
// import { fadeIn } from "../../../../styled-components/animation.styled";
// import { defaultColors } from "../../../../styled-components/App.styled";

const SpanErrorsStyled = styled.span`
  color: ${defaultColors.red};
  z-index: 5;
  font-weight: ${({from}) => from ? 'bold' : 'normal'};
  padding: 15px;
  
  ${fadeIn()}
`;

const FormGroupStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

export { SpanErrorsStyled, FormGroupStyled };
