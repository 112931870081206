import { usePathLocation } from "hooks/useLocation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { feedbackSuccess } from "redux/actions/feedbackAction";
import { resetUserNotification } from "redux/actions/userAction";
import { utilStatusRequest } from "utilities/utilStatusRequest/utilStatusRequest";

export const useAuth = () => {
  const { location } = usePathLocation();

  const navigate = useNavigate();

  const { loadingUser, success_request, error, success_message } = useSelector(
    (state) => state.userReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(resetUserNotification());
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (
      success_request &&
      success_message === utilStatusRequest({ code: "successLoginuser" })
    ) {
      // console.log({ success_message });
      dispatch(
        feedbackSuccess({
          variant: "success",
          text: success_message,
        })
      );

      dispatch(resetUserNotification());

      navigate("/", { replace: true });
    }
  }, [success_request, dispatch]);

  return { location, loadingUser };
};
