import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const useNavigateInHome = () => {
  const [clickToggleBtn, setClickToggleBtn] = useState("artists");

  let navigate = useNavigate();

  const searchParams = useSearchParams()[0].get("show");

  const handleToggleBtnClick = (path) => {
    setClickToggleBtn(path);

    path === "artists" && navigate("artists?show=artists");
    path === "locations" && navigate("locations?show=locations");
  };

  useEffect(() => {
    // if (!searchParams) handleToggleBtnClick("artists");
    if (!searchParams) handleToggleBtnClick("artists");
    else handleToggleBtnClick(`${searchParams}`);
    //eslint-disable-next-line
  }, [searchParams]);

  return { clickToggleBtn, handleToggleBtnClick };
};
