import { patterns } from "./utilPatterns";

export const addHttpsInUrl = (url) => {
  // console.log({ url });

  // console.log(patterns.patternURL.test(url));

  if (patterns.patternURL.test(url)) return url;

  return "https://" + url;
};
