import { SpinnerLoad } from "components/SpinnerLoad/SpinnerLoad";
import { useCatchErrors } from "hooks/useCatchErrors";
import { useSearchParamsBy } from "hooks/useSearchParamsBy";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { feedbackSuccess } from "redux/actions/feedbackAction";
import { AlertMessageStyled } from "styled-components/App.styled";
import { messagesApp } from "utilities/messagesApp.util";
import { editOrCreateEventsButtons } from "utilities/utilAccount/editOrCreateEventsButtons.util";
import { deleteEvent } from "views/account/interceptors/deleteEvent.interceptor";
import { getEvents } from "views/account/interceptors/getEvents.interceptor";
import {
  ToggleButtonEditOrCreateEventsStyled,
  WrapButtonsEditOrCreateEventsStyled,
  WrapEditOrCreateEventsStyled,
} from "views/account/styled-components/EditOrCreateEvents.styled";

import { CreateEvent } from "../CreateEvent/CreateEvent";
import { ModalConfirmAction } from "../ModalsEvents/ModalConfirmAction/ModalConfirmAction";
import { ModalEditEvent } from "../ModalsEvents/ModalEditEvent/ModalEditEvent";

export const EditOrCreateEvents = () => {
  const { catchErrors } = useCatchErrors();
  const [loadingData, setLoadingData] = useState(true);

  const dispatch = useDispatch();

  // parameter contain profile id
  const { parameter } = useSearchParamsBy("id_profile");
  const [messageError, setMessageError] = useState("");

  // toggle ; views: editEvent, addEvent
  const [clickToggleBtn, setClickToggleBtn] = useState("edit-events");

  const [modalEditShow, setModalEditShow] = useState(false);

  const [eventToEdit, setEventToEdit] = useState([]);

  const [infoEvents, setInfoEvents] = useState([]);

  const [refreshEvents, setRefreshEvents] = useState(false);

  const [showModalConfirmActionText, setShowModalConfirmActionText] =
    useState(false);
  const [modalConfirmActionText, setModalConfirmActionText] = useState("");
  // const [actionDeleteConfirmModal, setActionDeleteConfirmModal] =
  //   useState(null);
  const [typeActionSelected, setTypeActionSelected] = useState(null);

  const [eventIdToDelete, setEventIdToDelete] = useState(null);

  const handleToggleBtnClick = (nameClick) => {
    setClickToggleBtn(nameClick);
  };

  // get data event
  const fetchData = async () => {
    try {
      const fetch = await getEvents({
        profileId: parameter,
      });

      if (!fetch || !fetch.success) {
        catchErrors({
          fetch,
          textDetail: "errorSearchEvents",
          where: "edit or create events - fetch data",
        });
      }

      setMessageError(null);

      setInfoEvents(fetch.data.data);
    } catch (error) {
      console.error("error", error.message);
      setMessageError(error.message);
    } finally {
      setLoadingData(false);
    }
  };

  // click -> button toggle change. if value edit-events then refresh
  useEffect(() => {
    setLoadingData(true);
    if (clickToggleBtn === "edit-events") {
      fetchData();
    }

    //eslint-disable-next-line
  }, [clickToggleBtn]);

  // edit or delete event then refresh events
  useEffect(() => {
    if (clickToggleBtn === "edit-events" && refreshEvents) {
      setLoadingData(true);
      fetchData();
      setRefreshEvents(false);
      // setActionDeleteConfirmModal(null);
      setEventIdToDelete(null);
      setTypeActionSelected(null);
    }

    //eslint-disable-next-line
  }, [refreshEvents]);

  const handleClickEventToEdit = (eventToEdit, eventNumber) => {
    setEventToEdit([{ ...eventToEdit, eventNumber }]);
  };

  const handleModalClose = () => setModalEditShow(false);

  useEffect(() => {
    if (eventToEdit.length > 0) {
      setModalEditShow(true);
    }
  }, [eventToEdit]);

  const handleEditEvents = () => {
    setRefreshEvents(true);
    handleModalClose();
  };

  // show modal confirm action
  useEffect(() => {
    if (modalConfirmActionText) {
      if (typeActionSelected === "allEvents") {
        setShowModalConfirmActionText(true);
      } else if (typeActionSelected === "oneEvent") {
        setShowModalConfirmActionText(true);
      }
    }
  }, [modalConfirmActionText, eventIdToDelete, typeActionSelected]);

  const handleCloseConfirmModal = () => {
    setModalConfirmActionText("");
    setShowModalConfirmActionText(false);
  };

  // delete events:
  //    delete all events
  const handleDeleteAllEvents = () => {
    setModalConfirmActionText("¿Estás seguro de eliminar todos los eventos?");
    setShowModalConfirmActionText(true);
    setTypeActionSelected("allEvents");
  };

  //    delete one event
  const handleDeleteEvent = (event) => {
    setEventIdToDelete(event.id);

    // setActionDeleteConfirmModal(() => handleConfirmDeleteEvent);
    setModalConfirmActionText("¿Estás seguro de eliminar el evento?");
    setShowModalConfirmActionText(true);
    setTypeActionSelected("oneEvent");
  };

  // press 'confirm' delete event or all events
  const handleConfirmDelete = async (type, id) => {
    try {
      setLoadingData(true);
      // delete Events
      const idProfile = parameter;
      let checkExistErrorDelete;

      if (type === "allEvents") {
        // delete all events
        let errorDeleteEvents;
        if (infoEvents?.length > 0) {
          errorDeleteEvents = await Promise.all(
            await infoEvents?.map(
              async (event) => await deleteEvent(idProfile, event.id)
            )
          );
        }

        // check errors in delete all events
        checkExistErrorDelete = !errorDeleteEvents
          ? undefined
          : errorDeleteEvents.find((item) => item.success === false);
      } else if (type === "oneEvent") {
        checkExistErrorDelete = await deleteEvent(idProfile, eventIdToDelete);
      }

      // check errro response API
      if (checkExistErrorDelete && !checkExistErrorDelete.success) {
        catchErrors({
          info: checkExistErrorDelete,
          textDetail: "errorDeleteEvents",
          where: "edit delete events - handleConfirmDelete",
        });
      }

      if (type === "oneEvent" || type === "allEvents") {
        dispatch(
          feedbackSuccess({
            variant: "success",
            text:
              type === "allEvents"
                ? messagesApp.deleteAllEventsSuccessfuly
                : type === "oneEvent"
                ? messagesApp.deleteEventSuccessfuly
                : "X",
          })
        );

        setRefreshEvents(true);
      }
    } catch (error) {
      console.error(
        "error handleConfirmDelete - EditOrCreateEvents",
        error.message
      );
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <WrapButtonsEditOrCreateEventsStyled className="WrapButtonsEditOrCreateEventsStyled">
      <div className="col col-12 btn-group" role="group">
        {editOrCreateEventsButtons.map((elements, idx) => (
          <ToggleButtonEditOrCreateEventsStyled
            key={idx}
            className={`col col-12${idx !== 0 && "ms-2"}`}
            id={`btns-actions-${idx}`}
            type="radio"
            name="btns"
            onClick={() => handleToggleBtnClick(elements.value)}
            data-toggle="toggle"
            checked={clickToggleBtn}
            nameButton={elements.value}
          >
            {elements.name}
          </ToggleButtonEditOrCreateEventsStyled>
        ))}
      </div>
      <WrapEditOrCreateEventsStyled>
        {clickToggleBtn === "edit-events" ? (
          loadingData ? (
            <SpinnerLoad />
          ) : messageError ? (
            <AlertMessageStyled type="danger">
              {messageError}
            </AlertMessageStyled>
          ) : (
            infoEvents.length > 0 && (
              <>
                <Button variant="danger" onClick={handleDeleteAllEvents}>
                  Eliminar todos los eventos
                </Button>
                {infoEvents?.map((eventInput, index) => (
                  <div className="p-3 my-2 events-list" key={index}>
                    <div className="d-flex justify-content-between ">
                      <p>
                        {messagesApp.eventText} {index + 1}
                      </p>
                      <div
                        className="btn btn-danger col col-5"
                        onClick={() => handleDeleteEvent(eventInput)}
                      >
                        Eliminar este evento
                      </div>
                    </div>
                    <div
                      className="py-2"
                      onClick={() =>
                        handleClickEventToEdit(eventInput, index + 1)
                      }
                    >
                      <div className="d-flex justify-content-between ">
                        <p>
                          {`${messagesApp.descriptionText}: `}
                          {eventInput.description}
                        </p>
                        <div className="btn btn-primary col col-5">
                          Editar evento
                        </div>
                      </div>

                      <p>
                        {`${messagesApp.dateText}: `}
                        {new Date(eventInput.date).toLocaleString()}
                      </p>
                      <p>
                        {`${messagesApp.urlText}: `} {eventInput.url}
                      </p>
                    </div>
                  </div>
                ))}
              </>
            )
          )
        ) : (
          <CreateEvent
            parameter={parameter}
            setRefreshEvents={setRefreshEvents}
          />
        )}
      </WrapEditOrCreateEventsStyled>

      <ModalConfirmAction
        show={showModalConfirmActionText}
        handleConfirm={handleConfirmDelete}
        onHide={() => handleCloseConfirmModal()}
        actionText={modalConfirmActionText}
        eventIdToDelete={eventIdToDelete}
        typeActionSelected={typeActionSelected}
      />

      <ModalEditEvent
        show={modalEditShow}
        onHide={() => handleModalClose()}
        eventSelected={eventToEdit}
        clickToggleBtn={clickToggleBtn}
        handleEditEvents={handleEditEvents}
        parameter={parameter}
      />
    </WrapButtonsEditOrCreateEventsStyled>
  );
};
