import { EditCoverAccount } from "./components/EditCoverAccount/EditCoverAccount";
import { FormEditArtistOrLocation } from "./components/FormEditArtistOrLocation";

import { useEditArtistOrLocationContext } from "./contexts/EditArtistOrLocationProvider";

const EditArtistOrLocation = () => {
  const { handleLinkProfileLinkCover } = useEditArtistOrLocationContext();

  return (
    <div className="col col-12">
      <EditCoverAccount handleLink={handleLinkProfileLinkCover} />
      <FormEditArtistOrLocation />
    </div>
  );
};

export default EditArtistOrLocation;
