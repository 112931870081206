import styled from 'styled-components';
import { fadeIn } from 'styled-components/animation.styled';
import {
	defaultColors,
	desktopInputTexts,
	subTitlesStyled,
} from 'styled-components/App.styled';

const whatButtonPaint = (checked, nameButton) =>
	checked === nameButton ? `1px solid ${defaultColors.skyBlue}` : 'none';

const whatButtonPaintBackground = (checked, nameButton, from) =>
	checked === nameButton
		? from
			? defaultColors.skyBlue
			: defaultColors.transparentWhite
		: from
		? defaultColors.somethingTransparentWhite
		: defaultColors.transparentWhite;

const whatButtonPaintColor = (checked, nameButton, disabled, from) =>
	checked === nameButton
		? from
			? defaultColors.darkBlue
			: defaultColors.skyBlue
		: disabled
		? defaultColors.greyBlue
		: defaultColors.white;

const ToggleButtonStyled = styled.button`
	padding: 1rem;
	// margin: 1rem 0 2rem 0;
	background-color: ${({ checked, nameButton, from }) =>
		whatButtonPaintBackground(checked, nameButton, from)};
	color: ${({ checked, nameButton, disabled, from }) =>
		whatButtonPaintColor(checked, nameButton, disabled, from)};

	border-bottom: ${({ checked, nameButton, from }) =>
		from ? 'none' : whatButtonPaint(checked, nameButton)};
	text-decoration: none;
	border-radius: ${({ from }) => (from ? '8px' : 'none')};
	display: ${({ displayBtn }) => (displayBtn ? 'none' : 'block')};

	font: ${({ from }) => (from ? desktopInputTexts : subTitlesStyled)};

	${fadeIn({ time: '1.5s' })}

	&:hover {
		background-color: ${({ disabled }) => (disabled ? '' : '')};
		color: ${({ disabled }) =>
			disabled ? defaultColors.greyBlue : defaultColors.skyBlue};
	}

	&:active {
		outline: 2px solid ${defaultColors.skyBlue};
	}
`;

export { ToggleButtonStyled };
