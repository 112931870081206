import fetchAPI from 'services/fetchAPI.services';
import { BEARERAUTH } from 'utilities/authHeader.util';

export const getUserPaymentMethods = async (accountId) => {
	try {
		const headers = {
			Authorization: BEARERAUTH(),
		};

		const fetchingUserPaymentMethods = await fetchAPI({
			method: 'GET',
			url: `https://api.showbookie.com/v1/Account/${accountId}/PaymentMethods`,
			headers,
		});

		return fetchingUserPaymentMethods;
	} catch (error) {
		console.error('error get interceptor getUserPaymentMethods', { error });
		return { success: false, code: 'X' };
	}
};
