import { useCatchErrors } from "hooks/useCatchErrors";
import { createContext, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAccount } from "redux/actions/accountAction";
import { feedbackSuccess } from "redux/actions/feedbackAction";
import { messagesApp } from "utilities/messagesApp.util";
import { inputsGroups } from "utilities/utilAccount/utilNewArtist";
import { validationSchemaAccount } from "utilities/utilAccount/utilValidationFields";
import { protectedLinks } from "utilities/utilHeader/utilHeaderNavLinks";
import { utilStatusRequest } from "utilities/utilStatusRequest/utilStatusRequest";
import { useFormAccount } from "views/account/hooks/useFormAccount";
import { setImageInProfileSection } from "views/account/interceptors";
import { postDataForm } from "views/account/interceptors/setDataForm.interceptor";
import { setNewEvent } from "views/account/interceptors/setNewEvent.interceptor";

const NewArtistContext = createContext();

const NewArtistProvider = ({ children }) => {
  const { catchErrors } = useCatchErrors();
  const { user } = useSelector((state) => state.userReducer);
  const { account } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // link portada (cover)
  const [profileLinkCover, setProfileLinkCover] = useState([]);

  // link profile image
  const [linkProfilePicture, setLinkProfilePicture] = useState(null);

  // links platforms
  const [profileLinksSources, setProfileLinksSources] = useState([]);

  // numPhone
  const [phone, setPhone] = useState("");

  // date select in input events
  const [dateEvent, setDateEvent] = useState([{ date: "", eventNum: 1 }]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const defaultSchema = { email: "", webSite: "", ubication: "", phone };
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useFormAccount({ fields: inputsGroups, defaultSchema });

  const handleLinkProfilePicture = (link) => {
    setLinkProfilePicture(link);
  };

  const handleLinkProfileLinkCover = (link) => {
    setProfileLinkCover(link);
  };

  const handlePhone = (num) => {
    setPhone(num);
  };

  const handleDateEvent = (data, eventNum) => {
    setDateEvent(
      dateEvent.map((item) =>
        item.eventNum === eventNum ? { date: data, eventNum } : item
      )
    );
  };

  const handleLoadingSubmit = (value) => setLoadingSubmit(value);

  const handleProfileLinksSources = (objWithLink) => {
    if (!objWithLink) setProfileLinksSources([]);
    else {
      const filterSource = profileLinksSources.filter(
        (item) => item.source !== objWithLink.source
      );
      setProfileLinksSources([...filterSource, objWithLink]);
    }
  };

  const saveDataFile = async (file, input) => {
    try {
      if (!file) return false;

      let formdata = new FormData();
      formdata.append("image", file, file.name);

      const uploadPictureProfile = await setImageInProfileSection(formdata);

      if (!uploadPictureProfile.success)
        throw new Error(
          utilStatusRequest({
            code: "X",
            msgRequest: uploadPictureProfile.title,
          })
        );

      localStorage.setItem(`link-${input}`, uploadPictureProfile.data.fileUrl);

      return { link: uploadPictureProfile.data.fileUrl, sourceGallery: input };
    } catch (error) {
      console.error(error.message);
    }
  };

  const manageDataNewArtistForm = async ({
    data,
    isPost = false,
    from = "save",
  }) => {
    try {
      handleLoadingSubmit(true);

      const dataPlace = data.ubication.split("/");

      const city = dataPlace[0];
      const country = dataPlace[1];
      const state = dataPlace[2];
      const address = dataPlace[3];
      const latitude = dataPlace[4];
      const longitude = dataPlace[5];

      // links gallery
      let fetchLinksGallery = await Promise.all([
        await saveDataFile(
          data?.galleryImage1[0],
          data?.galleryImage1[0]?.name
        ),
        await saveDataFile(
          data?.galleryImage2[0],
          data?.galleryImage2[0]?.name
        ),
        await saveDataFile(
          data?.galleryImage3[0],
          data?.galleryImage3[0]?.name
        ),
        await saveDataFile(
          data?.galleryImage4[0],
          data?.galleryImage4[0]?.name
        ),
        await saveDataFile(
          data?.galleryImage5[0],
          data?.galleryImage5[0]?.name
        ),
        await saveDataFile(
          data?.galleryImage6[0],
          data?.galleryImage6[0]?.name
        ),
      ]);

      const arrayLinksGallery = fetchLinksGallery
        .map((item) => (item !== false ? item.link : false))
        .filter((index) => (index !== false ? index.link : false));

      const parseGenre = parseInt(data.genre);

      const dataToSend = {
        name: data.name,
        email: data.email,
        bio: data.description,
        phone,
        description: data.description,
        capacity: 0,
        cover: profileLinkCover,
        address,
        picture: linkProfilePicture,
        webSite: data.webSite,
        city,
        country,
        state,
        latitude,
        longitude,
        certified: false,
        type: 1,
        genres: [parseGenre],
        gallery: arrayLinksGallery,
        links: profileLinksSources,
      };

      const arrayEvents = dateEvent
        .filter((item) => item.date !== "")
        .map((item) => ({
          description: data[`${item.eventNum}-eventDescription`],
          url: data[`${item.eventNum}-eventUrl`],
          date: new Date(item.date).toISOString(),
        }));

      const sendData = await postDataForm(dataToSend, account.id, "Profiles");

      if (!sendData || !sendData.success) {
        catchErrors({
          info: sendData,
          textDetail: "errorCreateNewArtist",
          where: "manageDataNewArtistForm - new artist provider",
        });
      }

      // crear nuevo evento
      if (arrayEvents.length > 0) {
        const profileId = sendData.data.id;

        const settingNewEvent = await Promise.all(
          arrayEvents.map(async (event) => {
            const addEvent = await setNewEvent(profileId, "Events", event);

            if (!addEvent || !addEvent.success) {
              catchErrors({
                info: addEvent,
                textDetail: "errorCreateEvent",
                where:
                  "settingNewEvent - manageDataNewArtistForm - new artist provider",
                withTry: false,
              });
            }
            return addEvent;
          })
        );
      }

      dispatch(getAccount(user.id, undefined, "Successful new operation"));

      reset();

      handlePhone("");
      handleDateEvent("");
      setDateEvent([{ date: "", eventNum: 1 }]);

      if (isPost) return { success: true, data: sendData };

      if (from === "public") return { success: false };

      dispatch(
        feedbackSuccess({
          variant: "success",
          text: messagesApp.saveDataSuccessfuly,
        })
      );

      // when use dashboard in 'protectedLinks' options, then use protectedLinks[1].path
      // navigate(`${protectedLinks[1].path}/${account.id}`, { replace: true });
      navigate(`${protectedLinks[0].path}/${account.id}`, { replace: true });
    } catch (error) {
      console.error(
        "manageDataNewArtistForm - new artist provider",
        error.message
      );
    } finally {
      handleLoadingSubmit(false);
    }
  };

  const onSubmitSave = async (data) => {
    await manageDataNewArtistForm({ data });
  };

  const onSubmitPublic = async (data, e) => {
    try {
      const setData = await manageDataNewArtistForm({
        data,
        isPost: true,
        from: "public",
      });

      if (!setData || !setData.success) {
        throw new Error(messagesApp.catchNewArtist);
      }

      dispatch(
        feedbackSuccess({
          variant: "success",
          text: messagesApp.saveDataSuccessfulyAndGoPayment,
        })
      );

      // when use dashboard in 'protectedLinks' options, then use protectedLinks[1].path
      // navigate(`${protectedLinks[1].path}/${account.id}`, { replace: true });
      navigate(
        `${protectedLinks[0].path}/${account.id}/payments-section?pi=${setData.data.data.id}&ai=${account.id}&type=artists`,
        { replace: true }
      );
    } catch (error) {
      console.error(
        "error onSubmitPublic - new artist provider",
        error.message
      );
      dispatch(
        feedbackSuccess({
          variant: "danger",
          text: error.message,
        })
      );
    }
  };

  return (
    <NewArtistContext.Provider
      value={{
        register,
        handleSubmit,
        errors,
        watch,
        handleProfileLinksSources,
        onSubmitSave,
        onSubmitPublic,
        validationSchemaAccount,
        handleLinkProfilePicture,
        setValue,
        clearErrors,
        handleLinkProfileLinkCover,
        loadingSubmit,
        handlePhone,
        phone,
        setError,
        profileLinksSources,
        handleDateEvent,
        setDateEvent,
        dateEvent,
      }}
    >
      {children}
    </NewArtistContext.Provider>
  );
};

const useNewArtistContext = () => {
  const context = useContext(NewArtistContext);
  if (context === undefined)
    throw new Error(
      "New artist context must be used within a New artist provider"
    );

  return context;
};

export { useNewArtistContext, NewArtistProvider };

// MERGEAR CON DEV --... PUSHEAR Y EMPEZAR REFACTOR DE YOUR ACCOUNT
