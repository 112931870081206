import styled from "styled-components";
import { defaultColors, desktopInputTexts } from "styled-components/App.styled";

const IconMusicNoteStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1rem 0 0;
`;

const NameGenreText = styled.div`
  width: 100%;
  color: ${defaultColors.white};
  font: ${desktopInputTexts};
  display: flex;
  justify-content: flex-start;
  padding: 0 0 1rem 1rem;
`;

export { NameGenreText, IconMusicNoteStyled };
