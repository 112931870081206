import { defaultColors } from "../../../styled-components/App.styled";

const backgroundOption = {
  warning: defaultColors.warningYellow,
  danger: defaultColors.red,
  normal: defaultColors.darkBlue,
  default: defaultColors.somethingTransparentWhite,
};

const assignBackgroundOption = (value) =>
  backgroundOption[value]
    ? backgroundOption[value]
    : backgroundOption["default"];

export { assignBackgroundOption };
