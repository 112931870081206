import { messagesApp } from "utilities/messagesApp.util";

export const utilStatusRequest = ({ code, msgRequest = null }) => {
  const beenProblemText = "Ocurrió un problema";
  const defaultRequestMessages = {
    // success: "Successful operation",
    success: "Operación exitosa",
    update: "Operación de actualización exitosa",
    successCreateuser: "Usuario creado con éxito",
    successLoginuser: "Inicio de sesión exitoso",
    successUpdateYourAccount: "Tu cuenta se actualizó correctamente",
    // update: "Successful update operation",
    errorAuthLogin: "Usuario o Contraseña incorrecto",
    errorCreateEvent: `${beenProblemText} al crear el evento, intenta crearlo más tarde`,
    errorCreateNewArtist: `${beenProblemText} al crear el nuevo artista`,
    errorCreateNewLocation: `${beenProblemText} al crear la nueva locación`,
    errorLogin: `${beenProblemText} al iniciar sesión, intenta más tarde`,
    errorSearchEvents: `${beenProblemText} al buscar los eventos, intenta mas tarde`,
    errorSignUp: `${beenProblemText} al crear el usuario, intenta más tarde`,
    errorUpdateArtistOrLocation: `${beenProblemText} al actualizar artista o locación, intenta más tarde`,
    errorUpdateEvent: `${beenProblemText} al actualizar el evento, intenta más tarde`,
    errorUpdateGallery: `${beenProblemText} al actualizar el la galería, intenta más tarde`,
    errorUpdatePassword: `${beenProblemText} al cambiar el password, intenta más tarde`,
    errorDeleteEvents: messagesApp.catchDeleteEvents,
    errorDeleteGalleryImage: `${beenProblemText} al borrar imagen de galería, intenta más tarde`,
    errorDeleteImage: `${beenProblemText} al actualizar el la galería, intenta más tarde`,
    errorDeleteProfile: `${beenProblemText} al eliminar el perfil, intenta más tarde`,
    errorPayment: `${beenProblemText} realizar el pago`,
    errorPaymentSection: `${beenProblemText} al obtener los medios de pago`,
    errorPublish: `${beenProblemText} al publicar este perfil, intenta más tarde`,
    errorUserPaymentMethods: `${beenProblemText} al obtener el nuevo medio de pago`,
    errorSendMailForgotPw: `${beenProblemText} al enviarte un mail, intenta más tarde`,
    errorUpdateYourAccount: `${beenProblemText} al actualizar la cuenta, intenta más tarde`,
    errorValidateForgotPw: `${beenProblemText} al validar tu identidad, intenta más tarde`,
    sendMailForgotPw: "Enviamos un mensaje a tu correo electrónico",
    successUpdatePassword: "Password cambiado correctamente",
    USER101: "First Name is required.",
    USER102: "Last Name is required.",
    USER103: "Email is required.",
    USER104: "Password is required.",
    USER105: "PasswordConfirmation is required.",
    USER106:
      "Your password must contain between 6 and 20 characters and at least one letter and one number.",
    USER107: "Passwords do not match.",
    USER108: "Invalid Email or password.",
    USER109: "Email already in use.",
    USER110: "There was an error trying to create the user.",
    AUTH101: "Email is required.",
    AUTH102: "Password is required.",
    AUTH103: "Invalid Email",
    AUTH104: "The email is not registered",
    AUTH105: "Invalid Email or Password",
    ACCOUNT103: " Id is required.",
    ACCOUNT104: "Could not find an account with the id provided.",
    ACCOUNT105: "There was an error trying to update the account.",
    ACOCUNT110: "First Name is required.",
    ACOCUNT111: "Last Name is required.",
    400: "Bad Request",
  };

  const messageResponse = msgRequest
    ? msgRequest
    : defaultRequestMessages[code];

  return messageResponse
    ? messageResponse
    : `${messagesApp.thereAreProblems} - ${code}`;
};
