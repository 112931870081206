export const searchingItemExistInArray = (array, query) => {
  if (array.length === 0) return false;
  const checkItem = array.filter(
    (item) => item.source.toLowerCase() === query.toLowerCase()
  );

  if (checkItem.length === 0) return false;
  // else return checkItem.url === "" ? false : checkItem[0];
  else return checkItem[0];
};
