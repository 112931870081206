/**
 * inputs of your account or profile edit
 */

import {
  FormGroupStyled,
  SpanErrorsStyled,
} from "views/account/pages/YourAccount/styled-components";
import { InputStyled } from "./styled-components/Input.styled";

export const InputForm = ({
  children,
  register,
  errors,
  validationSchema,
  disabledButton = false,
}) => {
  const _children = children.map((field) => (
    <FormGroupStyled key={field.name} className="form-group">
      <InputStyled
        type={field.type}
        className="form-control"
        name={field.name}
        placeholder={field.placeholder}
        properties={field.properties}
        {...register(field.name, validationSchema[field.name])}
        disabled={disabledButton}
      />
      {errors[field.name] && (
        <SpanErrorsStyled>{errors[field.name]["message"]}</SpanErrorsStyled>
      )}
    </FormGroupStyled>
  ));

  return _children;
};
