const msgValidation = {
  required: "El campo es requerido",

  picture: {
    invalidSize: "The image cannot exceed 2MB",
    invalidType: "Format image permited png/jpg/jpeg",
  },
};

const validationPicture = (file) => {
  if (!file || file.length === 0) return undefined;

  if (file.size > 2000000) return msgValidation.picture.invalidSize;

  const typeImg = file.type.split("/");

  if (typeImg[1] !== "png" && typeImg[1] !== "jpg" && typeImg[1] !== "jpeg")
    return msgValidation.picture.invalidType;

  return false;
};

export { validationPicture };
