import styled from "styled-components";
import { fadeIn } from "styled-components/animation.styled";
import {
  defaultColors,
  desktopInputTexts,
  subTitlesStyled,
} from "styled-components/App.styled";

const FormAccountStyled = styled.form`
  position: relative;
  margin-top: ${({ from }) => (from ? "0" : "321px")};
  width: 100%;
  padding: 20px 0 5px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  ${fadeIn({ time: "1.5s" })}
`;

const TextDesktopInputs = styled.h3`
  font: ${desktopInputTexts};
  color: ${defaultColors.white};
`;

const TextDesktopSubtitles = styled.div`
  font: ${subTitlesStyled};
  color: ${defaultColors.white};
`;

export { FormAccountStyled, TextDesktopInputs, TextDesktopSubtitles };
