import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";

export const getFilterHome = async (type, name) => {
  try {
    const url = `${URLs.profiles}?Type=${type}&Name=${name}`;

    const fetchData = await fetchAPI({
      method: "GET",
      url: `${url}&pageSize=500`,
    });

    return fetchData;
  } catch (error) {
    console.error("error getFilterHome interceptor", { error });
    return { success: false, code: "X" };
  }
};
