import fetchAPI from "services/fetchAPI.services";
import { URLs } from "services/servicesUrls.services";
import { BEARERAUTH } from "utilities/authHeader.util";
import { adapterPersistUserData } from "../adapter/userData.adapter";

export const authenticateUser = async (userId, token) => {
  try {
    // console.log({ userId, token });

    const bearer = BEARERAUTH() ? BEARERAUTH() : `Bearer ${token}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: bearer,
    };

    const fetchingDataUser = await fetchAPI({
      method: "GET",
      url: `${URLs.users}/${userId}`,
      headers,
    });
    // console.log({ fetchingDataUser });
    return adapterPersistUserData(fetchingDataUser);
  } catch (error) {
    console.error("error authenticate User", { error });
    return { success: false, code: "X" };
  }
};
