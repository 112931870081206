import { InputStyled } from "components/InputForm/styled-components/Input.styled";
import { SpinnerLoad } from "components/SpinnerLoad/SpinnerLoad";
import { useCatchErrors } from "hooks/useCatchErrors";
import { useSearchParamsBy } from "hooks/useSearchParamsBy";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { feedbackSuccess } from "redux/actions/feedbackAction";
import { addHttpsInUrl } from "utilities/addHttpsInUrl.util";
import { messagesApp } from "utilities/messagesApp.util";
import { eventsGroups } from "utilities/utilAccount/utilNewArtist";
import { putEvent } from "views/account/interceptors/putEvent.interceptor";
import { ButtonsSubmitNewArtistForm } from "views/account/pages/NewArtists/components";
import { DateInput } from "views/account/pages/NewArtists/components/FormNewArtist/DateInput/DateInput";
import { FormGroupStyled } from "views/account/pages/NewArtists/styled-components";
import { SpanErrorsStyled } from "views/Auth/styled-components/InputAuthForm.styled";

export const ModalEditEvent = ({
  show,
  onHide,
  eventSelected,
  handleEditEvents,
  parameter,
}) => {
  const { catchErrors } = useCatchErrors();
  const [loadingData, setLoadingData] = useState(true);

  const dispatch = useDispatch();

  // states click update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [disabledButtonEdit, setDisabledButtonEdit] = useState(false);

  const [messageError, setMessageError] = useState("");

  const [dateEvent, setDateEvent] = useState("");

  const { register, handleSubmit, setValue, reset } = useForm();

  const handleDateEvent = (date) => {
    setDateEvent(date);
  };

  useEffect(() => {
    if (dateEvent) setLoadingData(false);
  }, [dateEvent]);

  useEffect(() => {
    if (eventSelected.length > 0) {
      setLoadingData(true);
      setDateEvent(Date.parse(eventSelected[0].date));
      setValue("eventDescription", eventSelected[0].description);
      setValue("eventUrl", eventSelected[0].url);
      setLoadingData(true);
    }
  }, [eventSelected]);

  const handleCloseModal = () => {
    setDateEvent("");
    onHide();
  };

  const onSubmitEditEvent = async (data) => {
    try {
      // console.log({ data, dateEvent });
      setLoadingUpdate(true);
      setDisabledButtonEdit(true);

      const objEventToEdit = {
        description: data.eventDescription,
        url: addHttpsInUrl(data.eventUrl),
        date: new Date(dateEvent).toISOString(),
        active: true,
      };

      const idEventToEdit = eventSelected[0].id;
      const updateEvent = await putEvent(
        parameter,
        "Events",
        objEventToEdit,
        idEventToEdit
      );

      if (!updateEvent || !updateEvent.success) {
        catchErrors({
          info: updateEvent,
          textDetail: "errorUpdateEvent",
          where: "on submit edit event",
        });
      }

      dispatch(
        feedbackSuccess({
          variant: "success",
          text: messagesApp.updateDataSuccessfuly,
        })
      );

      handleEditEvents();
    } catch (error) {
      console.error("error al guardar nuevo evento", error.message);
      setMessageError(error.message);
      setTimeout(() => {
        setMessageError("");
      }, 2500);
    } finally {
      setLoadingUpdate(false);
      setDisabledButtonEdit(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => handleCloseModal()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {loadingData && <div>CARGANDO...</div>}
      {eventSelected.length > 0 &&
        !loadingData &&
        eventSelected.map((event) => (
          <div key={event.eventNumber}>
            <Modal.Header closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{ color: "black" }}
              >
                {messagesApp.eventText} {event.eventNumber}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ color: "white", backgroundColor: "black" }}>
              {eventsGroups.inputsEventsGroups.map((field, index) => (
                <FormGroupStyled key={index} className="form-group my-2">
                  <InputStyled
                    type={field.type}
                    className="form-control"
                    name={`${index + 1}-${field.name}`}
                    placeholder={field.placeholder}
                    {...register(field.name)}
                  />
                </FormGroupStyled>
              ))}

              <DateInput
                dateEditEvent={dateEvent}
                handleDateEvent={handleDateEvent}
              />
            </Modal.Body>
            <Modal.Footer className="d-flex flex-nowrap">
              {loadingUpdate && <SpinnerLoad />}
              {messageError && (
                <SpanErrorsStyled>{messageError}</SpanErrorsStyled>
              )}
              <ButtonsSubmitNewArtistForm
                text={messagesApp.editText}
                from="editEvents"
                click={handleSubmit((data) => onSubmitEditEvent(data))}
                disabled={disabledButtonEdit}
              />

              <Button onClick={() => handleCloseModal()}>Cerrar</Button>
            </Modal.Footer>
          </div>
        ))}
    </Modal>
  );
};
