import { useEffect, useState } from "react";

export const usePlatforms = (handlelinks) => {
  const [centaresModalShow, setCenteredModalShow] = useState(false);

  const [whatButtonClicked, setWhatButtonClicked] = useState(null);

  const [renderWhatButtonClicked, setRenderWhatButtonClicked] = useState(null);

  const handleClickPlatform = (item) => {
    setRenderWhatButtonClicked(item);
    setWhatButtonClicked(item);
  };

  useEffect(() => {
    handlelinks(undefined);
  }, []);

  useEffect(() => {
    if (whatButtonClicked) setCenteredModalShow(true);

    return () => {
      setWhatButtonClicked(null);
    };
  }, [whatButtonClicked]);

  return {
    centaresModalShow,
    renderWhatButtonClicked,
    handleClickPlatform,
    setCenteredModalShow,
  };
};
