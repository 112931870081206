import React, { useEffect } from 'react';

export default function Disclamer() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="index-page">
			<div className="text-container">
				<div className="c6 doc-content">
					<h1>DISCLAIMER</h1>
					<p className="c0">
						<span className="c2">Last updated: 2022-07-11</span>
					</p>
					<p className="c0">
						<h2>WEBSITE DISCLAIMER</h2>
					</p>
					<p className="c0">
						<span>The information provided by </span>
						<span className="c3">ShowBookie</span>
						<span>
							&nbsp;(&ldquo;Company&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;,
							&ldquo;us&rdquo;) on{' '}
						</span>
						<span className="c3">ShowBookie.com</span>
						<span className="c2">
							&nbsp;(the &ldquo;Site&rdquo;) is for general informational
							purposes only. All information on the Site is provided in good
							faith, however we make no representation or warranty of any kind,
							express or implied, regarding the accuracy, adequacy, validity,
							reliability, availability, or completeness of any information on
							the Site.
						</span>
					</p>
					<p className="c0">
						<span className="c2">
							UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY
							LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE
							SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE
							OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS
							SOLELY AT YOUR OWN RISK.
						</span>
					</p>
					<p className="c0">
						<span className="c1">EXTERNAL LINKS DISCLAIMER</span>
					</p>
					<p className="c0">
						<span className="c2">
							The Site may contain (or you may be sent through the Site) links
							to other websites or content belonging to or originating from
							third parties or links to websites and features. Such external
							links are not investigated, monitored, or checked for accuracy,
							adequacy, validity, reliability, availability or completeness by
							us.
						</span>
					</p>
					<p className="c0">
						<span className="c2">
							WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
							FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY
							THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR
							FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE
							A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
							TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR
							SERVICES.
						</span>
					</p>
					<p className="c0">
						<span className="c1">ERRORS AND OMISSIONS DISCLAIMER</span>
					</p>
					<p className="c0">
						<span className="c2">
							While we have made every attempt to ensure that the information
							contained in this site has been obtained from reliable sources,
							ShowBookie is not responsible for any errors or omissions or for
							the results obtained from the use of this information. All
							information in this site is provided &ldquo;as is&rdquo;, with no
							guarantee of completeness, accuracy, timeliness or of the results
							obtained from the use of this information, and without warranty of
							any kind, express or implied, including, but not limited to
							warranties of performance, merchantability, and fitness for a
							particular purpose.
						</span>
					</p>
					<p className="c0">
						<span className="c2">
							In no event will Show Bookie, its related partnerships or
							corporations, or the partners, agents or employees thereof be
							liable to you or anyone else for any decision made or action taken
							in reliance on the information in this Site or for any
							consequential, special or similar damages, even if advised of the
							possibility of such damages.
						</span>
					</p>
					<p className="c0">
						<span className="c1">LOGOS AND TRADEMARKS DISCLAIMER</span>
					</p>
					<p className="c0">
						<span className="c2">
							All logos and trademarks of third parties referenced on
							ShowBookie.com are the trademarks and logos of their respective
							owners. Any inclusion of such trademarks or logos does not imply
							or constitute any approval, endorsement or sponsorship of
							ShowBookie by such owners.
						</span>
					</p>
					<p className="c0">
						<span className="c1">CONTACT US</span>
					</p>
					<p className="c0">
						<span>
							Should you have any feedback, comments, requests for technical
							support or other inquiries, please contact us by email:{' '}
						</span>
						<span className="c3">support@webdesignpilot.com</span>
						<span>.</span>
					</p>
				</div>
			</div>
		</div>
	);
}
