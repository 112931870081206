import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { defaultColors, desktopInputTexts } from 'styled-components/App.styled';
import { fadeIn } from 'styled-components/animation.styled';

const DatePickerStyled = styled(DatePicker)`
	width: 100%;
	height: 56px;
	/* margin: 5px 0 5px 0; */
	margin-bottom: 5px;
	/* border: 1px solid ${defaultColors.white}; */
	border: none;
	/* border-radius: 12px; */
	border-radius: 8px;
	padding: 1.1rem;
	outline: 0;
	background: ${defaultColors.somethingTransparentWhite};
	color: ${defaultColors.white};
	transition: 0.3s;

	font: ${desktopInputTexts};

	${fadeIn({ time: '1.5s' })}

	&:hover {
		// border-bottom: 1px solid ${defaultColors.white};
		cursor: pointer;
	}

	&:focus {
		/* border-radius: 12px; */
		// outline: 3px solid ${defaultColors.darkBlue};
		padding: 15px 0 10px 10px;
		// border-bottom: 2px solid ${defaultColors.white};
		// background: ${defaultColors.transparentWhite};
		color: ${defaultColors.white};
	}

	&::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: white;
		opacity: 1; /* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: white;
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		color: white;
	}
`;

export { DatePickerStyled };
